import {Getpaymentres} from '../../../../infrastructure/Admin/payment'
const actions = {
    setisLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setpayment:
      (payment) =>
      ({ setState }) => {
        setState({ payment });
      },
      setpaymentrazorpay:
      (paymentrazorpay) =>
      ({ setState }) => {
        setState({ paymentrazorpay });
      },
      setpaymentdatecreated:
      (paymentdatecreated) =>
      ({setState}) =>{
        setState({paymentdatecreated});
      },  
      setpaymentcustomer:
      (paymentcustomer) =>
      ({setState}) =>{
        setState({paymentcustomer});
      },
      setpaymentrefunded:
      (paymentrefunded) =>
      ({setState}) =>{
        setState({paymentrefunded});
      },
      getPayment:
      () =>
      async ({ dispatch ,getState}) => {
        dispatch(actions.setisLoading(true));
        const { paymentrazorpay,paymentdatecreated,paymentcustomer,paymentrefunded} = getState();
        try {
          const res = await Getpaymentres(paymentrazorpay,paymentdatecreated,paymentcustomer,paymentrefunded);
          console.log(res, "resdata");
          dispatch(actions.setpayment(res.data.data));
          dispatch(actions.setisLoading(false));
        } catch (err) {
        } finally {
          dispatch(actions.setisLoading(false));
        }
      },
    };
    export default actions;