import { get } from "../common/remote/base_api";
export const GetFranchisesettlement = (
  razorpay_payment_id,
  date_created,
  franchise_name,
  franchise_id,
  refunded
) => {
  return get(`admin/get-franchise-settlements/`, {
    razorpay_payment_id: razorpay_payment_id,
    date_created: date_created,
    franchise_name: franchise_name,
    franchise_id: franchise_id,
    refunded: refunded,
  });
};
