import { GetAdmins } from "../../../../infrastructure/Admin/AdminRegistration";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setAdmin:
    (Admin) =>
    ({ setState }) => {
      setState({ Admin });
    },
    GetAdmins:
    () =>
      async ({ dispatch}) => {
       
        dispatch(actions.setisLoading(true));
        try {
          let res = await GetAdmins();
          dispatch(actions.setAdmin(res.data.data));
        } catch (err) {
        } finally {
          dispatch(actions.setisLoading(false));
        }
      },
};

export default actions;
