const initialState = {
  isLoading: true,
  Charges: "",
  service_charge:"",
  delibuoy_commission:"",
  franchise_commission:"",
  driver_registration_fee:"",
  franchise_driver_registration_share:"",
  delibuoy_driver_registration_share:"",


};

export default initialState;
