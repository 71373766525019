import React, { useState, useEffect } from "react";
import { CommisionDiv } from "./style";
import { Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { GetCommisiondetails } from "./store";
import Loader from "../Widget/common/Loader";
import { firebase } from "../../../Common/firebase";
function Commision() {
  const [{ isLoading, Commision }, { getCommisions }] = GetCommisiondetails();
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  localStorage.setItem("token", token);

  useEffect(() => {
    getCommisions();
  }, [token]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <CommisionDiv>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Commision Received</h2>
      </div>
      <div className="commision">
        <div className="heading">
          <h1>Commision Received</h1>
          <Divider orientation="left" />
        </div>
      </div>
      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No:</h1>
                </th>
                <th className="head-off">
                  <h1>Id</h1>
                </th>
                <th className="head-off">
                  <h1>Razorpay</h1>
                </th>
                <th className="head-off">
                  <h1>Customer</h1>
                </th>
                <th className="head-off">
                  <h1>Driver</h1>
                </th>
                <th className="head-off">
                  <h1>Date Created</h1>
                </th>
                <th className="head-off">
                  <h1>Trips</h1>
                </th>
                <th className="head-off">
                  <h1>Amount</h1>
                </th>
              </tr>
            </thead>
            <tbody>
              {Commision.map((res, i) => (
                <tr className="body-row-off">
                  <td>{i + 1}</td>
                  <td>{res.id}</td>
                  <td>{res.razorpay_payment_id}</td>
                  <td>
                    <h1>{res.customer}</h1>
                  </td>
                  <td>
                    <h2>{res.driver}</h2>
                  </td>
                  <td>{res.date_created} </td>
                  <td>{res.trip} </td>
                  <td> &#8377; {res.amount} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </CommisionDiv>
  );
}

export default Commision;
