import { auth, db } from "../../../Common/firebase";

import { post } from "../common/remote/base_api";
const registerWithEmailsAndPassword = async (email, password) => {
  const res = await auth.createUserWithEmailAndPassword(email, password);
  // const user = res.user;
  // await db.collection("users").add({
  //   uid: user.uid,
  //   authProvider: "local",
  //   email,
  // });
  return registerWithEmailsAndPassword;
};
export default registerWithEmailsAndPassword;

export const Callerinfo = (data) => {
  return post(`admin/register-caller/`, data);
};
