import React from "react";
import { Supportfranchise } from "./style";
import { Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Mysupport } from "./store";
import { useHistory } from "react-router-dom";
function Supportf() {
  let history = useHistory();
  const [
    { subject, message, myfiles },
    { Postsupportmessage, setsubject, setmessage, setmyfiles },
  ] = Mysupport();
const submitsupport =()=>{
  Postsupportmessage()
}
  return (
    <Supportfranchise>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Support</h2>
      </div>
      <div className="support">
        <div className="heading">
          <h1>Support</h1>
          <Divider orientation="left" />
        </div>
      </div>
      <div className="section">
        <div className="top">
          <div className="heading">
            {/* <h1>Get in Touch </h1> */}
          </div>

          <div className="inputbox">
            <div className="addres">
              <input
                type="text"
                value={subject}
                placeholder="subject"
                maxlength="50"
                onChange={(e) => setsubject(e.target.value)}
              ></input>
            </div>
          </div>
          <div className="inputbox">
            <div className="addres">
              <input
                type="file"
                placeholder="subject"
                maxlength="50"
                type="file"
                id="myFile"
                name="filename"
                onChange={(e) => setmyfiles(e.target.files[0])}
              ></input>
            </div>
          </div>
          <div className="textarea">
            <div className="main">
              <input
                type="text"
                value={message}
                placeholder="Start write here your message"
                onChange={(e) => setmessage(e.target.value)}
              ></input>
            </div>
          </div>

          <div className="btn">
            <div className="btn1">
              <button onClick={() =>Postsupportmessage()}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </Supportfranchise>
  );
}

export default Supportf;
