import React, { useEffect, useState } from "react";
import { Settlementfran } from "./style";
import { Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Getsettlementdetails } from "./store";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { firebase } from "../../../Common/firebase";
import { Input } from "antd";

function Fransettlement() {
  const [
    {
      isLoading,
      settlement,
      razorpaypaymentid,
      datecreated,
      franchisename,
      franchiseid,
      refunded,
    },
    {
      getSettlement,
      setRazorpaypaymentid,
      setDatecreated,
      setFranchisename,
      setFranchiseid,
      setRefunded,
    },
  ] = Getsettlementdetails();

  const [hide, sethide] = useState(true);
  const hidden = () => {
    sethide(!hide);
    setRazorpaypaymentid("");
  };
  const [hided, sethided] = useState(true);
  const hiddend = () => {
    sethided(!hided);
    setDatecreated("");
  };
  const [hidefn, sethidefn] = useState(true);
  const hiddenfn = () => {
    sethidefn(!hidefn);
    setFranchisename("");
  };
  const [hidefi, sethidefi] = useState(true);
  const hiddenfi = () => {
    sethidefi(!hidefi);
    setFranchiseid("");
  };
  const [hideRe, sethideRe] = useState(true);
  const hiddenRe = () => {
    sethideRe(!hideRe);
    setRefunded("");
  };
  //token................................................................\
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);

  //......................................................
  useEffect(() => {
    getSettlement();
  }, [
    razorpaypaymentid,
    datecreated,
    franchisename,
    franchiseid,
    refunded,
    token,
  ]);
  

  const SearchrequestRpaymentid = (e) => {
    setRazorpaypaymentid(e.target.value);
    getSettlement();
  };
  const Searchrequestdatecreated = (e) => {
    setDatecreated(e.target.value);
    getSettlement();
  };
  const Searchrequestfranchisename = (e) => {
    setDatecreated(e.target.value);
    getSettlement();
  };
  const Searchrequestfranchiseid = (e) => {
    setFranchiseid(e.target.value);
    getSettlement();
  };
  const Searchrequestrefunded = (e) => {
    setRefunded(e.target.value);
    getSettlement();
  };
  
  return (
    <Settlementfran>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Franchise Settlement</h2>
      </div>
      <div className="settlement">
        <div className="heading">
          <h1>Franchise Settlement</h1>
          <Divider orientation="left" />
        </div>
      </div>
      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No</h1>
                </th>
                <th className="head-off">
                  <h1>
                    Id
                    {""}
                    {hidefi ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenfi()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestfranchiseid(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenfi()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Raz payment ID
                    {""}
                    {hide ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hidden()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => SearchrequestRpaymentid(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hidden()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Payment Received</h1>
                </th>
                <th className="head-off">
                  <h1>
                    Franchise
                    {""}
                    {hidefn ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenfn()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestfranchisename(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenfn()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Amount</h1>
                </th>
                <th className="head-off">
                  <h1>
                    Refunded
                    {""}
                    {hideRe ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenRe()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestrefunded(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenRe()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Date Created
                    {""}
                    {hided ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddend()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestdatecreated(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddend()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
              </tr>
            </thead>
            <tbody>
              {settlement?.map((res, i) => (
                <tr className="body-row-off">
                  <td>{i + 1}</td>
                  <td>{res.id}</td>
                  <td>{res.razorpay_payment_id}</td>
                  <td>{res.payment_recieved == true ? "Yes" : "No"}</td>
                  <td>{res.franchise}</td>
                  <td>{res.amount}</td>
                  <td>{res.refunded === true ? "Yes" : "No"}</td>
                  <td>{res.date_created}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Settlementfran>
  );
}

export default Fransettlement;
