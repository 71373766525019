
import {  firebase } from "../../../../Common/firebase";
import { notification } from "antd";
import signInWithEmailAndPassword from "../../../../infrastructure/Admin-franchise/login";
const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setToken:
    (token) =>
    ({ setState }) => {
      setState({ token });
    },
  setUsernameF:
    (UsernameF) =>
    ({ setState }) => {
      setState({ UsernameF });
    },
  setPasswordF:
    (PasswordF) =>
    ({ setState }) => {
      setState({ PasswordF });
    },
  SubmitloginF:
    (history) =>
    async ({ setState, dispatch, getState }) => {
      const { UsernameF, PasswordF } = getState();
      dispatch(actions.setisLoading(true));
      try {
       await signInWithEmailAndPassword(UsernameF, PasswordF);
        const user = firebase.auth().currentUser;
        const mytoken = user && (await user.getIdToken());
        localStorage.setItem("token", mytoken);
        notification["success"]({
          message: "success",
          description: "Franchise Logged in successfully",
        });
        history.push(`/franchise/dashbord`);
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: "Username or Password incorrect",
        });
      } finally {
      }
    },
};
export default actions;
