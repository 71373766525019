import { auth, db } from "../../../Common/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { post } from "../common/remote/base_api";

const registerWithEmailAndPassword = async (email, password) => {
 await auth.createUserWithEmailAndPassword(email, password);
  return registerWithEmailAndPassword;
};
export default registerWithEmailAndPassword;

export const Postemail = (data) => {
  return post(`admin/register-franchise-basic-info/`, data);
};

export const Postinformation2 = (data) => {
  return post(`admin/register-franchise-account-info/`, data);
};

export const Postinformation3 = (data) => {
  return post(`admin/register-franchise-owner-info/`, data);
};
