import React ,{useEffect,useState}from "react";
import { Callerdetails } from "./style";
import { Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Callerlist } from "./store";
import { useHistory } from "react-router";
import Loader from "../Widget/common/Loader";
import { firebase } from "../../../Common/firebase";
function Caller() {
  const[{isLoading,Caller},{GetCaller}] = Callerlist();
 let history= useHistory();
 const Createcaller=()=>{
   history.push("/admin/callercreate/" )
 }
 const Callerdetailss=(id)=>{
   history.push(`/admin/callerdetails/${id}`)
 }
 const [token, setToken] = useState("");
 useEffect(() => {
  //import { firebase } from "../../../Common/firebase";
  return firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      user
        .getIdToken(true)
        .then((latestToken) => setToken(latestToken))
        .catch((err) => console.log(err));
    }
  });
}, []);
localStorage.setItem("token", token);

  useEffect(() => {
    GetCaller();
  }, [token])
  if(isLoading){
    return <Loader />
  }
  return (
    <Callerdetails>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Caller</h2>
      </div>
      <div className="caller">
        <div className="heading">
          <h1>Caller</h1>
          <Divider orientation="left" />
        </div>
       </div>
       <div className="button">
        <button type="button" onClick={() => Createcaller()} >
          Create
        </button>
        </div>
      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No</h1>
                </th>
                <th className="head-off">
                  <h1>Id </h1>
                </th>
                <th className="head-off">
                  <h1>Name</h1>
                </th>
                <th className="head-off">
                  <h1>phone</h1>
                </th>
                <th className="head-off">
                  <h1>Email</h1>
                </th>
                <th className="head-off">
                  <h1>Total solved Requests</h1>
                </th>
              </tr>
            </thead>
            <tbody>
            {Caller?.map((res, i) => (
              <tr className="body-row-off">
                <td onClick={() => Callerdetailss(res.id)}>{i+1}</td>
                <td onClick={() => Callerdetailss(res.id)}>{res.id}</td>
                <td onClick={() => Callerdetailss(res.id)}>{res.name}</td>
                <td onClick={() => Callerdetailss(res.id)}>{res.phone}</td>
                <td onClick={() => Callerdetailss(res.id)}>{res.email}</td>
                <td onClick={() => Callerdetailss(res.id)}>{res.total_solved_requests}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </Callerdetails>
  );
}

export default Caller;
