import React, { useEffect,useState} from "react";
import { Freqlist } from "./style";
import { LeftOutlined,SearchOutlined, CloseOutlined, } from "@ant-design/icons";
import { Divider } from "antd";
import { GetFrequestlist } from "./store";
import Loader from "../Widget/common/Loader";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import { firebase } from "../../../Common/firebase";
import { Input } from "antd"

function Franchisereqlist() {
  let history = useHistory();
  const [{ isLoading, franchisereq,name,phone,email }, { getFreq,setName,setPhone,setEmail }] = GetFrequestlist();


  const Franchidedetailspage = (id) => {
    history.push(`/admin/franchiseDetail/${id}`);
  };
  const [hide, sethide] = useState(true);
  const hidden = () => {
    sethide(!hide);
    setName("");
  };
  const [hideph, sethideph] = useState(true);
  const hiddenph= () => {
    sethideph(!hideph);
    setPhone("");
  };
  const [hideE, sethideE] = useState(true);
  const hiddenE= () => {
    sethideE(!hideE);
    setEmail("");
  }
  const [token, setToken] = useState("");

  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);
  useEffect(() => {
    getFreq();
  }, [name,phone,email,token]);

  const SearchrequestName = (e) => {
    setName(e.target.value);
    getFreq();
  };

  const Searchrequestphone = (e) => {
    setPhone(e.target.value);
    getFreq();
  };
  const Searchrequestemail= (e) => {
    setEmail(e.target.value);
    getFreq();
  };
  // if (isLoading) {
  //   return <Loader />;
  // }
  return (
    <Freqlist>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Franchise Req list</h2>
      </div>
      <div className="trip">
        <div className="heading">
          <h1>Franchise Req List</h1>
          <Divider orientation="left" />
        </div>
      </div>

      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>Sl No</h1>
                </th>
                <th className="head-off">
                  <h1>ID</h1>
                </th>
                <th className="head-off">
                  <h1>Name
                  {""}
                    {hide ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hidden()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => SearchrequestName(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hidden()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                   
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Email
                  {""}
                    {hideE ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenE()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestemail(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenE()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>phone
                  {""}
                    {hideph ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenph()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestphone(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenph()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Pincode</h1>
                </th>
                <th className="head-off">
                  <h1>Date Created</h1>
                </th>
                <th className="head-off">
                  <h1>Total Drivers</h1>
                </th>
                <th className="head-off">
                  <h1>Account Status</h1>
                </th>
              </tr>
            </thead>
            <tbody>
              {franchisereq?.map((res, index) => (
                <tr className="body-row-off">
                  <td onClick={() => Franchidedetailspage(res.id)}>
                    {index + 1}
                  </td>
                  <td onClick={() => Franchidedetailspage(res.id)}>{res.id}</td>
                  <td onClick={() => Franchidedetailspage(res.id)}>
                    {res.name}
                  </td>
                  <td onClick={() => Franchidedetailspage(res.id)}>
                    {res.email}
                  </td>
                  <td onClick={() => Franchidedetailspage(res.id)}>
                    {res.phone}
                  </td>
                  <td onClick={() => Franchidedetailspage(res.id)}>
                    {res.pincode}
                  </td>
                  <td onClick={() => Franchidedetailspage(res.id)}>
                    <Moment format="YYYY-MM-DD HH:mm">
                      {res.date_created}
                    </Moment>
                  </td>
                  <td onClick={() => Franchidedetailspage(res.id)}>
                    {res.total_drivers}
                  </td>
                  <td onClick={() => Franchidedetailspage(res.id)}>
                    {res.account_status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Freqlist>
  );
}

export default Franchisereqlist;
