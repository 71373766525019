const initialState ={
    isLoading: true,
    Details:"",
    vehicleDetails:"",
    account_number:"",
    DateOfBirth:"",
    licensecovert:null,
    Profilepicupload:null,
    vehicleimage:null,
    imageleft:null,
    imageright:null,
    imageback:null,
    isDriver:false,
    isVehicle:false,
    rerender:false,
   }
   export default initialState;