import React from "react";
import { Top } from "./style";
import { BellOutlined, PoweroffOutlined } from "@ant-design/icons";
import { Badge, Popconfirm, Popover } from "antd";
import { useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { useHistory } from "react-router-dom";
import logo from "../../../Images/LogoDelibuoy.png"
function CallerHeader() {
  let history = useHistory();

// const homelink = () => {
//   history.push('/home')
// }

  const contents = (
    <div>
      <small>Sign Out</small>
    </div>
  )
  const signout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        history.push(`/`);
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };
  return (
    <Top>
      <div className="header">
        <div className="logo">
          <img src={logo} alt="logo" />
      
        </div>
        <ul>
         
          <li className="link">Contact</li>
          {/* <li className="badge">
            <Badge count={10}>
              <BellOutlined style={{ color: "white", fontSize: "20px" }} />
            </Badge>
          </li> */}
          <Popover content={contents} placement="right">
            <Popconfirm
              title="Are you sure？"
              placement="bottom"
              okText="Yes"
              cancelText="No"
              onConfirm={() => signout()}
            >
              <li className="badge">
                <PoweroffOutlined
                   style={{ color: "white", fontSize: "36px" ,opacity:"0.7"}}
                />
              </li>
            </Popconfirm>
          </Popover>
        </ul>
      </div>
    </Top>
  );
}

export default CallerHeader;
