import React, { useState } from "react";
import { Caller, Loginpage, Informations } from "./style";
import BackArrow from "../Widget/common/BackArrow";
import { Callerlogin } from "./store";
import { Modal, Button } from "antd";
import { useHistory } from "react-router-dom";
function CallerRegistration() {
  let history = useHistory();

  const [
    {
      isLoading,
      firebase_email,
      firebase_password,
      confirmpassword,
      name,
      address,
      phone,
      emails,
      pincode,
      image,
      total_solved_requests,
      isModalVisible,
      usernamec,
      passwordc,
    },
    {
      setPassword,
      setfirebase_email,
      setconfirmpassword,
      SubmitloginC,
      setname,
      setaddress,
      setphone,
      setemails,
      setpincode,
      setimage,
      settotal_solved_requests,
      PostCallerlogin,
      setIsModalVisible,
      setusernamec,
      setpasswordc,
      Relogin,
    },
  ] = Callerlogin();
  let myuser = localStorage.getItem('usernameLogin');
  let myuserpass = localStorage.getItem('passwordLogin');
  let username = myuser;
  let password = myuserpass;
  localStorage.setItem("usernamecaller", username);
  localStorage.setItem("passwordcaller", password);
  let usernamecaller = localStorage.getItem("usernamecaller");
  let passwordcaller = localStorage.getItem("passwordcaller");
  setpasswordc(passwordcaller);
  setusernamec(usernamecaller);
  console.log(usernamecaller, "..............");
  const [page, setpage] = useState(1);
  function goNextpage() {
    setpage((page) => page + 1);
  }
  return (
    <Caller>
      <div className="arrow">
        <BackArrow />
      </div>
      <div className="caller">
        <h1>Register Caller</h1>
        <small></small>
      </div>
      <div className="createf">
        <div className="maindiv">
          {page === 1 && (
            <Callerform1
              setpage={setpage}
              page={page}
              goNextpage={goNextpage}
              firebase_email={firebase_email}
              firebase_password={firebase_password}
              confirmpassword={confirmpassword}
              setfirebase_email={setfirebase_email}
              setPassword={setPassword}
              setconfirmpassword={setconfirmpassword}
              SubmitloginC={SubmitloginC}
            />
          )}
          {page === 2 && (
            <Callerform2
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              setpage={setpage}
              page={page}
              goNextpage={goNextpage}
              firebase_email={firebase_email}
              firebase_password={firebase_password}
              phone={phone}
              emails={emails}
              image={image}
              setphone={setphone}
              setemails={setemails}
              setimage={setimage}
              PostCallerlogin={PostCallerlogin}
              setname={setname}
              name={name}
              pincode={pincode}
              setpincode={setpincode}
              settotal_solved_requests={settotal_solved_requests}
              total_solved_requests={total_solved_requests}
              address={address}
              setaddress={setaddress}
              usernamec={usernamec}
              passwordc={passwordc}
              Relogin={Relogin}
              history={history}
            />
          )}
        </div>
      </div>
    </Caller>
  );
}

export default CallerRegistration;

// form 1.............................................................

function Callerform1({
  setpage,
  page,
  goNextpage,
  firebase_password,
  firebase_email,
  confirmpassword,
  setPassword,
  setfirebase_email,
  setconfirmpassword,
  SubmitloginC,
}) {
  const submitbuttonC = () => {
    let pswd = firebase_password;
    let cpswd = confirmpassword;
    let message = document.getElementById("message");
    if (pswd.length !== 0) {
      if (pswd == cpswd) {
        message.textContent = "Password Match";
        message.style.color = "green";
        SubmitloginC(goNextpage);
      } else {
        message.textContent = "Password doesnt Match!!!";
        message.style.color = "red";
      }
    }
  };

  return (
    <Loginpage>
      <div className="form">
        <div className="formcreate">
          <h1>Create an Account</h1>
        </div>
        <div className="input">
          <input
            placeholder="Enter your email"
            onChange={(e) => setfirebase_email(e.target.value)}
            value={firebase_email}
          ></input>
          <input
            type="password"
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
            value={firebase_password}
          ></input>
          <input
            type="password"
            placeholder="Confirm your password"
            onChange={(e) => setconfirmpassword(e.target.value)}
            value={confirmpassword}
          ></input>
        </div>
        <small id="message" style={{ color: "black" }}></small>
        <div className="buttonfran">
          <button onClick={() => submitbuttonC()}>Next</button>
        </div>
      </div>
    </Loginpage>
  );
}

// form2.......................................................................................

function Callerform2({
  firebase_email,
  firebase_password,
  name,
  address,
  phone,
  emails,
  pincode,
  image,
  total_solved_requests,
  setname,
  setaddress,
  setphone,
  setemails,
  setimage,
  isModalVisible,
  setIsModalVisible,
  setpincode,
  settotal_solved_requests,
  PostCallerlogin,
  usernamec,
  passwordc,
  Relogin,
  history,
}) {
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    Relogin(history);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [preview, setpreview] = useState(null);

  const uploadFile = (e) => {
    setimage(e.target.files[0]);
    setpreview(URL.createObjectURL(e.target.files[0]));
  };
  let imgPreview;
  if (preview) {
    imgPreview = (
      <img src={preview} alt="" style={{ width: "100%", height: "100%" }} />
    );
  }

  const submitformC = () => {
    PostCallerlogin(showModal);
  };
  return (
    <Informations>
      <div className="formcreate">
        <h1>Enter Informations</h1>
      </div>
      <div className="input">
        <input
          placeholder="name"
          onChange={(e) => setname(e.target.value)}
          value={name}
        ></input>
        <input
          placeholder="Address"
          onChange={(e) => setaddress(e.target.value)}
          value={address}
        ></input>

        <input
          placeholder=" email"
          onChange={(e) => setemails(e.target.value)}
          value={emails}
        ></input>
        <input
          maxlength="13"
          value={phone}
          placeholder="Phone"
          onChange={(e) => setphone(e.target.value)}
        ></input>
        <input
          maxlength="6"
          value={pincode}
          placeholder="Pincode"
          onChange={(e) => setpincode(e.target.value)}
        ></input>
        <input
          value={total_solved_requests}
          placeholder="solved requsest"
          onChange={(e) => settotal_solved_requests(e.target.value)}
        ></input>
        <div
          className="pic"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "40%",
          }}
        >
          <input
            type="file"
            id="actual-btn"
            name="filename"
            onChange={uploadFile}
            hidden
          />
          <label for="actual-btn">Upload Image</label>
          <div className="img" style={{ width: "60px", height: "40px" }}>
            {imgPreview}
          </div>
        </div>
        <div className="buttonfran">
          <button onClick={() => submitformC()}>Finish</button>
        </div>
        <Modal
          title="Confirm your login"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div style={{ display: "flex", gap: 20 }}>
            <input value={usernamec}></input>
            <input value={passwordc} type="password"></input>
          </div>
        </Modal>
      </div>
    </Informations>
  );
}
