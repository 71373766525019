import { GetCommisionres } from "../../../../infrastructure/Admin/CommisionReceived";
const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setCommision:
    (Commision) =>
    ({ setState }) => {
      setState({ Commision });
    },
  getCommisions:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetCommisionres();
      
        dispatch(actions.setCommision(res.data.data.commissions));
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
