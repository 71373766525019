import {
  GetDetailsdriver,
  UpdateRazerpaydat,
} from "../../../../infrastructure/Admin/DriverExp";
import { notification } from "antd";
import initialState from "./initialState";
const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setDetaillist:
    (Details) =>
    ({ setState }) => {
      setState({ Details });
    },
  setiddt:
    (idd) =>
    ({ setState }) => {
      setState({ idd });
    },
  setVehicleDetails:
    (vehicleDetails) =>
    ({ setState }) => {
      setState({ vehicleDetails });
    },
  setUpdateRazerpay:
    (razerpayupdate) =>
    ({ setState }) => {
      setState({ razerpayupdate });
    },
  setdriverid:
    (driver_id) =>
    ({ setState }) => {
      setState({ driver_id });
    },
  GetDetailsof:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetDetailsdriver(id);
        dispatch(actions.setDetaillist(res.data.data));
        dispatch(actions.setVehicleDetails(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  putrazerpayid:
    (id) =>
 
    async ({ dispatch, getState }) => {

      dispatch(actions.setisLoading(true));
      const { razerpayupdate } = getState();
      var formdata = new FormData();
      formdata.append("razorpay_id", razerpayupdate);
      try {
        let res = await UpdateRazerpaydat(id, formdata);
  
        let myres = res.response.status;
        dispatch(actions.setUpdateRazerpay(initialState.razerpayupdate));
        if (myres === 200) {
          notification["success"]({
            message: "success",
            description: "Driver Approved successfully",
          });
        } else {
          notification["warning"]({
            message: "warning",
            description: "Driver Approved Unsuccessfully",
          });
        }
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};

export default actions;
