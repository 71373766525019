import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

function SidenavCaller() {
  let history = useHistory();
  const ProfileCaller = () => {
    history.push(`/caller/profile`);
  };
  const callersupportreq = () => {
    history.push(`/caller/supportRequest`);
  };
    const Requesttaken = () => {
      history.push(`/caller/takenRequests`);
    };
  //   const commisionf = () => {
  //     history.push(`/franchise/commision`);
  //   };
  //   const settlementf = () => {
  //     history.push(`/franchise/settlement`);
  //   };
  //   const contactf = () => {
  //     history.push(`/franchise/contact`);
  //   };
  //   const supportf = () => {

  //     history.push(`/franchise/support`);
  //   };

  return (
    <Sidenav>
      <div className="section">
        <div className="small">
          <h1> Caller </h1>
        </div>
        <div
          onClick={() => ProfileCaller()}
          className="separation"
          style={{
            background:
              useLocation().pathname === "/caller/profile" ? "#00a8cb" : "",
          }}
        >
          <h1> Profile </h1>
        </div>
        <div className="border">
          <small></small>
        </div>
        {/* <div
          className="separation"
          style={{
            background:
              useLocation().pathname === "/caller/dashbord" ? "#00a8cb" : "",
          }}
        >
          <h1> Dashbord </h1>
        </div> */}
        {/* <div className="border">
          <small></small>
        </div> */}
        <div
          className="separation"
          onClick={() => callersupportreq()}
          style={{
            background:
              useLocation().pathname === "/caller/supportRequest"
                ? "#00a8cb"
                : "",
          }}
        >
          <h1> Support Request </h1>
        </div>
        <div className="border">
          <small></small>
        </div>
        <div
          className="separation"
          onClick={() => Requesttaken()}
          style={{
            background:
              useLocation().pathname === "/caller/takenRequests"
                ? "#00a8cb"
                : "",
          }}
        >
          <h1>Taken Requests</h1>
        </div>
        {/* <div className="border">
          <small></small>
        </div> */}
        {/* <div
          className="separation"
          style={{
            background:
              useLocation().pathname === "/franchise/settlement"
                ? "#00a8cb"
                : "",
          }}
        >
          <h1>Settlement</h1>
        </div> */}
        {/* <div className="border">
          <small></small>
        </div> */}
      </div>
    </Sidenav>
  );
}

export default SidenavCaller;
const Sidenav = styled.div`
  width: 15vw;
  margin-top: 80px;
  height: 100%;
  background: #f5f5ff5;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  position: fixed;
  .section {
    // height: 800px;
    overflow-y: scroll;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .small {
      padding-bottom: 20px;
      h1 {
        font-family: "pt_serifbold";
        font-size: 24px;
        color: grey;
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 1500px) {
      height:290px;
    }
    .border {
      height: 10px;
    }

    .separation {
      width: 14vw;
      background: #f5f5f5;
      cursor: pointer;
      display: flex;
      justify-content: center;
      height: 60px;

      box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      padding-right: 5px;
      box-sizing: border-box;
      h1 {
        font-family: "montserratregular";
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        color: red;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: black;
        text-decoration: none;

        margin: 0px;
        img {
          margin-right: 24px;
        }
      }
      :hover {
        background: linear-gradient(
          270deg,
          rgba(201, 209, 255, 0.44) 0%,
          #ffffff 101.77%
        );
        border-right: solid 4px black;
      }
    }
  }
`;
