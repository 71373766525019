import {
  GetFranchiseDashboard,
  GetprofilefranchiseDash,
} from "../../../../infrastructure/Admin-franchise/dashboard";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setDashboard:
    (dashboard) =>
    ({ setState }) => {
      setState({ dashboard });
    },
  setDashboard:
    (dashboard) =>
    ({ setState }) => {
      setState({ dashboard });
    },
  setProfile:
    (profile) =>
    ({ setState }) => {
      setState({ profile });
    },
  getdashboard:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetFranchiseDashboard();
  
        dispatch(actions.setDashboard(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  getuserprofile:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));

      try {
        const res = await GetprofilefranchiseDash();
        console.log(res, "resdata");
        dispatch(actions.setProfile(res.data.data));
        dispatch(actions.setisLoading(false));
      } catch (err) {
        window.location.replace(`/franchise/Notfound/`);
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};

export default actions;
