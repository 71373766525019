import styled from "styled-components";
export const About=styled.div`
display:flex;
justify-content:center;
align-items:center;
margin-top:50px;
.pagesection{

  h1{
      padding-top:50px
      font-family: "dm_sansregular";
      font-size:30px;
      @media (max-width:540px) {
          font-size:22px;
      }
  }
  .paragraph{
    display: flex;
    justify-content: center;
    p{ 
        width:50%;
        text-align:justify;
        font-family: "dm_sansregular";
        font-size:18px;
        @media(max-width:540px){
            font-size:16px;
        }
    }
  }
}
`


