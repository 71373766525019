import React,{useEffect,useState}from 'react'
import {Driverfranchise} from './style'
import { Divider } from "antd";
import {LeftOutlined} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Fdriver } from './store';
import Loader from '../../Admin/Widget/common/Loader';
import { firebase } from "../../../Common/firebase";
function Driverf() {
  
  let history = useHistory();
  const DriverExpndf = (id) => {
    history.push(`/franchise/driverExpnd/${id}`);
  };
  const created = () => {
    history.push(`/franchise/driverCreate/`);
  };

  const[{isLoading,driver},{getdriverdetails}]=Fdriver();


    const [token, setToken] = useState("");
    useEffect(() => {
      //import { firebase } from "../../../Common/firebase";
      return firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          user
            .getIdToken(true)
            .then((latestToken) => setToken(latestToken))
            .catch((err) => console.log(err));
        }
      });
    }, []);
    localStorage.setItem("token", token);



  useEffect(() => {
    getdriverdetails();
  }, [token])

  if (isLoading) {
    return <Loader />;
  }
    return (
        <Driverfranchise>
            <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: 'grey' }} />
        <h2>Driver</h2>
      </div>
      <div className="driver">

        <div className="heading">
          <h1>Drivers</h1>
          <Divider orientation="left" />
        </div>
      </div>
      <div className="button">
        <button type="button" onClick={() => created()}>
          Create
        </button>
      </div>
      <div className="content">
        <div className="border" style={{ backgroundColor: 'white'}}>
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No:</h1>
                </th>
                <th className="head-off">
                  <h1>
                    Id
                  </h1>
                </th>

                <th className="head-off">
                  <h1>
                    Name
                  </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Phone
                  </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Email
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Total trips</h1>
                </th>
                <th className="head-off">
                  <h1>Image</h1>
                </th>
              </tr>
            </thead>

            <tbody>
            {driver?.map((res, i) => (
                <tr className="body-row-off">
                  <td onClick={() => DriverExpndf(res.id)}>{i+1}</td>
                  <td onClick={() => DriverExpndf(res.id)}>{res.id}</td>
                  <td onClick={() => DriverExpndf(res.id)}>{res.name}</td>
                  <td onClick={() => DriverExpndf(res.id)} >{res.phone}</td>
                  <td onClick={() => DriverExpndf(res.id)}>{res.email}</td>
                  <td onClick={() => DriverExpndf(res.id)}>{res.total_trips}</td>
                  <td><img src={res.image} style={{width:'50px',borderRadius:'60%',height:50,objectFit:'cover'}} /></td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
        </Driverfranchise>
    )
}

export default Driverf
