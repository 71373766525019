import React, { useEffect, useState } from "react";
import { FranDetail, Basicmodal, Ownermodal } from "./style";
import { Divider } from "antd";
import BackArrow from "../Widget/common/BackArrow";
import { Franchesisdetailpage } from "./store";
import Loader from "../Widget/common/Loader";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Modal, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { firebase } from "../../../Common/firebase";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
function FranchiseDetail({ match }) {
  let history = useHistory();
  const [idd, setidd] = useState(match.params.id);
  const [rerender, setrerender] = useState(false);
  const [
    {
      isLoading,
      latitude,
      farnChiseUser,
      name,
      phone,
      email,
      billingads,
      billing_address,
      pincode,
      account_number,
      ifsc_code,
      bank_name,
      bank_branch,
      owner_name,
      owner_id_proof,
      id_proof_number,
      pan_number,
      id_proof_file,
      pan_file,
      razorpay_id,
      owner_id_proof_option,
      isaccount,
      isBasic,
      isowner
    },
    {
      GetFranchise,
      setFranname,
      setFranphone,
      setFranEmail,
      setFranBilling,
      setFranpicode,
      setFranlongitude,
      setFranlatitude,
      setFranBillings,
      Updatebasic,
      setFranaccount_number,
      setFranifsc_code,
      setFranbank_name,
      setFranbank_branch,
      putAccountsinfo,
      setFranowner_name,
      setFranowner_id_proof,
      setFranid_proof_number,
      setFranpan_number,
      setFranid_proof_file,
      setFranpan_file,
      putOwnerinfo,
      setrazorpayid,
      putRazorpayupdate,
      setowner_id_proof,
      setIsaccount,
      setIsBasic,
      setIsowner
    },
  ] = Franchesisdetailpage();

  //basic modal..........................................


  const showModalBasic = () => {
    setIsBasic(true);
  };

  const handleOkBasic = () => {
    Updatebasic(idd);

    setrerender(!rerender);

 
  };

  const handleCancelBasic = () => {
    setIsBasic(false);
  };
  ///razorpay madal..............................................................
  const [israzerpay, setrazerpay] = useState(false);
  const showModalrazerpay = () => {
    setrazerpay(true);
  };

  const handleOkrazerpay = () => {
    putRazorpayupdate(idd,history);
    setrazerpay(false);
  };

  const handleCancelrazerpay = () => {
    setrazerpay(false);
  };
  //account-info...............................................................


  const showModalaccount = () => {
    setIsaccount(true);
  };

  const handleOkaccount = () => {
    putAccountsinfo(idd);
    setrerender(!rerender);
  
  
  };

  const handleCancelaccount = () => {
    setIsaccount(false);
  };
  //owner info...............................................................


  const showModalowner = () => {
    setIsowner(true);
  };

  const handleOkowner = () => {
    putOwnerinfo(idd);
    setrerender(!rerender);
   

  };

  const handleCancelowner = () => {
    setIsowner(false);
  };
  //map update................................................................
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const latlng = await getLatLng(result[0]);
    setFranBillings(value);
    setFranBilling(value);
    setFranlatitude(latlng.lat);
    setFranlongitude(latlng.lng);
  };
  const [token, setToken] = useState(null);
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);

  useEffect(() => {
    GetFranchise(idd);
  }, [rerender, token]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <FranDetail>
      <div className="title">
        <div className="backarrow">
          <BackArrow />
        </div>
        <div className="heading">
          <h1>Franchise</h1>
          <LeftOutlined style={{ color: "grey" }} />
          <h2> {farnChiseUser.name}</h2>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          style={{
            backgroundColor: "rgb(0, 203, 41)",
            border: "none",
            width: 150,
            height: 45,
            borderRadius: 4,
            color: "white",
            fontWeight: "bold",
          }}
          onClick={() => showModalrazerpay()}
        >
          Approve Franchise
        </button>
      </div>
      <div className="Main">
        <div className="heading">
          <h1>Basic Information</h1>
          <small></small>
        </div>

        <div className="basic">
          <div className="up">
            <div className="info">
              <h1>Name</h1>
              <small> : </small>
              <div className="basicinfo">
                <h2>{farnChiseUser.name}</h2>
              </div>
            </div>
            <div className="info">
              <h1>Phone</h1>
              <small> : </small>
              <div className="basicinfo">
                <h2>{farnChiseUser.phone}</h2>
              </div>
            </div>
            <div className="info">
              <h1>Email</h1>
              <small> : </small>
              <div className="basicinfo">
                <h2>{farnChiseUser.email}</h2>
              </div>
            </div>

            <div className="info">
              <h1>Billing address</h1>
              <small> : </small>
              <div className="basicinfo">
                <h2>{farnChiseUser.billing_address}</h2>
              </div>
            </div>
            <div className="info">
              <h1>Pincode</h1>
              <small> : </small>
              <div className="basicinfo">
                <h2>{farnChiseUser.pincode}</h2>
              </div>
            </div>
            <div className="info">
              <h1>Total drivers</h1>
              <small> : </small>
              <div className="basicinfo">
                <h2>{farnChiseUser.total_drivers}</h2>
              </div>
            </div>
          </div>
          <div
            className="button"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              onClick={() => showModalBasic()}
              style={{
                width: 80,
                height: 35,
                fontSize: 14,
                border: "none",
                backgroundColor: "#00a8cb",
                borderRadius: "6px",
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>

      <div className="Main">
        <div className="heading">
          <h1>Account Information</h1>
          <small></small>
        </div>
        <div className="basic">
          <div className="up">
            <div className="info">
              <h1>Account Number</h1>
              <small> : </small>
              <div className="basicinfo">
                <h2>{farnChiseUser?.account_information?.account_number}</h2>
              </div>
            </div>
            <div className="info">
              <h1>IFSC Code</h1>
              <small> : </small>
              <div className="basicinfo">
                <h2>{farnChiseUser?.account_information?.ifsc_code}</h2>
              </div>
            </div>
            <div className="info">
              <h1>Bank Name</h1>
              <small> : </small>
              <div className="basicinfo">
                <h2>{farnChiseUser?.account_information?.bank_name}</h2>
              </div>
            </div>

            <div className="info">
              <h1>Bank branch</h1>
              <small> : </small>
              <div className="basicinfo">
                <h2>{farnChiseUser?.account_information?.bank_branch}</h2>
              </div>
            </div>
          </div>
          <div
            className="button"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              onClick={() => showModalaccount()}
              style={{
                width: 80,
                height: 35,
                fontSize: 14,
                border: "none",
                backgroundColor: "#00a8cb",
                borderRadius: "6px",
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div className="Main">
        <div className="heading">
          <h1>Owner Information </h1>
          <small></small>
        </div>
        <div
          className="button"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            onClick={() => showModalowner()}
            style={{
              width: 80,
              height: 35,
              fontSize: 14,
              border: "none",
              backgroundColor: "#00a8cb",
              borderRadius: "6px",
            }}
          >
            Update
          </button>
        </div>
        <div className="basic">
          <div className="info">
            <h1>Owner Name</h1>
            <small> : </small>
            <div className="basicinfo">
              <h2>{farnChiseUser?.owner_information?.owner_name}</h2>
            </div>
          </div>
          <div className="info">
            <h1>Owner Id Proof</h1>
            <small> : </small>
            <div className="basicinfo">
              <h2>{farnChiseUser?.owner_information?.owner_id_proof}</h2>
            </div>
          </div>
          <div className="info">
            <h1>ID Proof Number</h1>
            <small> : </small>
            <div className="basicinfo">
              <h2>{farnChiseUser?.owner_information?.id_proof_number}</h2>
            </div>
          </div>

          <div className="info">
            <h1>Pan Number</h1>
            <small> : </small>
            <div className="basicinfo">
              <h2>{farnChiseUser?.owner_information?.pan_number}</h2>
            </div>
          </div>
          <div className="infoimage" style={{ display: "flex" }}>
            <h1> ID Proof </h1>
            <small> </small>
            <div className="basicinfo" style={{ width: 300 }}>
              <p>
                Check out{" "}
                <a
                  href={farnChiseUser?.owner_information?.id_proof_file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ID Proof
                </a>
                .
              </p>
            </div>
          </div>
          <div className="infoimage" style={{ display: "flex" }}>
            <h1>Pan Card</h1>
            <small> </small>
            <div style={{ width: 300 }}>
              <p>
                Check out{" "}
                <a
                  href={farnChiseUser?.owner_information?.pan_file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pan file
                </a>
                .
              </p>
            </div>
          </div>
        </div>
   
      </div>

      <Modal
        title="Update Basic Information"
        visible={isBasic}
        fontWeight="bold"
        onOk={handleOkBasic}
        onCancel={handleCancelBasic}
      >
        <div className="modalcontent">
          <Basicmodal>
            <input
              type="text"
              value={name}
              placeholder="Enter your name"
              onChange={(e) => setFranname(e.target.value)}
            ></input>
            <input
              type="text"
              placeholder="Enter your Mobile Number"
              value={phone}
              onChange={(e) => setFranphone(e.target.value)}
            ></input>
            <input
              placeholder="Enter your Pincode"
              type="text"
              value={pincode}
              onChange={(e) => setFranpicode(e.target.value)}
            ></input>
            <PlacesAutocomplete
              value={billingads}
              onChange={setFranBilling}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div style={{ width: "395px" }}>
                  {/* <div style={{ display: "flex", gap: 10 }}>
              {" "}
              <small>Latitude:{coordinates.lat}</small>
              <small>Latitude:{coordinates.lng}</small>
            </div> */}

                  <input
                    {...getInputProps({ placeholder: "Enter Billing Address" })}
                  />
                  <div>
                    {loading ? <div>...loading </div> : null}
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active ? "#00a8cb" : "#fff",
                      };
                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Basicmodal>
        </div>
      </Modal>
      {/* //2nmodal........................................................... */}

      <Modal
        title="Update Account Information"
        visible={isaccount}
        fontWeight="bold"
        onOk={handleOkaccount}
        onCancel={handleCancelaccount}
      >
        <Basicmodal>
          <input
            type="text"
            placeholder="Enter Your Account Number"
            value={account_number}
            onChange={(e) => setFranaccount_number(e.target.value)}
          ></input>
          <input
            placeholder="Enter your IFSC Code"
            type="text"
            value={ifsc_code}
            onChange={(e) => setFranifsc_code(e.target.value)}
          ></input>
          <input
            type="text"
            placeholder="Enter your Bank Name"
            value={bank_name}
            onChange={(e) => setFranbank_name(e.target.value)}
          ></input>
          <input
            placeholder="Enter your Bank branch"
            type="text"
            value={bank_branch}
            onChange={(e) => setFranbank_branch(e.target.value)}
          ></input>
        </Basicmodal>
      </Modal>
      {/* //3rd modal..............................
          
       */}
      <Modal
        title="Update Owner Information"
        visible={isowner}
        fontWeight="bold"
        onOk={handleOkowner}
        onCancel={handleCancelowner}
      >
        <Ownermodal>
          <input
            type="text"
            placeholder="Enter Owner Name"
            value={owner_name}
            onChange={(e) => setFranowner_name(e.target.value)}
          ></input>

          {/* <input
            placeholder="Enter  ID Proof"
            type="text"
            value={owner_id_proof}
            onChange={(e) => setFranowner_id_proof(e.target.value)}
          
          >
           </input> */}
          <select
            style={{ width: "85%" ,height:40}}
            value={owner_id_proof_option}
            onChange={(e) => setowner_id_proof(e.target.value)}
          >
            <option value="adhaar">Aadhar</option>
            <option value="voter-id">Voters Id</option>
            <option value="Passport">Passport</option>
            <option value="driving-license">Driving License</option>
          </select>
          <input
            type="text"
            placeholder="Enter ID Proof Number"
            value={id_proof_number}
            onChange={(e) => setFranid_proof_number(e.target.value)}
          ></input>

          <input
            type="text"
            placeholder="Enter Your Pan Number"
            value={pan_number}
            onChange={(e) => setFranpan_number(e.target.value)}
          ></input>

          <div class="upload-btn-wrapper">
            <button class="btn">Upload id proof</button>
            <input
              type="file"
              id="myFile"
              name="filename"
              onChange={(e) => setFranid_proof_file(e.target.files[0])}
            />
          </div>

          <div class="upload-btn-wrapper">
            <button class="btn">Upload pan card</button>
            <input
              type="file"
              id="myFile"
              name="filename"
              onChange={(e) => setFranpan_file(e.target.files[0])}
            />
          </div>
        </Ownermodal>
      </Modal>
      {/* //razorpay modal */}

      <Modal
        title="Update Driver Razorpay"
        visible={israzerpay}
        onOk={handleOkrazerpay}
        onCancel={handleCancelrazerpay}
      >
        <input
          value={razorpay_id}
          onChange={(e) => setrazorpayid(e.target.value)}
          placeholder="Enter your razorpay id"
          style={{ width: 450, height: 40, paddingLeft: 10 }}
        ></input>
      </Modal>
    </FranDetail>
  );
}

export default FranchiseDetail;
