import React from "react";
import { MyLoginCaller } from "./store";
import { Main } from "./style";
import { useHistory } from "react-router-dom";
function Caller() {
  let history = useHistory();
  const [{ Password, Username }, { setUsername, Submitlogin, setPassword }] =
    MyLoginCaller();
  function handleKeyPress(e) {
    if (e.key === "Enter") {
      if (Username !== "" && Password !== "") {
        Submitlogin(history);
      }
    }
  }
  const handleChange = (e) => {
    e.preventDefault();
  };

  return (
    <Main>
      <div className="box">
        <div className="heading">
          <h1>Delibuoy</h1>
        </div>
        <div className="inpt">
          <p>Login to Caller</p>
          <input
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            className="small"
            type="text"
            value={Username}
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="enter your username"
          />
          <input
            classNmae="small"
            value={Password}
            type="password"
            placeholder="enter your password"
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>

        <div className="button">
          <button onClick={() => Submitlogin(history)}> Login</button>
        </div>
      </div>
    </Main>
  );
}

export default Caller;
