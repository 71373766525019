import React, { useEffect, useState } from "react";
import { DriverDiv } from "./style";
import { GetDriverdetails } from "./store";
import { useHistory } from "react-router-dom";
import { Divider } from "antd";
import Loader from "../Widget/common/Loader";
import { SearchOutlined, CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { Empty, Input } from "antd";
import Spinner from "../Widget/common/Spinner";
import { firebase } from "../../../Common/firebase";
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
function Driver() {
  let history = useHistory();

  const [
    { isLoading, driver, drivername, driverphone, driveremail, driverid },
    {
      getDrivers,
      setdriveremail,
      setdrivername,
      setdriverphone,
      setdriverid,
      getblockDrivers,
      getunblockDrivers,
    },
  ] = GetDriverdetails();
  const [hide, sethide] = useState(true);
  const hidden = () => {
    sethide(!hide);
    setdrivername("");
  };
  const [hideP, sethideP] = useState(true);
  const hiddenP = () => {
    sethideP(!hideP);
    setdriverphone("");
  };
  const [hideE, sethideE] = useState(true);
  const hiddenE = () => {
    sethideE(!hideE);
    setdriveremail("");
  };
  const [hideI, sethideI] = useState(true);
  const hiddenI = () => {
    sethideI(!hideI);
    setdriverid("");
  };
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);

  useEffect(() => {
    getDrivers();
  }, [drivername, driverphone, driveremail, driverid, token]);

  const DriverExp = (id) => {
    history.push(`/admin/driverExp/${id}`);
  };

  const Searchrequestname = (e) => {
    setdrivername(e.target.value);
    getDrivers();
  };

  const Searchrequestphone = (e) => {
    setdriverphone(e.target.value);
    getDrivers();
  };

  const Searchrequestemail = (e) => {
    setdriveremail(e.target.value);
    getDrivers();
  };
  const Searchrequestid = (e) => {
    setdriverid(e.target.value);
    getDrivers();
  };

  const blockdriver = (id) => {
    getblockDrivers(id);
  };
  const unblockdriver = (id) => {
    getunblockDrivers(id);
  };

  // if (isLoading) {
  //   return <Loader />;
  // }

  return (
    <DriverDiv>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Driver</h2>
      </div>
      <div className="driver">
        <div className="heading">
          <h1>Drivers</h1>
          <Divider orientation="left" />
        </div>
      </div>

      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No:</h1>
                </th>
                <th className="head-off">
                  <h1>
                    Id{""}
                    {hideI ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenI()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestid(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenI()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>

                <th className="head-off">
                  <h1>
                    Name
                    {hide ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hidden()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestname(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hidden()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Phone{" "}
                    {hideP ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenP()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestphone(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenP()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Email{""}
                    {hideE ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenE()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestemail(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenE()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Block</h1>
                </th>
                <th className="head-off">
                  <h1>Vehicle Reg Num</h1>
                </th>
                <th className="head-off">
                  <h1>Account Status</h1>
                </th>
              </tr>
            </thead>

            <tbody>
              {driver?.map((res, i) => (
                <tr className="body-row-off">
                  <td onClick={() => DriverExp(res.id)}>{i + 1}</td>
                  <td onClick={() => DriverExp(res.id)}>{res.id}</td>
                  <td onClick={() => DriverExp(res.id)}>{res.name}</td>

                  <td onClick={() => DriverExp(res.id)}>
                    <h1>{res.phone}</h1>
                  </td>
                  <td onClick={() => DriverExp(res.id)}>
                    <h2> {res.email}</h2>
                  </td>
                  <td>
                    {res.is_blocked === false ? (
                      <button
                        style={{
                          width: 80,
                          height: 30,
                          borderRadius: 30,
                          border: "none",
                          fontFamily: "dm_sansregular",
                          fontSize: 14,
                          backgroundColor: "#f5f5f5",
                          fontWeight: "bold",
                          letterSpacing: ".5px",
                          color: "red",
                        }}
                        onClick={() => blockdriver(res.id)}
                      >
                        {" "}
                        Block
                      </button>
                    ) : (
                      <button
                        style={{
                          width: 80,
                          height: 30,
                          borderRadius: 30,
                          border: "none",
                          fontFamily: "dm_sansregular",
                          fontWeight: "bold",
                          fontSize: 14,
                          backgroundColor: "#f5f5f5",
                          letterSpacing: ".5px",
                          color: "blue",
                        }}
                        onClick={() => unblockdriver(res.id)}
                      >
                        {" "}
                        UnBlock
                      </button>
                    )}
                  </td>
                  <td>{res.vehicle_registration_number} </td>
                  <td>{res.account_status} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </DriverDiv>
  );
}

export default Driver;
