import styled from "styled-components";
export const Caller =styled.div `
width: 85vw;
margin-left:15vw;
margin-top:80px;
.arrow {
    margin-top: 60px;
    padding-left: 50px;
  }
  .caller {
    display: flex;
    padding-left: 80px;
    margin-top: 50px;
    margin-bottom: 100px;
    align-items: center;
    gap: 15px;
    h1 {
      font-family: "Quicksand", sans-serif;
      font-size: 27px;
      font-weight: bold;
      width: 350px;
    }
    small {
      border: 1px solid #f0f0f0;
      height: 1px;
      width: 100%;
    }
  }
`
export const Loginpage = styled.div`
margin-top: 50px;
.form {
  .formcreate {
    h1 {
      font-size: 28px;
      font-family: "montserratregular";
      font-weight: bold;
    }
  }
  .input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    gap: 17px;
    input {
      border-radius: 3.5px;
      padding: 1vh;
      font-size: 1.7vh;
      border: 1px solid grey;
      height: 6vh;
      background: white;
      box-shadow: inset 0 -1px 0 #00a8cb;
      width:40%;
    }
  }
  .buttonfran {
    margin-top: 20px;
    margin-bottom: 30px;
    button {
      width: 100px;
      height: 50px;
      border: none;
      background-color: #00a8cb;
      border-radius: 4px;
      &:active {
        background-color: black;
        color: white;
      }
    }
  }
}
`;
export const Informations=styled.div`
margin-top: 50px;
    .formcreate {
      height: 2px;
      h1 {
        font-size: 28px;
        font-family: "montserratregular";
        font-weight: bold;
      }
    }
    .input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      gap: 17px;
      input {
        border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid grey;
        height: 6vh;
        background: white;
        box-shadow: inset 0 -1px 0 #00a8cb;
        width: 40%;
      }
    
    }
   
    label {
      background-color:grey;
      color: black;
      padding: 0.5rem;
      font-family: sans-serif;
      border-radius: 0.3rem;
      cursor: pointer;
      margin-top: 1rem;
      font-size:12px;
      height:30px;
    }
    

    .buttonfran {
      margin-top: 10px;
      margin-bottom: 30px;
      button {
        width: 150px;
        height: 40px;
        border: none;
        background-color: #00a8cb;
        border-radius: 4px;
        &:active {
          background-color: black;
          color: white;
        }
      }
    }
  
`;

