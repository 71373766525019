import React, { useEffect,useState } from "react";
import { GetTripsdetails } from "./store";
import { Tripsdiv } from "./style";
import { useHistory } from "react-router-dom";
import { Divider } from "antd";
import Loader from "../Widget/common/Loader";
import { firebase } from "../../../Common/firebase";
import {LeftOutlined}  from "@ant-design/icons";
function Trips() {
  const [{ isLoading, trips }, { gettrips }] = GetTripsdetails();
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);
  useEffect(() => {
    gettrips();
  }, [token]);

  
  if(isLoading) {
      return <Loader/>
  }
  return <Tripsdiv>
     <div className="title">
          <h1>Home</h1>
          <LeftOutlined style={{color:'grey'}}/>
          <h2>Trips</h2>
        </div>
      <div className="trip">
        <div className="heading">
          <h1>Trips</h1>
          <Divider orientation="left" />
        </div>
      </div>

      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No</h1>
                </th>
                <th className="head-off">
                  <h1>ID</h1>
                </th>
                <th className="head-off">
                  <h1>Start Date</h1>
                </th>
                <th className="head-off">
                  <h1>Driver</h1>
                </th>
                <th className="head-off">
                  <h1>Total Distance</h1>
                </th>
                <th className="head-off">
                  <h1>Total Charge</h1>
                </th>
                <th className="head-off">
                  <h1>Commission</h1>
                </th>
                <th className="head-off">
                  <h1>Status</h1>
                </th>
              </tr>
            </thead>

            <tbody>
            {trips?.map((res,i)=>(
                <tr className="body-row-off">
                  <td >{i+1}</td>
                  <td >{res.id}</td>
                  <td >{res.start_date}</td>
                  <td >
                    <h1>{res.driver}</h1>
                  </td>
                  <td >
                    <h2>{res.total_distance}</h2>
                  </td>
                  <td>{res.total_charge} </td>
                  <td>{res.commission} </td>
                  <td>{res.status} </td>
                </tr>
             ))}
            </tbody>
          </table>
        </div>
      </div>
  </Tripsdiv>;
}

export default Trips;
