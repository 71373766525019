import React, { useEffect, useState } from "react";
import { DashboardContainer } from "./style";
import { Createadminget } from "./store";
import Loader from "../Widget/common/Loader";
import { firebase } from "../../../Common/firebase";
function Dashboard() {
  // const base = async (options) => {
  //   const user = firebase.auth().currentUser;
  //   const mytoken = user && (await user.getIdToken());
  //   console.log(mytoken,"mytoken..Fra.....................")
  // }

  const [{ isLoading, user, dashboard }, { getAdmin, getDashboard }] =
    Createadminget();

  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);

  useEffect(() => {
    getAdmin();
    getDashboard();
  }, [token]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <DashboardContainer>
      <div className="section">
        <div className="item1">
          <h1>Franchises</h1>
          <h2>{dashboard?.franchises}</h2>
        </div>
        <div className="item2">
          <h1>Drivers</h1>
          <h2>{dashboard?.drivers}</h2>
        </div>
        <div className="item3">
          <h1>Total completed trips</h1>

          <h2>{dashboard?.total_completed_trips}</h2>
        </div>
        <div className="item4">
          <h1>Commision Received</h1>
          <h2>{dashboard?.total_commission_received}</h2>
        </div>
      </div>
    </DashboardContainer>
  );
}

export default Dashboard;
