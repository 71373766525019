import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import BackArrow from "../Widget/common/BackArrow";
import Loader from "../Widget/common/Loader";
import { GetDriverdetails } from "./store";
import { DriverDetails, Vechicleimages } from "./style";
import { LeftOutlined } from "@ant-design/icons";
import { Modal, Button, Image } from "antd";
import { findAllInRenderedTree } from "react-dom/test-utils";
import { firebase } from "../../../Common/firebase";
function DriverExp({ match }) {
  const [
    { isLoading, Details, vehicleDetails, razerpayupdate },
    { GetDetailsof, setUpdateRazerpay, putrazerpayid },
  ] = GetDriverdetails();

  console.log(Details, ".........................................");
  const [idd, setidd] = useState(match.params.id);

  const [israzerpay, setrazerpay] = useState(false);

  const showModalrazerpay = () => {
    setrazerpay(true);
  };

  const handleOkrazerpay = () => {
    putrazerpayid(idd);
    setrazerpay(false);
  };

  const handleCancelrazerpay = () => {
    setrazerpay(false);
  };
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);
  useEffect(() => {
    GetDetailsof(idd);
  }, [token]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DriverDetails>
      <div className="title">
        <h1>Driver</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2> {Details.name}</h2>
      </div>
      <div className="arrow">
        <BackArrow />
      </div>
      <div className="main">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            style={{
              backgroundColor: "rgb(0, 203, 41)",
              border: "none",
              width: 150,
              height: 45,
              borderRadius: 4,
              color: "white",
              fontWeight: "bold",
            }}
            onClick={() => showModalrazerpay()}
          >
            Approve Razerpay
          </button>
        </div>
        <div className="heading">
          <h1>Driver</h1>
          <Divider orientation="left" />
        </div>

        <div className="section">
          <div className="small">
            <div className="item">
              <div className="name">
                <h1>Name </h1>
                <span>:</span>
                <h2> {Details.name} </h2>
              </div>
              <div className="name">
                <h1>Phone</h1>
                <span>:</span>
                <h2>{Details.phone}</h2>
              </div>
              <div className="name">
                <h1>Email </h1>
                <span>:</span>
                <h2>{Details.email}</h2>
              </div>
              <div className="name">
                <h1>Account Number </h1>
                <span>:</span>
                <h2>{Details.account_number}</h2>
              </div>
              <div className="name">
                <h1>IFSC Code </h1>
                <span>:</span>
                <h2>{Details.ifsc_code}</h2>
              </div>
              <div className="name">
                <h1>Bank Name </h1>
                <span>:</span>
                <h2>{Details.bank_name}</h2>
              </div>
              <div className="name">
                <h1>UPI </h1>
                <span>:</span>
                <h2>{Details.upi}</h2>
              </div>
            </div>
          </div>
          <div
            className="image"
            style={{
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              objectFit: "cover",
              borderRadius: 10,
            }}
          >
            <Image width={200} height={200} src={Details.image} />
          </div>
        </div>
        <div className="heading">
          <h1>Vehicle Details</h1>
          <small></small>
        </div>
        <div className="section">
          <div className="small">
            <div className="item">
              <div className="name">
                <h1>Vehicle Model</h1>
                <span> : </span>
                <h2>{Details?.vehicle_information?.vehicle_model}</h2>
              </div>
              <div className="name">
                <h1>Register Number</h1>
                <span> : </span>
                <h2>{Details?.vehicle_information?.register_number}</h2>
              </div>
              <div className="name">
                <h1>Year of Registration</h1>
                <span> : </span>
                <h2>{Details?.vehicle_information?.year_of_registration}</h2>
              </div>
              <div className="name">
                <h1>Body color</h1>
                <span> : </span>
                <h2>{Details?.vehicle_information?.body_color}</h2>
              </div>
              <div className="name">
                <h1>Max load</h1>
                <span> : </span>
                <h2>{Details?.vehicle_information?.max_load}</h2>
              </div>
              <div className="name">
                <h1>PCC </h1>
                <span> : </span>
                <a
                  href={Details?.vehicle_information?.pcc_file}
                  target="_blank"
                >
                  <h2>PCC File</h2>
                </a>
              </div>
              <div className="name">
                <h1>NOC</h1>
                <span> : </span>
                <a
                  href={Details?.vehicle_information?.noc_file}
                  target="_blank"
                >
                  <h2>Noc File</h2>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="heading">
          <h1>Vehicle Images</h1>
          <small></small>
        </div>
        <Vechicleimages>
          <div className="vech">
            <div className="label">
              <label>Front</label>
              <a
                href={Details?.vehicle_information?.vehicle_image}
                target="_blank"
              >
                <img src={Details?.vehicle_information?.vehicle_image} alt />
              </a>
            </div>
            <div className="label">
              <label>Right</label>
              <a
                href={Details?.vehicle_information?.image_right}
                target="_blank"
              >
                <img src={Details?.vehicle_information?.image_right} alt></img>
              </a>
            </div>

            <div className="label">
              <label>Left</label>
              <a
                href={Details?.vehicle_information?.image_left}
                target="_blank"
              >
                <img src={Details?.vehicle_information?.image_left} alt></img>
              </a>
            </div>

            <div className="label">
              <label>Back</label>
              <a
                href={Details?.vehicle_information?.image_back}
                target="_blank"
              >
                <img src={Details?.vehicle_information?.image_back} alt></img>
              </a>
            </div>
          </div>
        </Vechicleimages>
      </div>
      <Modal
        title="Update Driver Razorpay"
        visible={israzerpay}
        onOk={handleOkrazerpay}
        onCancel={handleCancelrazerpay}
      >
        <input
          value={razerpayupdate}
          onChange={(e) => setUpdateRazerpay(e.target.value)}
          placeholder="Enter your razorpay id"
          style={{ width: 450, height: 40, paddingLeft: 10 }}
        ></input>
      </Modal>
    </DriverDetails>
  );
}

export default DriverExp;
