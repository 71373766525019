import "./App.css";
import "antd/dist/antd.css";
import LoginPage from "././Presentation/Admin/LoginPage";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminDashboard from "./Presentation/Admin/Component/DashboardRouting";

import ReportError from "./Presentation/Admin/Report";
// import Home from "./Presentation/website_Delibuoy/Home";
import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import RouterWeb from "./Presentation/website_Delibuoy/Router.js";
import ContactUs from "./Presentation/website_Delibuoy/ContactUs";
import Franchiselogin from "./Presentation/Admin-franchise/Login";
import Routerfranchise from "./Presentation/Admin-franchise/Router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import Privacypolicy from "./Presentation/website_Delibuoy/PrivacyPo";
import Landingpage from "./Presentation/website_Delibuoy/landing";
import Caller from "./Presentation/Caller/login";
import RouterCaller from "./Presentation/Caller/Router";
import Termsandconditions from "./Presentation/website_Delibuoy/TermsAndConditions";
import Home from "./Presentation/website_Delibuoy/Home";
import Aboutus from "./Presentation/website_Delibuoy/AboutUs";
import ShippingPolicy from "./Presentation/website_Delibuoy/Shipping and policy";
import ReturnCancellation from "./Presentation/website_Delibuoy/ReturnCancellation";
import { firebase } from "./Common/firebase/index";
import Dashboardf from "./Presentation/Admin-franchise/Dashboard";
import Details from "./Presentation/website_Delibuoy/FranchiseDetails";

function App({users}) {



  const [token, setToken] = useState("");

  let user = firebase.auth().currentUser;
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(setCurrentUser);
  }, []);

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)

          .then((latestToken) => setToken(latestToken))

          .catch((err) => console.log(err));
      }
    });
  }, []);

  const [count, setCount] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      setCount(false);
    }, 3000);
 // Update count to be 5 after timeout is scheduled
  }, []);

 console.log(count,
  "")
  return (
    <div className="App">
      <Helmet>
        <title>Delibuoy</title>
        <meta
          name="description"
          content="Delibuoy is a one-stop delivery solution to individuals and businesses that takes care of point-to-point pickup and delivery locally. We present a wide range of goods vehicles right from 2-wheelers to large trucks and everything in-between to pick up and deliver everything from roses and cakes to inventory cartons and more."
        />
      </Helmet>
      <BrowserRouter>
        <>
          <Switch>
            <Route exact path="/admin" component={LoginPage} />
            <Route exact path="/caller" component={Caller} />
           
            <Route exact path="/franchise">
              {currentUser === null ? (
                 <Franchiselogin />
              ) : (
                <Redirect to="/franchise/dashbord" />
              
              )}
            </Route>
            <Route exact path="/franchise/router" component={Routerfranchise} />
            <Route exact path="/" component={Landingpage} />
            <Route path="/report" component={ReportError} />
            <Route path="/admin" component={AdminDashboard} />
            <Route path="/franchise" component={Routerfranchise} />
            <Route path="/caller" component={RouterCaller} />
            <Route path="/contact" component={ContactUs} />
            <Route path="/privacyPolicy" component={Privacypolicy} />
            <Route path="/termsandconditions" component={Termsandconditions} />
            <Route exact path="/home" component={RouterWeb} />
            <Route exact path="/aboutus" component={Aboutus} />
            <Route exact path="/shippingPolicy" component={ShippingPolicy} />
            <Route
              exact
              path="/cancellationPolicy"
              component={ReturnCancellation}
            />
            {/* <Route exact path='/details' component={Details} /> */}
          </Switch>
        </>
      </BrowserRouter>
    </div>
  );
}

export default App;
