import React from "react";
import Styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
export default function Sidenav() {
  let history = useHistory();

  const dashboard = () => {
    history.push(`/admin/dashboard`);
  };
  const Franchises = () => {
    history.push(`/admin/franchises`);
  };
  const Drivers = () => {
    history.push(`/admin/driver`);
  };
  const Customers = () => {
    history.push(`/admin/customer`);
  };
  const Trips = () => {
    history.push(`/admin/trips`);
  };
  const Payments = () => {
    history.push(`/admin/payments`);
  };

  const CommisionReceived = () => {
    history.push(`/admin/commisionreceived`);
  };
  const Caller = () => {
    history.push(`/admin/caller`);
  };
  // const SupportRequest = () => {
  //   history.push(`/admin/supportrequest`);
  // };
  const settlementDues = () => {
    history.push(`/admin/settlementDues`);
  };
  const Franchisesettlement = () => {
    history.push(`/admin/franchissettlement`);
  };
  const DriverPayments = () => {
    history.push(`/admin/driverpayments`);
  };
  const Charges = () => {
    history.push(`/admin/delibuoycharges/`);
  };
  const settlement = () => {
    history.push(`/admin/settlement`);
  };
  const Registration = () => {
    history.push(`/admin/registration`);
  };
  const reqlistF = () => {
    history.push(`/admin/franchisereqlist`);
  };
  const Driverreqlist = () => {
    history.push(`/admin/driverreqlist`);
  };
  return (
    <Sidebar>
      <div className="first">
        <div className="small">
          <h1>Admin</h1>
        </div>
       <div className="sidebar">
        <div
          onClick={() => dashboard()}
          className="separation"
          style={{
            background:
              useLocation().pathname === "/admin/dashboard" ? "#00a8cb" : "",
          }}
        >
          <h1> Dashbord </h1>
        </div>
        <div
          onClick={() => reqlistF ()}
          className="separation"
          style={{
            background:
              useLocation().pathname === "/admin/franchisereqlist" ? "#00a8cb" : "",
          }}
        >
          <h1>Franchises Req List </h1>
        </div>
        <div
          onClick={() => Driverreqlist ()}
          className="separation"
          style={{
            background:
              useLocation().pathname === "/admin/driverreqlist" ? "#00a8cb" : "",
          }}
        >
          <h1>Driver Req List </h1>
        </div>

        <div
          onClick={() => Franchises()}
          className="separation"
          style={{
            background:
              useLocation().pathname === "/admin/franchises" ? "#00a8cb" : "",
          }}
        >
          <h1>Franchises </h1>
        </div>
        <div
          className="separation"
          style={{
            background:
              useLocation().pathname === "/admin/driver" ? "#00a8cb" : "",
          }}
          onClick={() => Drivers()}
        >
          <h1>Drivers</h1>
        </div>
        <div
          onClick={() => Customers()}
          className="separation"
          style={{
            background:
              useLocation().pathname === "/admin/customer" ? "#00a8cb" : "",
          }}
        >
          <h1>Customers</h1>
        </div>
        <div className="border">
          <small></small>
        </div>

        <div
          className="separation"
          onClick={() => Trips()}
          style={{
            background:
              useLocation().pathname === "/admin/trips" ? "#00a8cb" : "",
          }}
        >
          <h1>Trips</h1>
        </div>
        <div
          className="separation"
          onClick={() => Payments()}
          style={{
            background:
              useLocation().pathname === "/admin/payments" ? "#00a8cb" : "",
          }}
        >
          <h1>Payments</h1>
        </div>

        <div
          className="separation"
          onClick={() => CommisionReceived()}
          style={{
            background:
              useLocation().pathname === "/admin/commisionreceived"
                ? "#00a8cb"
                : "",
          }}
        >
          <h1>CommissionRecived</h1>
        </div>

        <div className="border">
          <small></small>
        </div>
        <div
          className="separation"
          onClick={() => Caller()}
          style={{
            background:
              useLocation().pathname === "/admin/caller" ? "#00a8cb" : "",
          }}
        >
          <h1>Caller</h1>
        </div>

        {/* <div
          className="separation"
          onClick={() => SupportRequest()}
          style={{
            background:
              useLocation().pathname === "/admin/supportrequest"
                ? "#00a8cb"
                : "",
          }}
        >
          <h1>SupportRequest</h1>
        </div> */}
        <div className="border">
          <small></small>
        </div>
        <div
          className="separation"
          onClick={() => settlementDues()}
          style={{
            background:
              useLocation().pathname === "/admin/settlementDues"
                ? "#00a8cb"
                : "",
          }}
        >
          <h1>Franchise Settlement Dues</h1>
        </div>
        <div
          className="separation"
          onClick={() => Franchisesettlement()}
          style={{
            background:
              useLocation().pathname === "/admin/franchissettlement"
                ? "#00a8cb"
                : "",
          }}
        >
          <h1>FranchiseSettlements</h1>
        </div>
        <div
          className="separation"
          onClick={() => DriverPayments()}
          style={{
            background:
              useLocation().pathname === "/admin/driverpayments"
                ? "#00a8cb"
                : "",
          }}
        >
          <h1>Driver Payments</h1>
        </div>
        <div
          className="separation"
          onClick={() => Charges()}
          style={{
            background:
              useLocation().pathname === "/admin/delibuoycharges/" ? "#00a8cb" : "",
          }}
        >
          <h1>Charges</h1>
        </div>
        <div
          className="separation"
          onClick={() =>Registration()}
          style={{
            background:
              useLocation().pathname === "/admin/registration" ? "#00a8cb" : "",
          }}
        >
          <h1>Admin Registration</h1>
        </div>
        </div>
      </div>
    </Sidebar>
  );
}
const Sidebar = Styled.div`

    width : 15vw;
    margin-top:80px;
height:100%;
background: #f5f5ff5;
box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
position: fixed;

    .first{
       height: 800px;
      display: flex;
       flex-direction: column;
      justify-content: center;
      align-items: center;
      -ms-overflow-style: none;
       scrollbar-width: none;
  
      .small{
        padding-top:15px;
        padding-bottom:15px;
    
        h1{
          font-family: "pt_serifbold";
           font-size:24px;
           color:grey;
          }
      }
      ::-webkit-scrollbar {
      display: none;
      }
      @media(max-width:1500px){
      height:630px;
      margin-top:20px;
      }
      .border{
      

          height:10px;
      
     
      }
      .sidebar{
        overflow-y:scroll;
      
   
    .separation{
        width:14vw;
        background:#f5f5f5;
        cursor:pointer;
        display: flex;
        justify-content: center;
        height: 60px;
    
        box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
        display:flex;
        align-items:center;
        padding-right: 5px;
        box-sizing: border-box;
        h1{
          font-family: "montserratregular";
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          color:red;
          font-weight: bold;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: black;
          text-decoration:none;
          margin-left:24px;
          margin:0px;
          padding:0px;
          img{
  
            margin-right:24px;
          }
        }
        :hover{
          background: linear-gradient(270deg, rgba(201, 209, 255, 0.44) 0%, #FFFFFF 101.77%);
          border-right: solid 4px black;
  
        }
      }
    }

    }
    
`;

const Linkk = Styled.div` 
h1{ font-family: 'dm_sansregular';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        color:red;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #304FFE;
        text-decoration:none;
        margin-left:24px;}`;
const StyledLink = Styled(Link)`
        //some CSS styles here
   `;
