import React, { useEffect,useState } from "react";
import BackArrow from "../Widget/common/BackArrow";
import { Registration } from "./style";
import { LeftOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { useHistory } from "react-router";
import { Adminlist } from "./store";
import { firebase } from "../../../Common/firebase";
import Loader from "../Widget/common/Loader";
function Adminregistration() {
  const [{ isLoading, Admin }, { GetAdmins }] = Adminlist();

  let history = useHistory();
  const createadmin = () => {
    history.push(`/admin/createadmin/`);
  };
  const [token, setToken] = useState("");
 
  

  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  localStorage.setItem("token", token);
 
  useEffect(() => {
    GetAdmins();
  }, [token]);
  
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Registration>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Registration</h2>
      </div>
      <div className="franchises">
        <div className="heading">
          <h1>AdminRegistration</h1>
          <Divider orientation="left" />
        </div>
      </div>
      <div className="button">
        <button type="button" onClick={() => createadmin()}>
          Create
        </button>
      </div>
      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No</h1>
                </th>
                <th className="head-off">
                  <h1>Id</h1>
                </th>
                <th className="head-off">
                  <h1>Name</h1>
                </th>
                <th className="head-off">
                  <h1>Designation</h1>
                </th>

                <th className="head-off">
                  <h1>Phone</h1>
                </th>
                <th className="head-off">
                  <h1>Email</h1>
                </th>
                <th className="head-off">
                  <h1>Image</h1>
                </th>
              </tr>
            </thead>

            <tbody>
              {Admin?.map((res, i) => (
                <tr className="body-row-off">
                  <td>{i + 1}</td>
                  <td>{res.id}</td>
                  <td>{res.name}</td>
                  <td>{res.designation}</td>
                  <td>{res.phone}</td>
                  <td>{res.email}</td>
                  <td>
                    <img
                      src={res.image}
                      style={{ width: "50px", borderRadius: "60%" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Registration>
  );
}

export default Adminregistration;
