import { get, put } from "../common/remote/base_api";

export const FranchiseDetailbyid = (id) => {
  return get(`admin/get-franchise-details/${id}/`);
};
export const FranchiseUpdatebyid = (id, data) => {
  return put(`admin/update-franchise-basic-info/${id}/`, data);
};
export const FranchiseUpdateaccountid = (id, data) => {
  return put(`admin/update-franchise-account-info/${id}/`, data);
};
export const FranchiseUpdateregisterid = (id, data) => {
  return put(`/admin/update-franchise-owner-info/${id}/`, data);
};
export const FranchiseUpdateRazerpay = (id, data) => {
  return put(`admin/update-franchise-razorpay-id/?franchise_id=${id}`, data);
};
