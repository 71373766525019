import React from "react";
import { Arrow } from "./style";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
function BackArrow() {
  let History = useHistory();
  const goBack = () => {
    History.goBack();
  };
  return (
    <Arrow>
      <button type="submit" onClick={()=>goBack()}>
        <ArrowLeftOutlined />
      </button>
    </Arrow>
  );
}

export default BackArrow;
