import styled from "styled-components";
export const Dashboardfranchise = styled.div`
  width: 100%;
  margin-top: 80px;
  margin-left: 15vw;
  // background-color: #d3e3fc;
  .section {
    padding-top: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    .item1 {
      border-radius: 18px;
      height: 200px;
      width: 350px;
      background-color: yellow;
      @media (max-width: 1500px) {
        width: 280px;
        height: 170px;
      }

      h1 {
        padding-top: 20px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "dm_sansregular";
        @media (max-width: 1500px) {
          font-size: 24px;
        }
        @media (max-width: 1400px) {
          font-size: 22px;
        }
      }
      h2 {
        padding-top: 40px;
        color: black;
        display: flex;
        letter-spacing: 1px;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 26px;
        font-family: "dm_sansregular";
        font-weight: bold;
        opacity: 0.7;
        @media (max-width: 1500px) {
          font-size: 24px;
        }
      }
    }
    .item2 {
      border-radius: 18px;
      height: 200px;
      width: 350px;
      background-color: #e60073;

      @media (max-width: 1500px) {
        width: 280px;
        height: 170px;
      }

      h1 {
        padding-top: 20px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "dm_sansregular";
        @media (max-width: 1500px) {
          font-size: 24px;
        }
        @media (max-width: 1400px) {
          font-size: 22px;
        }
      }
      h2 {
        padding-top: 40px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: "dm_sansregular";
        font-weight: bold;
        opacity: 0.7;
        font-size: 24px;
        @media (max-width: 1500px) {
          font-size: 24px;
        }
      }
    }
    .item3 {
      border-radius: 18px;
      height: 200px;
      width: 350px;

      background-color: skyblue;
      @media (max-width: 1500px) {
        width: 280px;
        height: 170px;
      }

      h1 {
        padding-top: 20px;

        text-align: center;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "dm_sansregular";
        @media (max-width: 1500px) {
          font-size: 24px;
        }
        @media (max-width: 1400px) {
          font-size: 22px;
        }
      }
      h2 {
        padding-top: 40px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 26px;
        font-family: "dm_sansregular";
        font-weight: bold;
        opacity: 0.7;
        @media (max-width: 1500px) {
          font-size: 24px;
        }
      }
    }
    .item4 {
      border-radius: 18px;
      height: 200px;
      width: 350px;
      background-color: #50d050;
      @media (max-width: 1500px) {
        width: 280px;
        height: 170px;
      }

      h1 {
        padding-top: 20px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "dm_sansregular";
        @media (max-width: 1500px) {
          font-size: 25px;
        }
        @media (max-width: 1400px) {
          font-size: 22px;
        }
      }
      h2 {
        padding-top: 40px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 26px;
        font-family: "dm_sansregular";
        font-weight: bold;
        opacity: 0.7;
        @media (max-width: 1500px) {
          font-size: 24px;
        }
      }
    }
    .item5 {
      border-radius: 18px;
      height: 200px;
      width: 350px;
      padding: 10px;
      background-color: rgba(245, 88, 123, 1);
      @media (max-width: 1500px) {
        width: 280px;
        height: 170px;
      }

      h1 {
        padding-top: 20px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "dm_sansregular";
        @media (max-width: 1500px) {
          font-size: 25px;
        }
        @media (max-width: 1400px) {
          font-size: 22px;
        }
      }
      h2 {
        padding-top: 40px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 26px;
        font-family: "dm_sansregular";
        font-weight: bold;
        opacity: 0.7;
        @media (max-width: 1500px) {
          font-size: 24px;
        }
      }
    }
    .item6 {
      border-radius: 18px;
      height: 200px;
      padding:10px;
      width: 350px;
      background-color: #ccff66;
      @media (max-width: 1500px) {
        width: 280px;
        height: 170px;
      }

      h1 {
        padding-top: 20px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "dm_sansregular";
        @media (max-width: 1500px) {
          font-size: 25px;
        }
        @media (max-width: 1400px) {
          font-size: 22px;
        }
      }
      h2 {
        padding-top: 40px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 26px;
        font-family: "dm_sansregular";
        font-weight: bold;
        opacity: 0.7;
        @media (max-width: 1500px) {
          font-size: 24px;
        }
      }
    }
    .item7 {
      border-radius: 18px;
      height: 200px;
      width: 350px;
      background-color: rgba(207, 240, 158, 1);
      @media (max-width: 1500px) {
        width: 280px;
        height: 170px;
      }

      h1 {
        padding-top: 20px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "dm_sansregular";
        @media (max-width: 1500px) {
          font-size: 25px;
        }
        @media (max-width: 1400px) {
          font-size: 22px;
        }
      }
      h2 {
        padding-top: 40px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 26px;
        font-family: "dm_sansregular";
        font-weight: bold;
        opacity: 0.7;
        @media (max-width: 1500px) {
          font-size: 24px;
        }
      }
    }
  }
`;
