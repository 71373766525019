import styled from "styled-components";
export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color:#00a8cb;
  .box {
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 450px;
    justify-content: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    align-items: center;
    border-radius: 12px;
    background: #f5f5f5;

    .heading {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      h1 {
        font-size: 25px;
        font-weight: bold;
        color: #064bbf;
      }
    }
    .inpt {
      width: 60%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      p {
        font-size: 17px;
        margin-top: 20px;
      }

      input {
        font-family: "dm_sansregular";
        background-color: white;
        height: 60px;
        border: solid;
        border-radius: 15px;
        width: 90%;
        padding-left: 20px;
        font-size: 18px;
        outline: none;
        border-color: white;
      }
    }
    .items {
      display: flex;
      width: 290px;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      margin-top: 10px;
      .check {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .forgotten {
        margin-top: 10px;
        cursor: pointer;
      }
    }
    .button {
      margin-top: 10px;
      button {
        width: 150px;
        background: #00a8cb;
        border-radius: 6px;
        font-size: 17px;
        font-weight: bold;
        height: 50px;
        margin-top: 20px;
        cursor: pointer;
        color: white;
        border: none;
        &:active {
          background-color: black;
          color: white;
        }
      }
    }
  }
`;