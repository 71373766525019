import React from 'react'

function Errorwarning() {
    return (
        <div>
            <h1>Your Account is Not Active or Blocked</h1>
        </div>
    )
}

export default Errorwarning
