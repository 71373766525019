import registerWithEmailAndPasswords, {
  GetVechiclemodel,
  GetVechicletype,
  Postinfo1,
  Postinfo2,
  Postinfo3,
} from "../../../../infrastructure/Admin-franchise/Drivercreate";
import initialState from "./initialState";
import { notification } from "antd";
import signInWithEmailAndPasswordCommonf from "../../../../infrastructure/Admin-franchise/Commonloginf";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setUsername:
    (firebase_email) =>
    ({ setState }) => {
      setState({ firebase_email });
    },
  setPassword:
    (firebase_password) =>
    ({ setState }) => {
      setState({ firebase_password });
    },
  setConfirmpass:
    (confirmpass) =>
    ({ setState }) => {
      setState({ confirmpass });
    },
  setName:
    (name) =>
    ({ setState }) => {
      setState({ name });
    },
  setPhone:
    (phone) =>
    ({ setState }) => {
      setState({ phone });
    },

  setBillingadrs:
    (billing_address) =>
    ({ setState }) => {
      setState({ billing_address });
    },
  setPincode:
    (pincode) =>
    ({ setState }) => {
      setState({ pincode });
    },

  setAccountnumbr:
    (account_number) =>
    ({ setState }) => {
      setState({ account_number });
    },
  setIfsccode:
    (ifsc_code) =>
    ({ setState }) => {
      setState({ ifsc_code });
    },
  setBankname:
    (bank_name) =>
    ({ setState }) => {
      setState({ bank_name });
    },
  setUpi:
    (upi) =>
    ({ setState }) => {
      setState({ upi });
    },
  setRegisternmbr:
    (register_number) =>
    ({ setState }) => {
      setState({ register_number });
    },
  setRegistartion:
    (year_of_registration) =>
    ({ setState }) => {
      setState({ year_of_registration });
    },
  setBodycolor:
    (body_color) =>
    ({ setState }) => {
      setState({ body_color });
    },
  setMaxload:
    (max_load) =>
    ({ setState }) => {
      setState({ max_load });
    },
  setImage:
    (image) =>
    ({ setState }) => {
      setState({ image });
    },
  setCheckbox:
    (checkbox) =>
    ({ setState }) => {
      setState({ checkbox });
    },
  setVechicletype:
    (vtype) =>
    ({ setState }) => {
      setState({ vtype });
    },
  setVechiclemodel:
    (model) =>
    ({ setState }) => {
      setState({ model });
    },
  setmodelid:
    (vehicle_model) =>
    ({ setState }) => {
      setState({ vehicle_model });
    },
  setIsModalVisibleD:
    (isModalVisibleD) =>
    ({ setState }) => {
      setState({ isModalVisibleD });
    },
  setusernameD:
    (usernameD) =>
    ({ setState }) => {
      setState({ usernameD });
    },
  setlicence_number:
    (licence_number) =>
    ({ setState }) => {
      setState({ licence_number });
    },
  setlicence_file:
    (licence_file) =>
    ({ setState }) => {
      setState({ licence_file });
    },
  setage:
    (age) =>
    ({ setState }) => {
      setState({ age });
    },
  setdate_of_birth:
    (date_of_birth) =>
    ({ setState }) => {
      setState({ date_of_birth });
    },
  setreferece_number:
    (referece_number) =>
    ({ setState }) => {
      setState({ referece_number });
    },
  setreference_relation:
    (reference_relation) =>
    ({ setState }) => {
      setState({ reference_relation });
    },
  setreference_name:
    (referece_name) =>
    ({ setState }) => {
      setState({ referece_name });
    },
  setpasswordD:
    (passwordD) =>
    ({ setState }) => {
      setState({ passwordD });
    },
  setsex:
    (sex) =>
    ({ setState }) => {
      setState({ sex });
    },
  setimage_left:
    (image_left) =>
    ({ setState }) => {
      setState({ image_left });
    },
  setimage_right:
    (image_right) =>
    ({ setState }) => {
      setState({ image_right });
    },
  setimage_back:
    (image_back) =>
    ({ setState }) => {
      setState({ image_back });
    },
  setnoc_file:
    (noc_file) =>
    ({ setState }) => {
      setState({ noc_file });
    },
  setpcc_file:
    (pcc_file) =>
    ({ setState }) => {
      setState({ pcc_file });
    },
  Submitlogin:
    (showModalD) =>
    async ({ dispatch, getState }) => {
      const { firebase_email, firebase_password } = getState();
      if (firebase_email === "") {
        notification["warning"]({
          message: "warning",
          description: "Email feild is empty",
        });
        return;
      } else if (firebase_password === "") {
        notification["warning"]({
          message: "warning",
          description: "password feild is empty",
        });
        return;
      }
      try {
        dispatch(actions.setisLoading(true));
        await registerWithEmailAndPasswords(firebase_email, firebase_password);
        showModalD();
        // notification["success"]({
        //   message: "success",
        //   description: "User Created  successfully",
        // });
      } catch (err) {
        let msg = err.message;

        if (err.message === msg) {
          notification["warning"]({
            message: "warning",
            description: "Email address already exists",
          });
        } else {
          notification["warning"]({
            message: "warning",
            description: "Something went Wrong!!!",
          });
        }
      } finally {
        dispatch(actions.setisLoading(true));
      }
    },
  Postformlogind:
    (goNextpage) =>
    async ({ dispatch, getState }) => {
      const {
        firebase_email,
        firebase_password,
        name,
        phone,
        billing_address,
        pincode,
        account_number,
        ifsc_code,
        bank_name,
        upi,
        image,
        vehicle_model,
        vehicle_type,
        licence_number,
        licence_file,
        age,
        date_of_birth,
        referece_name,
        reference_relation,
        referece_number,
        sex,
      } = getState();
      if (name === "") {
        notification["warning"]({
          message: "warning",
          description: "name field is empty",
        });
        return;
      } else if (phone === "") {
        notification["warning"]({
          message: "warning",
          description: "phone feild is empty",
        });
        return;
      } else if (pincode === "") {
        notification["warning"]({
          message: "warning",
          description: "pincode feild is empty",
        });
        return;
      } else if (billing_address === "") {
        notification["warning"]({
          message: "warning",
          description: "billing address field is empty",
        });
        return;
      } else if (account_number === "") {
        notification["warning"]({
          message: "warning",
          description: "account number field is empty",
        });
        return;
      } else if (ifsc_code === "") {
        notification["warning"]({
          message: "warning",
          description: "ifsc code field is empty",
        });
        return;
      } else if (bank_name === "") {
        notification["warning"]({
          message: "warning",
          description: "bankname field is empty",
        });
        return;
      } else if (upi === "") {
        notification["warning"]({
          message: "warning",
          description: "upi field is empty",
        });
        return;
      } else if (vehicle_model === "") {
        notification["warning"]({
          message: "warning",
          description: "vehicle model field is empty",
        });
        return;
      } else if (vehicle_type === "") {
        notification["warning"]({
          message: "warning",
          description: "vehicle type field is empty",
        });
        return;
      } else if (image === null) {
        notification["warning"]({
          message: "warning",
          description: "image file is missing",
        });
        return;
      } else if (licence_number === "") {
        notification["warning"]({
          message: "warning",
          description: "Licence  field is empty",
        });
        return;
      } else if (licence_file === "") {
        notification["warning"]({
          message: "warning",
          description: "Licence file is missing",
        });
        return;
      } else if (age === "") {
        notification["warning"]({
          message: "warning",
          description: "age field is empty",
        });
        return;
      } else if (date_of_birth === "") {
        notification["warning"]({
          message: "warning",
          description: "Date of birth field is empty",
        });
        return;
      } else if (referece_number === "") {
        notification["warning"]({
          message: "warning",
          description: "Reference Number field is empty",
        });
        return;
      } else if (reference_relation === "") {
        notification["warning"]({
          message: "warning",
          description: "Reference relation  field is empty",
        });
        return;
      } else if (referece_name === "") {
        notification["warning"]({
          message: "warning",
          description: "Reference Name  field is empty",
        });
        return;
      } else if (sex === "") {
        notification["warning"]({
          message: "warning",
          description: "Gender field is empty",
        });
        return;
      }

      dispatch(actions.setisLoading(true));
      var bodyFormdata = new FormData();
      bodyFormdata.append("name", name);
      bodyFormdata.append("firebase_email", firebase_email);
      bodyFormdata.append("phone", phone);
      bodyFormdata.append("firebase_password", firebase_password);
      bodyFormdata.append("billing_address", billing_address);
      bodyFormdata.append("pincode", pincode);
      bodyFormdata.append("account_number", account_number);
      bodyFormdata.append("ifsc_code", ifsc_code);
      bodyFormdata.append("bank_name", bank_name);
      bodyFormdata.append("upi", upi);
      bodyFormdata.append("vehicle_model", vehicle_model);
      bodyFormdata.append("licence_number", licence_number);
      bodyFormdata.append("licence_file", licence_file);
      bodyFormdata.append("age", age);
      bodyFormdata.append("image", image);
      bodyFormdata.append("date_of_birth", date_of_birth);
      bodyFormdata.append("reference_name", referece_name);
      bodyFormdata.append("reference_relation", reference_relation);
      bodyFormdata.append("reference_number", referece_number);
      bodyFormdata.append("sex", sex);

      try {
        await Postinfo1(bodyFormdata);
        goNextpage();
        notification["success"]({
          message: "success",
          description: "Basic information Created  successfully",
        });
        dispatch(actions.setImage(initialState.image));
      } catch (err) {
        // notification["warning"]({
        //   message: "warning",
        //   description: "Basic information Created unSuccessfully",
        // });
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Postform3data:
    () =>
    async ({ dispatch, getState }) => {
      const {
        firebase_email,
        firebase_password,
        register_number,
        year_of_registration,
        body_color,
        max_load,
        image,
        image_left,
        image_right,
        image_back,
        noc_file,
        pcc_file,
      } = getState();
      if (register_number === "") {
        notification["warning"]({
          message: "warning",
          description: "Register number field is empty",
        });
        return;
      } else if (year_of_registration === "") {
        notification["warning"]({
          message: "warning",
          description: "year of registration  field is empty",
        });
        return;
      } else if (body_color === "") {
        notification["warning"]({
          message: "warning",
          description: "body color field is empty",
        });
        return;
      } else if (max_load === "") {
        notification["warning"]({
          message: "warning",
          description: "max load field is empty",
        });
        return;
      } else if (image === null) {
        notification["warning"]({
          message: "warning",
          description: "Vechicle Front image file is missing",
        });
        return;
      } else if (image_back === null) {
        notification["warning"]({
          message: "warning",
          description: "Vechicle Back image file is missing",
        });
        return;
      } else if (image_left === null) {
        notification["warning"]({
          message: "warning",
          description: "Vechicle Left image file is missing",
        });
        return;
      } else if (image_right === null) {
        notification["warning"]({
          message: "warning",
          description: "Vechicle Right image file is missing",
        });
        return;
      } else if (pcc_file === null) {
        notification["warning"]({
          message: "warning",
          description: "PCC file is missing",
        });
        return;
      }

      dispatch(actions.setisLoading(true));
      try {
        var bodyFormdata = new FormData();
        bodyFormdata.append("firebase_email", firebase_email);
        bodyFormdata.append("firebase_password", firebase_password);
        bodyFormdata.append("register_number", register_number);
        bodyFormdata.append("year_of_registration", year_of_registration);
        bodyFormdata.append("body_color", body_color);
        bodyFormdata.append("max_load", max_load);
        bodyFormdata.append("image", image);
        bodyFormdata.append("image_back", image_back);
        bodyFormdata.append("image_left", image_left);
        bodyFormdata.append("image_right", image_right);
        bodyFormdata.append("noc_file", noc_file);
        bodyFormdata.append("pcc_file", pcc_file);
        await Postinfo3(bodyFormdata);
        window.location.replace(`/franchise/driver`);
      } catch (err) {
        // notification["warning"]({
        //   message: "warning",
        //   description: "Driver Vehicle information Created unSuccessfully",
        // });
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Getvechiclemodeltype:
    () =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      try {
        let res = await GetVechicletype();
        dispatch(actions.setVechicletype(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Getvechiclemodel:
    (id) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      try {
        let res = await GetVechiclemodel(id);
        dispatch(actions.setVechiclemodel(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Relogin:
    (goNextpage) =>
    async ({ dispatch, getState }) => {
      const { usernameD, passwordD } = getState();
      try {
        dispatch(actions.setisLoading(true));
        await signInWithEmailAndPasswordCommonf(usernameD, passwordD);
        goNextpage();
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
