import registerWithEmailAndPasswords, {
  Postadmininfo,
} from "../../../../infrastructure/Admin/Admincreate";
import initialState from './initialState'
import { notification } from "antd";
import signInWithEmailAndPasswordCommon from "../../../../infrastructure/Admin/commonlogin";
const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setUsername:
    (firebase_email) =>
    ({ setState }) => {
      setState({ firebase_email });
    },
  setPassword:
    (firebase_password) =>
    ({ setState }) => {
      setState({ firebase_password });
    },
  setConfirmpass:
    (confirmpass) =>
    ({ setState }) => {
      setState({ confirmpass });
    },
  setname:
    (name) =>
    ({ setState }) => {
      setState({ name });
    },
  setdesignation:
    (designation) =>
    ({ setState }) => {
      setState({ designation });
    },
  setphone:
    (phone) =>
    ({ setState }) => {
      setState({ phone });
    },
  setemail:
    (email) =>
    ({ setState }) => {
      setState({ email });
    },
  setimage:
    (image) =>
    ({ setState }) => {
      setState({ image });
    },
    setIsModalVisibleAR:
    (isModalVisibleAR) =>
    ({ setState }) => {
      setState({ isModalVisibleAR });
    },
    setusernameA:
    (usernameA) =>
    ({ setState }) => {
      setState({ usernameA });
    },
  setpasswordA:
    (passwordA) =>
    ({ setState }) => {
      setState({ passwordA });
    },

  Submitlogin:
    (goNextpage) =>
    async ({ dispatch, getState }) => {
      const { firebase_email, firebase_password } = getState();
      if (firebase_email === "") {
        notification["warning"]({
          message: "warning",
          description: "Email feild is empty",
        });
        return;
      } else if (firebase_password === "") {
        notification["warning"]({
          message: "warning",
          description: "password feild is empty",
        });
        return;
      }
      try {
        dispatch(actions.setisLoading(true));
        await registerWithEmailAndPasswords(firebase_email, firebase_password);
        notification["success"]({
          message: "success",
          description: "User Created  successfully",
        });
        goNextpage();
      } catch (err) {
        let msg = err.message;

        if (err.message === msg) {
          notification["warning"]({
            message: "warning",
            description: "Email address already exists",
          });
        } else {
          notification["warning"]({
            message: "warning",
            description: "Something went Wrong!!!",
          });
        }
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  PostAdminlogin:
    (showModalAR) =>
    async ({ dispatch, getState }) => {
      const {
        firebase_email,
        firebase_password,
        name,
        designation,
        phone,
        email,
        image,
      } = getState();
      if (name === "") {
        notification["warning"]({
          message: "warning",
          description: "name feild is empty",
        });
        return;
      } else if (designation === "") {
        notification["warning"]({
          message: "warning",
          description: "designation feild is empty",
        });
        return;
      } else if (email === "") {
        notification["warning"]({
          message: "warning",
          description: "email feild is empty",
        });
        return;
      } else if (phone === "") {
        notification["warning"]({
          message: "warning",
          description: "phone feild is empty",
        });
        return;
      } else if (image === "") {
        notification["warning"]({
          message: "warning",
          description: "image file is empty",
        });
        return;
      }
      try {
        dispatch(actions.setisLoading(true));
        var bodyformdat = new FormData();
        bodyformdat.append("name", name);
        bodyformdat.append("designation", designation);
        bodyformdat.append("email", email);
        bodyformdat.append("firebase_email", firebase_email);
        bodyformdat.append("firebase_password", firebase_password);
        bodyformdat.append("image", image);
        bodyformdat.append("phone", phone);
        await Postadmininfo(bodyformdat);
        showModalAR();
        
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: " information Created unSuccessfully",
        });
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
    Relogin:
    (history) =>
    async ({ dispatch, getState }) => {
      const { usernameA, passwordA } = getState();
      try {
        dispatch(actions.setisLoading(true));
        await signInWithEmailAndPasswordCommon(usernameA, passwordA);

        history.push(`/admin/registration`);
        notification["success"]({
          message: "success",
          description: " information Created  successfully",
        });
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
