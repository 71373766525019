const initialState = {
  isLoading: false,
  firebase_email: "",
  firebase_password: "",
  billing_address: "",
  coordinates: { lat: null, lng: null },
  name: "",
  phone: "",
  latdrs: "",
  address: "",
  pincode: "",
  latitude: "",
  longitude: "",
  billing_address: "",
  account_number: "",
  ifsc_code: "",
  bank_name: "",
  bank_branch: "",
  owner_name: "",
  owner_id_proof: "",
  id_proof_number: "",
  pan_number: "",
  id_proof_file: "",
  pan_file: "",
  confirmpass:"",
  usernameF:"",
  passwordF:"",
  isModalVisibleF:false,
};
export default initialState;
