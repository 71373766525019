import registerWithEmailAndPassword, {
  Postemail,
  Postinformation2,
  Postinformation3,
} from "../../../../infrastructure/Admin/FranchiseExplogin";
import initialState from "./initialState";
import { notification } from "antd";
import signInWithEmailAndPasswordCommon from "../../../../infrastructure/Admin/commonlogin";
const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setUsername:
    (firebase_email) =>
    ({ setState }) => {
      setState({ firebase_email });
    },
  setPassword:
    (firebase_password) =>
    ({ setState }) => {
      setState({ firebase_password });
    },
  setAddress:
    (address) =>
    ({ setState }) => {
      setState({ address });
    },
  setCordinates:
    (coordinates) =>
    ({ setState }) => {
      setState({ coordinates });
    },
  setlatdrs:
    (latitude) =>
    ({ setState }) => {
      setState({ latitude });
    },
  setlngadrs:
    (longitude) =>
    ({ setState }) => {
      setState({ longitude });
    },
  setname:
    (name) =>
    ({ setState }) => {
      setState({ name });
    },
  setphone:
    (phone) =>
    ({ setState }) => {
      setState({ phone });
    },
  setbillingAdrs:
    (billing_address) =>
    ({ setState }) => {
      setState({ billing_address });
    },
  setAccountnum:
    (account_number) =>
    ({ setState }) => {
      setState({ account_number });
    },
  setifsccode:
    (ifsc_code) =>
    ({ setState }) => {
      setState({ ifsc_code });
    },
  setbankname:
    (bank_name) =>
    ({ setState }) => {
      setState({ bank_name });
    },
  setbankbranch:
    (bank_branch) =>
    ({ setState }) => {
      setState({ bank_branch });
    },
  setpincode:
    (pincode) =>
    ({ setState }) => {
      setState({ pincode });
    },
  setownername:
    (owner_name) =>
    ({ setState }) => {
      setState({ owner_name });
    },
  setowneridproof:
    (owner_id_proof) =>
    ({ setState }) => {
      setState({ owner_id_proof });
    },
  setidproofnum:
    (id_proof_number) =>
    ({ setState }) => {
      setState({ id_proof_number });
    },
  setpannumber:
    (pan_number) =>
    ({ setState }) => {
      setState({ pan_number });
    },
  setidprooffile:
    (id_proof_file) =>
    ({ setState }) => {
      setState({ id_proof_file });
    },
  setpanfile:
    (pan_file) =>
    ({ setState }) => {
      setState({ pan_file });
    },
  setConfirmpass:
    (confirmpass) =>
    ({ setState }) => {
      setState({ confirmpass });
    },
  setimage:
    (image) =>
    ({ setState }) => {
      setState({ image });
    },
  setIsModalVisibleF:
    (isModalVisibleF) =>
    ({ setState }) => {
      setState({ isModalVisibleF });
    },
  setusernameF:
    (usernameF) =>
    ({ setState }) => {
      setState({ usernameF });
    },
  setpasswordF:
    (passwordF) =>
    ({ setState }) => {
      setState({ passwordF });
    },

  Submitlogin:
    (goNextpage) =>
    async ({ dispatch, getState }) => {
      const { firebase_email, firebase_password } = getState();
      if (firebase_email === "") {
        notification["warning"]({
          message: "warning",
          description: "Email feild is empty",
        });
        return;
      } else if (firebase_password === "") {
        notification["warning"]({
          message: "warning",
          description: "password feild is empty",
        });
        return;
      }
      try {
        dispatch(actions.setisLoading(true));
        await registerWithEmailAndPassword(firebase_email, firebase_password);
        notification["success"]({
          message: "success",
          description: "User Created  successfully",
        });
        goNextpage();
        dispatch(actions.setisLoading(false));
      } catch (err) {
        let msg = err.message;

        if (err.message === msg) {
          notification["warning"]({
            message: "warning",
            description: "Email address already exists",
          });
        } else {
          notification["warning"]({
            message: "warning",
            description: "Something went Wrong!!!",
          });
        }
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Postformlogin:
    (goNextpage) =>
    async ({ dispatch, getState }) => {
      const {
        firebase_email,
        firebase_password,
        latitude,
        longitude,
        phone,
        pincode,
        name,
        billing_address,
        image,
      } = getState();

      if (name === "") {
        notification["warning"]({
          message: "warning",
          description: "name feild is empty",
        });
        return;
      } else if (phone === "") {
        notification["warning"]({
          message: "warning",
          description: "phone feild is empty",
        });
        return;
      } else if (pincode === "") {
        notification["warning"]({
          message: "warning",
          description: "pincode feild is empty",
        });
        return;
      } else if (billing_address === "") {
        notification["warning"]({
          message: "warning",
          description: "billing address feild is empty",
        });
        return;
      } else if (image === "") {
        notification["warning"]({
          message: "warning",
          description: "image file is empty",
        });
        return;
      }
      try {
        dispatch(actions.setisLoading(true));
        var bodyformdat = new FormData();
        bodyformdat.append("name", name);
        bodyformdat.append("firebase_email", firebase_email);
        bodyformdat.append("firebase_password", firebase_password);
        bodyformdat.append("latitude", latitude);
        bodyformdat.append("billing_address", billing_address);
        bodyformdat.append("image", image);
        bodyformdat.append("longitude", longitude);
        bodyformdat.append("pincode", pincode);
        bodyformdat.append("phone", phone);

        const res = await Postemail(bodyformdat);
        notification["success"]({
          message: "success",
          description: "Basic information Created  successfully",
        });
        goNextpage();
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: "Basic information Created unSuccessfully",
        });
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Postformlogin2:
    (goNextpage) =>
    async ({ dispatch, getState }) => {
      const {
        firebase_email,
        firebase_password,
        account_number,
        ifsc_code,
        bank_name,
        bank_branch,
      } = getState();
      if (account_number === "") {
        notification["warning"]({
          message: "warning",
          description: "account number feild is empty",
        });
        return;
      } else if (ifsc_code === "") {
        notification["warning"]({
          message: "warning",
          description: "ifsc code feild is empty",
        });
        return;
      } else if (bank_name === "") {
        notification["warning"]({
          message: "warning",
          description: "bank name feild is empty",
        });
        return;
      } else if (bank_branch === "") {
        notification["warning"]({
          message: "warning",
          description: "bank branch feild is empty",
        });
        return;
      }
      const data = {
        firebase_email,
        firebase_password,
        account_number,
        ifsc_code,
        bank_name,
        bank_branch,
      };

      try {
        dispatch(actions.setisLoading(true));
        const res = await Postinformation2(data);
        notification["success"]({
          message: "success",
          description: " Bank Information Created  successfully",
        });
        goNextpage();
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: "Bank Information Created unSuccessfully",
        });
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Postformlogin3:
    (showModalF) =>
    async ({ dispatch, getState }) => {
      const {
        firebase_email,
        firebase_password,
        owner_name,
        owner_id_proof,
        id_proof_number,
        pan_number,
        id_proof_file,
        pan_file,
      } = getState();

      var bodyFormdata = new FormData();
      bodyFormdata.append("firebase_email", firebase_email);
      bodyFormdata.append("firebase_password", firebase_password);
      bodyFormdata.append("owner_name", owner_name);
      bodyFormdata.append(
        "owner_id_proof",
        owner_id_proof === "" ? "adhaar" : owner_id_proof
      );
      bodyFormdata.append("id_proof_number", id_proof_number);
      bodyFormdata.append("pan_number", pan_number);
      bodyFormdata.append("id_proof_file", id_proof_file);
      bodyFormdata.append("pan_file", pan_file);
      if (owner_name === "") {
        notification["warning"]({
          message: "warning",
          description: "Owner name feild is empty",
        });
        return;
      } else if (id_proof_number === "") {
        notification["warning"]({
          message: "warning",
          description: "Id proof number feild is empty",
        });
        return;
      } else if (pan_number === "") {
        notification["warning"]({
          message: "warning",
          description: "pan number feild is empty",
        });
        return;
      } else if (id_proof_file === "") {
        notification["warning"]({
          message: "warning",
          description: "id proof file is missing",
        });
        return;
      } else if (pan_file === "") {
        notification["warning"]({
          message: "warning",
          description: "Pan  file is missing",
        });
        return;
      }
      try {
        dispatch(actions.setisLoading(true));
        await Postinformation3(bodyFormdata);
        showModalF();
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Relogin:
    (history) =>
    async ({ dispatch, getState }) => {
      const { usernameF, passwordF } = getState();
      try {
        dispatch(actions.setisLoading(true));
        await signInWithEmailAndPasswordCommon(usernameF, passwordF);

        history.push(`/admin/franchisereqlist`);
        notification["success"]({
          message: "success",
          description: " Owner Details Created  successfully",
        });
        dispatch(actions.setUsername(initialState.firebase_email));
        dispatch(actions.setPassword(initialState.firebase_password));
        dispatch(actions.setname(initialState.name));
        dispatch(actions.setphone(initialState.phone));
        dispatch(actions.setbillingAdrs(initialState.billing_address));
        dispatch(actions.setlatdrs(initialState.latitude));
        dispatch(actions.setlngadrs(initialState.longitude));
        dispatch(actions.setAccountnum(initialState.account_number));
        dispatch(actions.setifsccode(initialState.ifsc_code));
        dispatch(actions.setbankname(initialState.bank_name));
        dispatch(actions.setbankbranch(initialState.bank_branch));
        dispatch(actions.setownername(initialState.owner_name));
        dispatch(actions.setowneridproof(initialState.owner_id_proof));
        dispatch(actions.setidproofnum(initialState.id_proof_number));
        dispatch(actions.setpannumber(initialState.pan_number));
        dispatch(actions.setidprooffile(initialState.id_proof_file));
        dispatch(actions.setpanfile(initialState.pan_file));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};

export default actions;
