import React from "react";
import { useHistory } from "react-router";
import { HomeBackground } from "./style";

import Carousel from "react-bootstrap/Carousel";
import courier from "../../../Images/courirer.jpg";
import backgrond from "../../../Images/truckbanner.jpg";
import AppStore from "./Overview/App";
import Slider from "react-slick";
import boxpic1 from "../../../Images/box1.jpg";
import boxpic2 from "../../../Images/box2.png";
import boxpic3 from "../../../Images/box3.jpg";
import bike from "../../../Images/bikedelivery.png";
import truck from "../../../Images/card.png";
import ambulance from "../../../Images/delibuoyvan.jpg";
import movegoods from "../../../Images/movegoods.png"
function Home() {
  // let history = useHistory();
  // const franchiseclick=()=>{
  //   history.push('/details')
  // }
  return (
    <HomeBackground>
      {/* <div className="main">
        <div className="textimonal"></div>
      </div> */}

      <div style={{ paddingTop: 80, marginBottom: 100 }}>
        <Carousel data-interval="false" data-ride="carousel" data-pause="hover">
        <Carousel.Item>
            <div>
              <img
                src={courier}
                style={{ width: "100%", height: "94vh" }}
              ></img>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <img
                src={backgrond}
                style={{ width: "100%", height: "94vh" }}
              ></img>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <img
                src={ambulance}
                style={{ width: "100%", height: "94vh" }}
              ></img>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div>
              <img
                src={movegoods}
                style={{ width: "100%", height: "94vh" }}
              ></img>
            </div>
          </Carousel.Item>

  
        </Carousel>
      </div>
      <div className="animaton" id="about">
        <div className="aboutus">
          <div className="top">
            <div className="head">
              {" "}
              <h1>About Us</h1>
            </div>
            <div className="content">
              <p>
                {/* Delibuoy is a trusted point-to-point pick-up and delivery
                partner for retail stores, restaurants, small businesses and
                individuals. Brought to you by Quadauq Technosys Pvt. Ltd,
                Delibuoy opens up the possibilities to grow your small business
                as extended means to reaching out to your customers where they
                are. For individuals, we’re the most beloved fairy always at
                service. */}
                Delibuoy is a one-stop delivery solution to individuals and
                businesses that takes care of point-to-point pickup and delivery
                locally. We present a wide range of goods vehicles right from
                2-wheelers to large trucks and everything in-between to pick up
                and deliver everything from roses and cakes to inventory cartons
                and more. Brought to you by Quadauq Technosys Pvt. Ltd, Delibuoy
                is a first-of-its-kind in Kerala to provide a scalable and
                affordable solution to bring fleet owners and their customers
                closer. Delibuoy is a trusted point-to-point pick-up and
                delivery partner for retail stores, restaurants, small
                businesses and individuals. Delibuoy opens up the possibilities
                to grow your small business as extended means to reaching out to
                your customers where they are. For individuals, we’re the most
                beloved pick-up and delivery fairy always at service.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* //animation div over */}
      <div className="topics">
        <div className="wrapper">
          <div className="mainpart">
            <div className="left">
              <img src={bike}></img>
            </div>
            <div className="right">
              <h1>Book, Track, Grow </h1>
              <div className="maind">
                <p>
                  {" "}
                  Delibuoy is for everyone who is in need of pick-up and
                  delivery of products/parcels locally. We strive to serve
                  business owners and individuals, doctors and chemists,
                  home-makers and home-bakers looking for reliable and timely
                  pick-up/delivery of essentials. Our online tracking system
                  helps you stay updated. To use our service, install our app,
                  register and get going. We’ll make sure your things reach
                  their destination safe and sound, on time.
                </p>
              </div>
              <div className="learn">
                <button type="button" className="button">
                  <span>Learn more</span>
                </button>
              </div>
            </div>
          </div>
          <div className="mainpart2">
            <div className="left2">
              <h1>Franchise Opportunity </h1>
              <div className="maind">
                <p>
                  For business owners looking to grow their outreach, our
                  franchise model offers great opportunities to expand your
                  business. For details, please reach out to us on email or
                  phone number.
                </p>
              </div>
              <div className="learn">
                <button type="button" className="button" >
                  <span>Franchise</span>
                </button>
              </div>
            </div>
            <div className="right2">
              <img src={truck}></img>
            </div>
          </div>
        </div>
      </div>
      <div className="contentss">
        <div className="wraper3">
          <div className="detail3">
            <img src={boxpic1}></img>

            <div className="list">
              <h1>Connect</h1>
              <p>
                {" "}
                Connect with the most affordable, available, and well-behaved
                drivers and choose the most appropriate delivery vehicles.{" "}
              </p>
            </div>
          </div>
          <div className="detail3">
            <img src={boxpic2}></img>

            <div className="list">
              <h1>Pick Up</h1>
              <p>
                {" "}
                Once you confirm, the assigned delibuoy agent will pick up the goods,
                load them, and get started to deliver.
              </p>
            </div>
          </div>
          <div className="detail3">
            <img src={boxpic3}></img>

            <div className="list">
              <h1> Deliver </h1>
              <p>
                The driver will deliver your goods safely, quickly and
                economically so that you can focus on your priorities.
              </p>
            </div>
          </div>
        </div>
      </div>
      <AppStore />
    </HomeBackground>
  );
}

export default Home;
