import React from "react";
import { SettlementDiv } from "./style";
import BackArrow from "../Widget/common/BackArrow";
import { LeftOutlined } from "@ant-design/icons";
import { Divider } from "antd";
function SettlementExp() {
  return (
    <SettlementDiv>
      <div className="title">
        <div className="backarrow">
          <BackArrow />
        </div>
        <div className="heading">
          <h1>Settlement</h1>
          <LeftOutlined style={{ color: "grey" }} />
          <h2>Settlement dues</h2>
        </div>
      </div>
      <div className="main">
        <div className="heading">
          <h1>Settlement</h1>
          <Divider orientation="left" />
        </div>
        <div className="section">
          <div className="small">
            <div className="item">
              <div className="name">
                <h1>Name </h1>
                <span>:</span>
                <h2> hfhf </h2>
              </div>
              <div className="name">
                <h1>Phone</h1>
                <span>:</span>
                <h2>bnbn</h2>
              </div>
              <div className="name">
                <h1>Email </h1>
                <span>:</span>
                <h2>vbvbv</h2>
              </div>
              <div className="name">
                <h1>Account Number </h1>
                <span>:</span>
                <h2>fhf</h2>
              </div>
              <div className="name">
                <h1>IFSC Code </h1>
                <span>:</span>
                <h2>fbhngb</h2>
              </div>
              <div className="name">
                <h1>Bank Name </h1>
                <span>:</span>
                <h2>fhf</h2>
              </div>
              <div className="name">
                <h1>UPI </h1>
                <span>:</span>
                <h2>fbhgfbh</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettlementDiv>
  );
}

export default SettlementExp;
