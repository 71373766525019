import styled from "styled-components";

export const Top = styled.div`
position: fixed;
  background: #00a8cb;
  height: 80px;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  .header {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      gap:10px;
      img {
        width:130px;
      }
      h1 {
        font-weight:bold;
        letter-spacing: 1px;
        font-size: 20px;
        font-family: "pt_serifbold";
        margin:0px;
        color:#dadada;
        
      }
    }

    ul {
      list-style: none;
      display: flex;
      list-style: none;
      gap: 35px;
      font-size: 18px;
      align-items: center;
   
      margin: 0px;
      padding: 0px;
      
      .link {
        font-weight: 400;
        font-family: "pt_serifbold";
        font-size: 18px;
        cursor: pointer;
        color: white;
        align-items: center;
        display: flex;
        letter-spacing: 1px;
        &:hover {
          color: white;
          text-decoration: underline;
        }
      }
      .badge{
        cursor:pointer;
      }
    }
  }
`;
