import React, { useState, useEffect } from "react";
import { Driverexpfranchise, Drivermodal, Vehiclemodal } from "./style";
import { Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import { GetDriverdetailsf } from "./store";
import BackArrow from "../../Admin/Widget/common/BackArrow";
import Loader from "../../Admin/Widget/common/Loader";
import { Modal } from "antd";
import { firebase } from "../../../Common/firebase";
import { DatePicker } from "antd";
import moment from "moment";

import { Image } from "antd";
function Driverexpf({ match }) {
  const [
    {
      isLoading,
      Details,
      accountdetails,
      vehicledetails,
      name,
      phone,
      billing_address,
      account_number,
      pincode,
      age,
      sex,
      dob,
      referencename,
      referencerelation,
      referencenumber,
      Licencenumber,
      ifsc_code,
      bank_name,
      upi,
      image,
      register_number,
      year_of_registration,
      body_color,
      max_load,
      imageleft,
      imageright,
      imageback,
      vehicleimage,
      licensecovert,
      Licencefile,
      DateOfBirth,
      Profilepicupload,
      isDriver,
      isVehicle,
      rerender,
    },
    {
      GetDetailsoff,
      setUserNames,
      setPhone,
      setBillingadrs,
      setPincode,
      setAge,
      setSex,
      setDob,
      setReferencename,
      setReferencerelation,
      setReferencenumber,
      setLicencenumber,
      setAccountnum,
      setIfsccode,
      setBankname,
      setUpi,
      setImage,
      setRegisternmbr,
      setYearofregistration,
      setBodycolor,
      setMaxload,
      UpdateDriver,
      UpdateVehicle,
      setImageleft,
      setImageright,
      setImageback,
      setVehicleimage,
      setDateOfBirth,
      setLicencefile,
      setlicensecovert,
      setProfilepicupload,
      setIsDriver,
      setIsVehicle,
    },
  ] = GetDriverdetailsf();

  const [idd, setidd] = useState(match.params.id);
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  localStorage.setItem("token", token);
  //Lisence preview................................................................

  const uploadLisence = (e) => {
    setLicencefile(e.target.files[0]);
    setlicensecovert(e.target.files[0]);
  };

  //profile image.........................

  // read the file

  const [previewimage, setpreviewimage] = useState(null);
  const uploadprofile = (e) => {
    setImage(e.target.files[0]);
    setProfilepicupload(e.target.files[0]);
    setpreviewimage(URL.createObjectURL(e.target.files[0]));
  };
  let imgPreviewprofile;
  if (previewimage) {
    imgPreviewprofile = (
      <img src={previewimage} alt="" width="80px" height="80px" />
    );
  }

  //..................................*****************************
  const [preview, setpreview] = useState(null);
  const uploadSingleFile = (e) => {
    setVehicleimage(e.target.files[0]);
    setpreview(URL.createObjectURL(e.target.files[0]));
  };
  let imgPreview;
  if (preview) {
    imgPreview = <img src={preview} alt="" width="80px" height="80px" />;
  }

  const [preview1, setpreview1] = useState(null);
  const uploadSingleFile1 = (e) => {
    setImageleft(e.target.files[0]);
    setpreview1(URL.createObjectURL(e.target.files[0]));
  };
  let imgPreview1;
  if (preview1) {
    imgPreview1 = <img src={preview1} alt="" width="80px" height="80px" />;
  }

  const [preview2, setpreview2] = useState(null);
  const uploadSingleFile2 = (e) => {
    setImageright(e.target.files[0]);
    setpreview2(URL.createObjectURL(e.target.files[0]));
  };
  let imgPreview2;
  if (preview2) {
    imgPreview2 = <img src={preview2} alt="" width="80px" height="80px" />;
  }
  const [preview3, setpreview3] = useState(null);
  const uploadSingleFile3 = (e) => {
    setImageback(e.target.files[0]);
    setpreview3(URL.createObjectURL(e.target.files[0]));
  };

  let imgPreview3;
  if (preview3) {
    imgPreview3 = <img src={preview3} alt="" width="80px" height="80px" />;
  }
  //driverinfo

  const showModalDriver = () => {
    setIsDriver(true);
  };

  const handleOkDriver = () => {
    UpdateDriver(idd);
    GetDetailsoff(idd);
    // setIsDriver(false);
  };

  const handleCancelDriver = () => {
    setIsDriver(false);
  };

  // vehicle info

  const showModalVehicle = () => {
    setIsVehicle(true);
  };

  const handleOkVehicle = () => {
    UpdateVehicle(idd);
    // GetDetailsoff(idd);
  };

  const handleCancelVehicle = () => {
    setIsVehicle(false);
  };

  function onChange(date, dateString) {
    let dates = (date, dateString);
    setDateOfBirth(dates);
  }
  //date validation.........................
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  }
  const dateFormat = "YYYY-MM-DD";
  useEffect(() => {
    GetDetailsoff(idd);
  }, [token, rerender, idd]);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Driverexpfranchise>
      <div className="title">
        <h1>Driver</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>{Details.name}</h2>
      </div>
      <div className="arrow">
        <BackArrow />
      </div>
      <div className="main">
        <div className="heading">
          <h1>Driver</h1>
          <Divider orientation="left" />
        </div>
        <div
          className="button"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            onClick={() => showModalDriver()}
            style={{
              width: 80,
              height: 35,
              fontSize: 14,
              border: "none",
              backgroundColor: "#00a8cb",
              borderRadius: "6px",
            }}
          >
            Update
          </button>
        </div>
        <div className="section">
          <div className="small">
            <div className="item">
              <div
                className="image"
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "cover",
                  borderRadius: "50%",
                  marginBottom: 10,
                }}
              >
                <Image width={200} height={200} src={Details.image} />
                {/* <img
                  src={Details.image}
                  alt="datail"
                  style={{ width: "100%", height: "100%", display: "block" }}
                /> */}
              </div>
              <div className="name" style={{ marginTop: 50 }}>
                <h1>ID</h1>
                <span>:</span>
                <h2 style={{ color: "green" }}>{Details.id}</h2>
              </div>
              <div className="name">
                <h1>Name</h1>
                <span>:</span>
                <h2>{Details.name}</h2>
              </div>
              <div className="name">
                <h1>Phone </h1>
                <span>:</span>
                <h2>{Details.phone}</h2>
              </div>
              <div className="name">
                <h1>Billing Addres </h1>
                <span>:</span>
                <h2>{Details.billing_address}</h2>
              </div>
              <div className="name">
                <h1>Pincode </h1>
                <span>:</span>
                <h2>{Details.pincode}</h2>
              </div>
              <div className="name">
                <h1>Age </h1>
                <span>:</span>
                <h2>{Details.age}</h2>
              </div>
              <div className="name">
                <h1>Gender </h1>
                <span>:</span>
                <h2>{Details.sex}</h2>
              </div>
              <div className="name">
                <h1>Date of Birth</h1>
                <span>:</span>
                <h2>{Details.date_of_birth}</h2>
              </div>
              <div className="name">
                <h1>Reference Name </h1>
                <span>:</span>
                <h2>{Details.reference_name}</h2>
              </div>
              <div className="name">
                <h1>Reference Relation </h1>
                <span>:</span>
                <h2>{Details.reference_relation}</h2>
              </div>
              <div className="name">
                <h1>Reference Number </h1>
                <span>:</span>
                <h2>{Details.reference_number}</h2>
              </div>
              <div className="name">
                <h1>Licence Number</h1>
                <span>:</span>
                <h2>{Details.licence_number}</h2>
              </div>
              <div className="name">
                <h1>Licence File</h1>
                <span>:</span>
                <h2>
                  {" "}
                  <a href={Details.licence_file} target="_blank">
                    Licence File
                  </a>{" "}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="heading">
          <h1>Account info</h1>
          <small></small>
        </div>
        <div className="section">
          <div className="small">
            <div className="item">
              <div className="name">
                <h1>Account Number</h1>
                <span> : </span>
                <h2>{Details?.account_info?.account_number}</h2>
              </div>
              <div className="name">
                <h1>ifsc code</h1>
                <span> : </span>
                <h2>{Details?.account_info?.ifsc_code}</h2>
              </div>
              <div className="name">
                <h1>Bank Name</h1>
                <span> : </span>
                <h2>{Details?.account_info?.bank_name}</h2>
              </div>
              <div className="name">
                <h1>Upi</h1>
                <span> : </span>
                <h2>{Details?.account_info?.upi}</h2>
              </div>
              <div className="name">
                <h1>Razorpay account id</h1>
                <span> : </span>
                <h2>{Details?.razorpay_account_id}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="heading">
          <h1>Vehicle info</h1>
          <small></small>
        </div>
        <div
          className="button"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            onClick={() => showModalVehicle()}
            style={{
              width: 80,
              height: 35,
              fontSize: 14,
              border: "none",
              backgroundColor: "#00a8cb",
              borderRadius: "6px",
            }}
          >
            Update
          </button>
        </div>
        <div className="section">
          <div className="small">
            <div className="item">
              <div className="name">
                <h1>vehicle model </h1>
                <span>:</span>
                <h2>{Details?.vehicle_info?.vehicle_model} </h2>
              </div>
              <div className="name">
                <h1>Register number </h1>
                <span>:</span>
                <h2>{Details?.vehicle_info?.register_number} </h2>
              </div>
              <div className="name">
                <h1>Year of registartion </h1>
                <span>:</span>
                <h2>{Details?.vehicle_info?.year_of_registration}</h2>
              </div>
              <div className="name">
                <h1>Body color </h1>
                <span>:</span>
                <h2>{Details?.vehicle_info?.body_color} </h2>
              </div>
              <div className="name">
                <h1>Max load </h1>
                <span>:</span>
                <h2>{Details?.vehicle_info?.max_load} </h2>
              </div>
            </div>
          </div>

          <div>
            <h1
              style={{
                fontSize: 26,
                textDecoration: "underline",
                fontWeight: "bold",
                marginBottom: 30,
              }}
            >
              Vechicle Images
            </h1>
          </div>
          <div
            className="image"
            style={{
              gap: 20,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <label
                style={{ fontWeight: "bold", fontFamily: "montserratregular" }}
              >
                Front
              </label>
              <a href={Details?.vehicle_info?.vehicle_image} target="_blank">
                {" "}
                <img
                  src={Details?.vehicle_info?.vehicle_image}
                  alt=""
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "15px",
                  }}
                />
              </a>
            </div>

            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <label
                style={{ fontWeight: "bold", fontFamily: "montserratregular" }}
              >
                Left
              </label>
              <a href={Details?.vehicle_info?.image_left} target="_blank">
                <img
                  src={Details?.vehicle_info?.image_left}
                  alt=""
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "15px",
                  }}
                />
              </a>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <label
                style={{ fontWeight: "bold", fontFamily: "montserratregular" }}
              >
                Right
              </label>
              <a href={Details?.vehicle_info?.image_right} target="_blank">
                <img
                  src={Details?.vehicle_info?.image_right}
                  alt=""
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "15px",
                  }}
                />
              </a>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <label
                style={{ fontWeight: "bold", fontFamily: "montserratregular" }}
              >
                Back
              </label>
              <a href={Details?.vehicle_info?.image_back} target="_blank">
                <img
                  src={Details?.vehicle_info?.image_back}
                  alt=""
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "15px",
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="heading">
          <h1 style={{ width: "191px", textAlign: "start" }}>
            Unsettled Amount
          </h1>
          <small></small>
        </div>
        <div className="section">
          <div className="small">
            <div className="item">
              <div className="name">
                <h1>Delibuoy </h1>
                <span>:</span>
                <h2>{Details?.driver_unsettled_amount?.delibuoy} </h2>
              </div>
              <div className="name">
                <h1>Franchise </h1>
                <span>:</span>
                <h2>{Details?.driver_unsettled_amount?.franchise} </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Update Basic Information"
        visible={isDriver}
        fontWeight="bold"
        onOk={handleOkDriver}
        onCancel={handleCancelDriver}
      >
        <div className="modalcontent">
          <Drivermodal>
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setUserNames(e.target.value)}
              placeholder="Enter your name"
            ></input>
            <label>Phone number</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter your Mobile Number"
            ></input>
            <label>Billing Address</label>
            <input
              value={billing_address}
              onChange={(e) => setBillingadrs(e.target.value)}
              placeholder="Enter your Billing Address"
              type="text"
            ></input>
            <label>Pincode</label>
            <input
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              placeholder="Enter your Pincode"
              type="text"
            ></input>
            <label>Age</label>
            <input
              value={age}
              onChange={(e) => setAge(e.target.value)}
              placeholder="Enter your age"
              type="text"
            ></input>
            <label>Gender</label>
            <input
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              placeholder="Enter your gender"
              type="text"
            ></input>
            <label>Date Of Birth</label>
            <DatePicker
              style={{
                width: "400px",
                borderRadius: 3.5,
                height: "6vh",
                backgroundColor: "white",
                boxShadow: "inset 0 -1px 0 #e0e0e0",
                border: "1px solid #e0e0e0",
                display: "flex",
              }}
              disabledDate={disabledDate}
              onChange={onChange}
              defaultValue={moment(DateOfBirth, dateFormat)}
            />
            <label>Reference Name</label>
            <input
              value={referencename}
              onChange={(e) => setReferencename(e.target.value)}
              placeholder="Enter your Reference name"
              type="text"
            ></input>
            <label>Reference Relation</label>
            <input
              value={referencerelation}
              onChange={(e) => setReferencerelation(e.target.value)}
              placeholder="Enter your Reference relation"
              type="text"
            ></input>
            <label>Reference Number</label>
            <input
              value={referencenumber}
              onChange={(e) => setReferencenumber(e.target.value)}
              placeholder="Enter your Reference number"
              type="text"
            ></input>
            <label>License Number</label>
            <input
              value={Licencenumber}
              onChange={(e) => setLicencenumber(e.target.value)}
              placeholder="Enter your licence number"
              type="text"
            ></input>
            <label>Update License File</label>
            <input type="file" name="myImage" onChange={uploadLisence} />
            <label>Account number</label>
            <input
              value={account_number}
              onChange={(e) => setAccountnum(e.target.value)}
              placeholder="Account number"
              type="text"
            ></input>
            <label>IFSC Code</label>
            <input
              value={ifsc_code}
              onChange={(e) => setIfsccode(e.target.value)}
              placeholder="Enter your Ifsc code"
              type="text"
            ></input>
            <label>Bank name</label>
            <input
              value={bank_name}
              onChange={(e) => setBankname(e.target.value)}
              placeholder="Enter your Bank name"
              type="text"
            ></input>
            <label>UPI</label>
            <input
              value={upi}
              onChange={(e) => setUpi(e.target.value)}
              placeholder="Enter your Upi"
              type="text"
            ></input>
            <div style={{ width: "400px" }}>
              <img src={Details?.image} alt="img" width="80px" height="80px" />
            </div>
            <div
              style={{
                width: "400px",
                display: "flex",
                justifyContent: "space-between",
                color: "#00a8cb",
              }}
            >
              <div
                class="upload-btn-wrapper"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  class="btn"
                  style={{
                    backgroundColor: "#00a8cb",
                    color: "white",
                    border: "none",
                  }}
                >
                  Upload profile
                </button>
                <input
                  type="file"
                  id="myFile"
                  name="filename"
                  onChange={uploadprofile}
                />
                <div>{imgPreviewprofile}</div>
              </div>
            </div>
          </Drivermodal>
        </div>
      </Modal>
      {/* 2nd modal */}
      <Modal
        title="Update Vehicle Information"
        visible={isVehicle}
        fontWeight="bold"
        onOk={handleOkVehicle}
        onCancel={handleCancelVehicle}
      >
        <div className="modalcontent">
          <Vehiclemodal>
            <label>Register number</label>
            <input
              type="text"
              value={register_number}
              onChange={(e) => setRegisternmbr(e.target.value)}
              placeholder="Enter your Register number"
            ></input>
              <label>Year of registration</label>
            <input
              type="text"
              value={year_of_registration}
              onChange={(e) => setYearofregistration(e.target.value)}
              placeholder="Enter your Year of registration"
            ></input>
              <label>Body color</label>
            <input
              value={body_color}
              onChange={(e) => setBodycolor(e.target.value)}
              placeholder="Enter your body color"
              type="text"
            ></input>
             <label>Max-Load</label>
            <input
              value={max_load}
              onChange={(e) => setMaxload(e.target.value)}
              placeholder="Enter your Max load"
              type="text"
            ></input>
            {/* <div
              classname="image"
              style={{ display: "flex", justifyContent: "start", width: "80%" }}
            >
              <img src={vehicleimage} width="50px" height="40px" />
            </div>
            <div class="upload-btn-wrapper">
              <button class="btn">Upload image</button>

              <input
                type="file"
                id="file-upload"
                name="img"
                accept="image/*"
                onChange={(event) => {
                  console.log(event.target.files);
                  setVehicleimage(event.target.files[0]);
                }}
              />
            </div> */}
            <div
              style={{
                width: "350px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={Details?.vehicle_info?.vehicle_image}
                alt="img"
                width="80px"
                height="80px"
              />
              <div>{imgPreview}</div>
            </div>
            <div
              style={{
                width: "400px",
                display: "flex",
                justifyContent: "space-between",
                color: "#00a8cb",
              }}
            >
              <div
                class="upload-btn-wrapper"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  class="btn"
                  style={{
                    backgroundColor: "#00a8cb",
                    color: "white",
                    border: "none",
                  }}
                >
                  Vechile Front
                </button>
                <input
                  type="file"
                  id="myFile"
                  name="filename"
                  onChange={uploadSingleFile}
                />
              </div>
            </div>
            {/* <div
              classname="image"
              style={{ display: "flex", justifyContent: "start", width: "80%" }}
            >
              <img src={imageleft} width="50px" height="40px" />
            </div>
            <div class="upload-btn-wrapper">
              <button class="btn">Upload image</button>

              <input
                type="file"
                id="file-upload"
                name="img"
                accept="image/*"
                onChange={(event) => {
                  console.log(event.target.files);
                  setImageleft(event.target.files[0]);
                }}
              />
            </div> */}
            <div
              style={{
                width: "350px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={Details?.vehicle_info?.image_left}
                alt="img"
                width="80px"
                height="80px"
              />
              <div>{imgPreview1}</div>
            </div>
            <div
              style={{
                width: "400px",
                display: "flex",
                justifyContent: "space-between",
                color: "#00a8cb",
              }}
            >
              <div
                class="upload-btn-wrapper"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  class="btn"
                  style={{
                    backgroundColor: "#00a8cb",
                    color: "white",
                    border: "none",
                  }}
                >
                  Vechile Left
                </button>
                <input
                  type="file"
                  id="myFile"
                  name="filename"
                  onChange={uploadSingleFile1}
                />
              </div>
            </div>
            <div
              style={{
                width: "350px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={Details?.vehicle_info?.image_right}
                alt="img"
                width="80px"
                height="80px"
              />
              <div>{imgPreview2}</div>
            </div>
            <div
              style={{
                width: "400px",
                display: "flex",
                justifyContent: "space-between",
                color: "#00a8cb",
              }}
            >
              <div
                class="upload-btn-wrapper"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  class="btn"
                  style={{
                    backgroundColor: "#00a8cb",
                    color: "white",
                    border: "none",
                  }}
                >
                  Vechile Right
                </button>
                <input
                  type="file"
                  id="myFile"
                  name="filename"
                  onChange={uploadSingleFile2}
                />
              </div>
            </div>

            <div
              style={{
                width: "350px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                src={Details?.vehicle_info?.image_back}
                alt="img"
                width="80px"
                height="80px"
              />
              <div>{imgPreview3}</div>
            </div>
            <div
              style={{
                width: "400px",
                display: "flex",
                justifyContent: "space-between",
                color: "#00a8cb",
              }}
            >
              <div
                class="upload-btn-wrapper"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  class="btn"
                  style={{
                    backgroundColor: "#00a8cb",
                    color: "white",
                    border: "none",
                  }}
                >
                  Vechile Back
                </button>
                <input
                  type="file"
                  id="myFile"
                  name="filename"
                  onChange={uploadSingleFile3}
                />
              </div>
            </div>
          </Vehiclemodal>
        </div>
      </Modal>
    </Driverexpfranchise>
  );
}

export default Driverexpf;
