import React, { useEffect, useState } from "react";
import { Settlementfranchise } from "./style";
import { Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Card } from "antd";
import { Settlementreq } from "./store";
import Loader from "../../Admin/Widget/common/Loader";
import { notification } from "antd";
import { firebase } from "../../../Common/firebase";
import logo from "../../../Images/favicon.png"
function Settlementf() {
  const [
    { isLoading, settlements, dueamount, razorpayments, razorpaymentskey },
    {
      getsettlemets,
      getRazorpaydata,
      setRazorpaypaymentid,
      setRazorpayorderid,
      Postsettlement,
    },
  ] = Settlementreq();
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  localStorage.setItem("token", token);
  
  const postpayment = (pi, oi) => {

    setRazorpayorderid(oi);
    setRazorpaypaymentid(pi);
    Postsettlement();
  };




  const options = {
    key: razorpaymentskey,
    amount: razorpayments?.amount, //  = INR 1
    name: razorpayments?.name,
    description: razorpayments?.description,
    order_id: razorpayments?.razorpay_order_id,
    image: "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png",

    handler: function (response) {
    
      let payment_id = response.razorpay_payment_id;
      let order_id = response.razorpay_order_id;

      if (response === null) {
        alert("not succefull");
      } else {
        notification["success"]({
          message: "success",
          description: "your payment was successfully",
        });
        postpayment(payment_id, order_id);
      }
    },
    prefill: {
      name: razorpayments?.franchise?.name,
      contact: razorpayments?.franchise?.contact,
      email: razorpayments?.franchise?.email,
    },
    notes: {
      address: "some address",
    },
    theme: {
      color: "#00a8cb",
      hide_topbar: false,
    },
  };
  const openPayModal = () => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    getsettlemets();
    getRazorpaydata();
  }, [token]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Settlementfranchise>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Settlement</h2>
      </div>
      <div className="settlement">
        <div className="heading">
          <h1>Settlement</h1>
          <Divider orientation="left" />
        </div>
      </div>
      <div
        className="cards"
        style={{
          width: "95%",
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 50,
        }}
      >
        {" "}
        <Card style={{ width: 200 }}>
          <p>Due amount: {dueamount} </p>
          {dueamount >= 100 ? (
            <button
            onClick={openPayModal}
              style={{
                width: 100,
                height: 30,
                backgroundColor: "#32CD32",
                border: "none",
                borderRadius: "4px",
              }}
            >
              pay
            </button>
          ) : (
            <button
              style={{
                width: 100,
                height: 30,
                backgroundColor: "#32CD32",
                border: "none",
                borderRadius: "4px",
              }}
            >
              pay
            </button>
          )}
        </Card>
      </div>
      <div className="content">
        <div className="border" style={{ backgroundColor: "white"}}>
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No:</h1>
                </th>
                <th className="head-off">
                  <h1>Id</h1>
                </th>

                <th className="head-off">
                  <h1>Razorpay id</h1>
                </th>
                <th className="head-off">
                  <h1>Razorpay pay id</h1>
                </th>
                <th className="head-off">
                  <h1>Payment recieved</h1>
                </th>
                <th className="head-off">
                  <h1>Amount</h1>
                </th>
                <th className="head-off">
                  <h1>Refunded</h1>
                </th>
                <th className="head-off">
                  <h1>Date created</h1>
                </th>

                <th className="head-off">
                  <h1>Franchise</h1>
                </th>
              </tr>
            </thead>

            <tbody>
              {settlements?.previous_settlements?.map((res, i) => (
                <tr className="body-row-off">
                  <td>{i + 1}</td>
                  <td>{res.id}</td>
                  <td>{res.razorpay_order_id}</td>
                  <td>{res.razorpay_payment_id}</td>
                  <td>{res.payment_recieved === true ? "Yes" : "No"}</td>
                  <td>{res.amount}</td>
                  <td>{res.refunded === true ? "Yes" : "No"}</td>
                  <td>{res.date_created}</td>
                  <td>{res.franchise}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Settlementfranchise>
  );
}

export default Settlementf;
