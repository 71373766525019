import {Getdriverfranchise} from '../../../../infrastructure/Admin-franchise/Driver'
const actions = {
    setisLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
    setDriver:
      (driver) =>
      ({ setState }) => {
        setState({ driver });
      },
  
      getdriverdetails:
      () =>
      async ({ dispatch }) => {
        dispatch(actions.setisLoading(true));
       
        try {
          const res = await Getdriverfranchise();
        
          dispatch(actions.setDriver(res.data.data));
          dispatch(actions.setisLoading(false));
        } catch (err) {
        } finally {
          dispatch(actions.setisLoading(false));
        }
      },
      
  };
  export default actions;
  