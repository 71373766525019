import { auth } from "../../../Common/firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";


const signInWithEmailAndPassword = async (email, password) => {
  // try {
    await auth.signInWithEmailAndPassword(email, password);
    return signInWithEmailAndPassword;
  // } catch (err) {
  //   console.error(err);

  // }
};
export default signInWithEmailAndPassword;
