import axios from "axios";
import config from "../../../../Common/config";
import { firebase } from "../../../../Common/firebase";

const baseURL = config.BASE_API;

const base = async (options) => {
  const user = firebase.auth().currentUser;
  const mytoken = user && (await user.getIdToken());
  let mytokenrres = localStorage.getItem("token");
  const res = await axios({
    baseURL,
    headers: {
      "delibuoy-s-key": mytokenrres === null ? mytoken:mytokenrres,
      type: "franchise",
      Accept: "application/json",
      // Authorization: "Bearer " + mytoken,
    },
    ...options,
  }).then((res) => {
    console.log(res,"my error msg ...........................!!!!!")
      return res;
    })
    .catch((err) => {
     console.log(err.status,"msg from base api...................................")
      return err;
    });
    return res;
};

export const get = (url, params) => {
  const options = {
    method: "get",
    url,
    params,
  };
  return base(options);
};

export const patch = (url, data) => {
  const options = {
    method: "patch",
    url,
    data,
  };
  return base(options);
};

export const post = (url, data) => {
  const options = {
    method: "post",
    url,
    data,
  };
  return base(options);
};

export const put = (url, data) => {
  const options = {
    method: "put",
    url,
    data,
  };
  return base(options);
};

export const del = (url, data) => {
  const options = {
    method: "delete",
    url,
    data,
  };
  return base(options);
};
export const putt = (url, data) => {
  const options = {
    method: "put",
    url,
    data,
  };
  return base(options);
};
