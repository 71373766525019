import React from "react";
import { Route, Switch } from "react-router-dom";
import ContactUs from "../ContactUs";
import Footerweb from "../Footer";
import HeaderDelibuoy from "../Header";
import Home from "../Home";
import Privacypolicy from "../PrivacyPo";
import Termsandconditions from "../TermsAndConditions";
import { useLocation } from "react-router-dom";
function RouterWeb() {
  let location = useLocation();
  return (
    <div>
      <HeaderDelibuoy />

      <Switch>
        <Route exact path="/home" component={Home} />

      </Switch>
      <Footerweb />
    </div>
  );
}

export default RouterWeb;
{
  /* 
<Home />

<Footerweb /> */
}
