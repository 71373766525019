import { GetFranchisesettlement } from "../../../../infrastructure/Admin/FranchiseSettlement";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setSettlement:
    (settlement) =>
    ({ setState }) => {
      setState({ settlement });
    },
    setRazorpaypaymentid:
    (razorpaypaymentid) =>
    ({ setState }) => {
      setState({ razorpaypaymentid });
    },
    setDatecreated:
    (datecreated) =>
    ({ setState }) => {
      setState({ datecreated });
    },
    setFranchisename:
    (franchisename) =>
    ({ setState }) => {
      setState({ franchisename });
    },
    setFranchiseid:
    (franchiseid) =>
    ({ setState }) => {
      setState({ franchiseid });
    },
    setRefunded:
    (refunded) =>
    ({ setState }) => {
      setState({ refunded });
    },
  getSettlement:
    () =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      const {razorpaypaymentid,datecreated,franchisename,franchiseid,refunded } = getState();
      try {
        const res = await GetFranchisesettlement(razorpaypaymentid,datecreated,franchisename,franchiseid,refunded );
        dispatch(actions.setSettlement(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};

export default actions;
