import React, { useEffect, useState } from "react";
import { Settlediv } from "./style";
import { Divider } from "antd";
import { LeftOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { SettlementDues } from "./store";
import { firebase } from "../../../Common/firebase";
import { Empty, Input } from "antd";

function Settlementslist() {
  let history = useHistory();
  const [
    { isLoading,settlementdata, franchise_id, franchise_name },
    {
      setsettlementdata,
      setfranchise_id,
      setfranchise_name,
      GetSettlementDues,
    },
  ] = SettlementDues();

  //......................hide.... ............................................................
  const [hideI, sethideI] = useState(true);
  const hiddenI = () => {
    sethideI(!hideI);
    setfranchise_id("");
  };
  const [hide, sethide] = useState(true);
  const hidden = () => {
    sethide(!hide);
    setfranchise_name("");
  };
  //search..............................................................................................
  const Searchrequestid = (e) => {
    setfranchise_id(e.target.value);
    GetSettlementDues();
  };
  const Searchrequestname = (e) => {
    setfranchise_name(e.target.value);
    GetSettlementDues();
  };
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);
  useEffect(() => {
    GetSettlementDues();
  }, [token, franchise_id]);

  
  return (
    <Settlediv>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>SettlementDues</h2>
      </div>
      <div className="settlement">
        <div className="heading">
          <h1>Settlement Dues</h1>
          <Divider orientation="left" />
        </div>
      </div>
      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>Sl No</h1>
                </th>
                <th className="head-off">
                  <h1>
                    ID
                    {hideI ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenI()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestid(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenI()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Name
                  {hide ? (
                      <div>
                        <SearchOutlined style={{ color: 'grey' }} onClick={() => hidden()} />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestname(e)}
                          suffix={<CloseOutlined style={{ color: 'grey' }} onClick={() => hidden()} />}
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Email</h1>
                </th>
                <th className="head-off">
                  <h1>phone</h1>
                </th>
                <th className="head-off">
                  <h1>unsettled_amount</h1>
                </th>
              </tr>
            </thead>
            <tbody>
              {settlementdata?.map((res, i) => (
                <tr className="body-row-off">
                  <td>{i + 1}</td>
                  <td>{res.id}</td>
                  <td>{res.name}</td>
                  <td>{res.email}</td>
                  <td>{res.phone}</td>
                  <td>{res.unsettled_amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Settlediv>
  );
}

export default Settlementslist;
