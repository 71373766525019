import React from "react";
import { Report } from "./style";
function ReportError() {
  return (
    <Report>
      <h1> Page Not Found </h1>
    </Report>
  );
}

export default ReportError;
