import Styled from "styled-components"

export const  Tripsdiv = Styled.div`
width: 85vw;
margin-top:80px;
margin-left: 15vw;
.title{
  display:flex;
   justify-content:flex-end;
   margin-right:200px;
   margin-top:30px;
   gap:10px;
  h1{
    font-size:14px;
    color:grey;
  }
  h2{
    font-size:14px;
    color:grey;
  }
}
.trip {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  .heading {
    width: 95%;
    display: flex;
    align-items: center;
    h1{
      font-size:22px;
      font-family: "Quicksand", sans-serif;
      font-weight:bold;
    }
  }
}
.content {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  .border {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 80%;
    padding: 10px;
    .tables table.official {
      width: 100%;
      border: 1px solid #dddddd;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
      border-radius: 0px 4px 0px 0px;
    }
    tr.head-row-off {
      border-bottom: 1px solid #dddddd;
    }
    th.head-off {
      font-style: normal;
      font-weight: 6 00;
      font-size: 14px;
      line-height: 20px;
      height: 40px;

      text-align: left;
      font-family: FSMedium;
      font-style: normal;
      font-weight: 500px !important;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
      width: 235px;
      h1 {
        font-size: 18px;
        font-weight:bold !important;
         font-family: "dm_sansregular";
      }
    }
    tr.body-row-off {
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #dddddd;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
      height: 60px;
      text-align: left;
      font-family: FSMedium;
      font-style: normal;
      font-weight: 500px !important;
      font-size: 16px;
      line-height: 150%;
      font-family: "dm_sansregular";
      color: #000000;

      .qty {
        display: flex;
        gap: 15px;
      }
      img {
        width: 120px;
        border-radius: 6px;
      }
      h1 {
        font-size: 16px;
      }
      h2 {
        font-size: 16px;
      }
    }
    tr.body-row-off:hover {
  
      background: linear-gradient(
          0deg,
          rgba(96, 36, 57, 0.08),
          rgba(96, 36, 57, 0.08)
        ),
        #ffffff;
    }
  }
}`