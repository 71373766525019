import { GetCustomerdetailstrip } from "../../../../infrastructure/Admin/customertrip";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setCustomertrip:
    (customertrip) =>
    ({ setState }) => {
      setState({ customertrip });
    },
    getCustomertrips:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetCustomerdetailstrip(id);
     
        dispatch(actions.setCustomertrip(res.data.data));

      } catch (err) {
      
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },

};
export default actions;
