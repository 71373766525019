import styled from "styled-components";
export const Terms =styled.div`
.pagesectionp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;

    .pagewidthp {
      width: 70%;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    p {
      font-family: "dm_sansregular";
      text-align: justify;
      line-height: 30px;
      font-size: 18px;
      @media(max-width:540px){
          font-size:16px;
      }
    }
    h1 {
      font-family: "dm_sansregular";
      color: gray;
      display: flex;
      justify-content: start;
      font-size: 28px;
      font-weight:bold;
      @media(max-width:540px){
          font-size:26px;
      }
      @media(max-width:440px){
          font-size:24px;
      }
    }
    ul {
      list-style: none;
    }
  }
`
