import { get ,post} from "../Common/remote/base_api";
export const Getsettlementsres = (data) => {
  return get(`franchise/get-admin-settlements/`, data);
};
export const GetRazorpayres = (data) => {
  return get(`franchise/make-settlement/`, data);
};
export const Settelementpay = (data) => {
  return post(`franchise/settlement-success/`, data);
};
