import React, { useEffect, useState } from "react";
import { CallerExp, Updatemodal } from "./style";
import BackArrow from "../../Admin/Widget/common/BackArrow";
import { Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Getdetailscaller } from "./store";
import { Modal } from "antd";
import { firebase } from "../../../Common/firebase";
import Loader from '../../Admin/Widget/common/Loader'
function Callerdetails({ match }) {
  const [
    { isLoading, details, name, address, phone, pincode, image, images },
    {
      setdetails,
      GetCallerdetails,
      setname,
      setaddress,
      setphone,
      setpincode,
      setimage,
      setimages,
      Updatecaller
    },
  ] = Getdetailscaller();

  const [preview, setpreview] = useState(null);
  const [idd, setidd] = useState(match.params.id);
// imagepreview..........................................................
  const uploadSingleFile = (e) => {
    setimage(e.target.files[0]);
    setpreview(URL.createObjectURL(e.target.files[0]));
  };
  let imgPreview;
  if (preview) {
    imgPreview = <img src={preview} alt="" width='50px' height='40px' />;
  }
//................................................................

const [token, setToken] = useState("");
useEffect(() => {
  //import { firebase } from "../../../Common/firebase";
  return firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      user
        .getIdToken(true)
        .then((latestToken) => setToken(latestToken))
        .catch((err) => console.log(err));
    }
  });
}, []);
localStorage.setItem("token", token);


  useEffect(() => {
    GetCallerdetails(idd);
  }, [token]);

 

  //modal........................................................
  const [isUpdate, setIsUpdate] = useState(false);

  const showModalUpdate = () => {
    setIsUpdate(true);
  };

  const handleOkUpdate = () => {
    Updatecaller(idd);

    GetCallerdetails(idd);
    setIsUpdate(false);
  };

  const handleCancelUpdate = () => {
    setIsUpdate(false);
  };
  if(isLoading) {
    return <Loader/>
}
  return (
    <CallerExp>
      <div className="title">
        <h1>Caller</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>{details.name}</h2>
      </div>
      <div className="arrow">
        <BackArrow />
      </div>
      <div className="main">
        <div className="heading">
          <h1>Caller</h1>
          <Divider orientation="left" />
        </div>

        <div className="section">
          <div className="small">
            <div className="item">
              <div className="name">
                <h1>Name </h1>
                <span>:</span>
                <h2>{details.name}</h2>
              </div>
              <div className="name">
                <h1>Address</h1>
                <span>:</span>
                <h2>{details.address}</h2>
              </div>
              <div className="name">
                <h1>Phone </h1>
                <span>:</span>
                <h2>{details.phone}</h2>
              </div>
              <div className="name">
                <h1>Pincode </h1>
                <span>:</span>
                <h2>{details.pincode}</h2>
              </div>
              <div className="name">
                <h1>Total solved request </h1>
                <span>:</span>
                <h2>{details.total_solved_requests}</h2>
              </div>
              <div className="name">
                <h1>Total pending request </h1>
                <span>:</span>
                <h2>{details.total_pending_requests}</h2>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "30px" }}
          >
            <div
              className="button"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                onClick={() => showModalUpdate()}
                style={{
                  width: 80,
                  height: 35,
                  fontSize: 14,
                  border: "none",
                  backgroundColor: "#00a8cb",
                  borderRadius: "6px",
                }}
              >
                Update
              </button>
            </div>
            <div
              className="image"
              style={{ width: "200px", height: "200px", borderRadius: "50%",objectFit:"cover" }}
            >
              <img
                src={details.image}
                alt="image"
                style={{ width: "100%", height: "100%", display: "block" }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Update Caller Information"
        visible={isUpdate}
        fontWeight="bold"
        onOk={handleOkUpdate}
        onCancel={handleCancelUpdate}
      >
        <div className="modalcontent">
          <Updatemodal>
            <input
              type="text"
              value={name}
              placeholder="Enter your name"
              onChange={(e) => setname(e.target.value)}
            ></input>
             <input
              type="text"
              value={address}
              placeholder="Enter your name"
              onChange={(e) => setaddress(e.target.value)}
            ></input>
             <input
              type="text"
              placeholder="Enter your Mobile Number"
              value={phone}
              onChange={(e) => setphone(e.target.value)}
              maxlength="13"
            ></input>
            <input
              placeholder="Enter your Pincode"
              type="text"
              value={pincode}
              onChange={(e) => setpincode(e.target.value)}
              maxlength="6"
            ></input>
            <div style={{width:'400px',}}> 
            <img src={details.image} alt='img' width='50px' height="40px"/>
            </div>
            <div style={{width:'400px',display:'flex',justifyContent:"space-between",color:'#00a8cb'}}>
               <div
              class="upload-btn-wrapper"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <button class="btn" style={{backgroundColor:'#00a8cb',color:'white',border:'none'}}>Upload profile</button>
              <input
                type="file"
                id="myFile"
                name="filename"
                onChange={uploadSingleFile}
              />
              <div >{imgPreview}</div>
              </div>
            </div>
          </Updatemodal>
        </div>
      </Modal>
    </CallerExp>
  );
}

export default Callerdetails;
