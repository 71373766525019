import styled from "styled-components";
import backgrond from "../../../Images/truckbanner.jpg";
export const HomeBackground = styled.div`
  .main {
    // background: linear-gradient(
    //     180deg,
    //     rgba(225, 172, 37, 0.16) 0%,
    //     rgba(255, 255, 255, 0) 100%
    //   ),
    //   linear-gradient(0deg, rgba(11, 23, 35, 0.43), rgba(11, 23, 35, 0.43)),
    //   url(${backgrond});

    background:url(${backgrond}); 

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  

 

  
    .textimonal {
   
    }
   
  }
  .animaton {

    .aboutus {
      // background-color: #ffc300;
      // height: 300px;
      display: flex;
    
      justify-content: center;
      .top {
        width: 70%;
        display: flex;
        flex-direction: column;
        // paddin-top:50px;
        padding-bottom: 50px;
        .head {
          width: 100%;
          display: flex;
          padding-top: 30px;
          h1 {
            font-family: "paytone_oneregular";
            color: black;
            font-size: 26px;
          }
        }
        .content {
          margin-top: 50px;
          text-align: left;
          p {
            color: black;
            font-size: 16px;
            font-family: "dm_sansregular";
            text-align: justify;
          }
        }
      }
    }
  }
  .topics {
    display: flex;
    justify-content: center;
    background-color:#f8f9fb;
    .wrapper {
      width:70%;
      .mainpart {
        display: flex;
        height:500px;
        align-items: center;
        gap:200px;
        @media(max-width: 980px){
          flex-direction: column;
          height:unset;
          gap:100px;
        }
        .left {
          width:500px;
           height:400px;
        @media(max-width:1080px){
          width:600px;
          height:unset;
        }
        @media(max-width:980px){
          width:500px;
          height:unset;
          padding-top:30px;
        }
        @media(max-width:540px){
          width:400px;
       
        }
        @media(max-width:440px){
          width:300px;
       
        }
          img {
            width:100%;
            height:100%;
          }
        }
        .right {
          width:500px;
          height:400px;
          @media(max-width:540px){
            width:400px;
            height:unset;
          }
          @media(max-width:440px){
            width:300px;
            height:unset;
          }
          @media(max-width:380px){
            width:250px;
            height:unset;
          }
          h1{
            font-weight: bold;
            font-size: 22px;
          }
          .maind{
            p{
              font-size:14px;
              text-align: justify;

            }
          }
          .learn{
            margin-top:100px;
            button{
              border-radius: 4px;
              background-color: #ffc300;
              border: none;
              color: #FFFFFF;
              text-align: center;
              font-size: 28px;
              padding: 10px;
              width: 200px;
              cursor: pointer;
              margin: 5px;
              cursor:pointer;
              &:active{
                background-color:black;

              }
            
            }
          }
        }
      }
      .mainpart2 {
        display: flex;
        height:500px;
        align-items: center;
        gap:200px;
        @media(max-width: 980px){
          flex-direction: column-reverse;
          height:unset;
          gap:100px;
        }
        .left2 {
          width:500px;
          @media(max-width:540px){
            width:400px;
            height:unset;
          }
          @media(max-width:440px){
            width:300px;
            height:unset;
          }
          @media(max-width:380px){
            width:250px;
            height:unset;
           
          }
          h1{
            font-weight: bold;
            font-size: 22px;
          }
          .maind{
            p{
              font-size:16px;
              text-align: justify;

            }
          }
          .learn{
            margin-top:100px;
            button{
              border-radius: 4px;
              background-color: #ffc300;
              border: none;
              color: #FFFFFF;
              text-align: center;
              font-size: 28px;
              padding: 10px;
              width: 200px;
              cursor: pointer;
              margin: 5px;
              cursor: pointer;
            
              &:active{
                background-color:black;
                
              }
            }
          }
        }
         
        }
        .right2 {
          width:500px;
          object-fit: cover;
          @media(max-width:1080px){
            width:600px;
            height:unset;
          }
          @media(max-width:980px){
            width:500px;
            height:unset;
            padding-top:30px;
          }
          @media(max-width:540px){
            width:400px;
         
          }
          @media(max-width:440px){
            width:300px;
         
          }
          img {
            width:100%;
            height:100%;
          }
        }
      }
    }

  }
  .contentss {
    height:600px;
    width:100%;
    background-color: #f5f5f5;
    display:flex;
    
    justify-content:center;
    align-items: center;
    @media(max-width:740px){
      height:unset;
    }
    .wraper3{
      width:90%;
      justify-content: center;
     display: flex;
     @media(max-width:740px){
       flex-direction: column;
       align-items: center;
     }
      .detail3{
        width:33%;
        @media(max-width:740px){
          width:unset;
    
        
        }
        img{
          width:350px;
          object-fit: cover;
          height: 300px;
          border-radius:10px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
          @media(max-width:1080px){
            width:250px;
            height:250px;
          }
          @media(max-width:980px){
            width:200px;
            height:200px;
          }
          @media(max-width:740px){
            width:400px;
            height:350px;
          
          }
          @media(max-width:480px){
            width:300px;
            height:300px;
          
          }
       
        }
        .list{
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top:20px;
          h1{
           font-size:22px;
           font-weight:bold;
          }
          p{
            width:350px;
            text-align:justify;
            @media(max-width: 1190px){
              width:280px;
            }
            @media(max-width: 980px){
              width:200px;
            }
            @media(max-width: 740px){
              width:350px;
            }
            @media(max-width: 540px){
              width:300px;
            }
            @media(max-width: 480px){
              width:250px;
            }
          }
        }
      }
    }
  }

`;
