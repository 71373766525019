import {
  Delibuoycharges,
  Delibuoychargesupdate,
} from "../../../../infrastructure/Admin/DelibuoyCharges";
import { notification } from "antd";
const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setCharges:
    (Charges) =>
    ({ setState }) => {
      setState({ Charges });
    },
  setServiceCharge:
    (service_charge) =>
    ({ setState }) => {
      setState({ service_charge });
    },
  setdelibuoy_commission:
    (delibuoy_commission) =>
    ({ setState }) => {
      setState({ delibuoy_commission });
    },
  setfranchise_commission:
    (franchise_commission) =>
    ({ setState }) => {
      setState({ franchise_commission });
    },
  setdriver_registration_fee:
    (driver_registration_fee) =>
    ({ setState }) => {
      setState({ driver_registration_fee });
    },
  setfranchise_driver_registration_share:
    (franchise_driver_registration_share) =>
    ({ setState }) => {
      setState({ franchise_driver_registration_share });
    },
  setdelibuoy_driver_registration_share:
    (delibuoy_driver_registration_share) =>
    ({ setState }) => {
      setState({ delibuoy_driver_registration_share });
    },
  GetDelibuoycharges:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await Delibuoycharges();
        dispatch(actions.setCharges(res.data.data));
        dispatch(actions.setServiceCharge(res.data.data.service_charge));
        dispatch(actions.setdelibuoy_commission(res.data.data.delibuoy_commission));
        dispatch(
          actions.setfranchise_commission(res.data.data.franchise_commission)
        );
        dispatch(
          actions.setdriver_registration_fee(res.data.data.driver_registration_fee)
        );
        dispatch(
          actions.setfranchise_driver_registration_share(
            res.data.data.franchise_driver_registration_share
          )
        );
        dispatch(
          actions.setdelibuoy_driver_registration_share(
            res.data.data.delibuoy_driver_registration_share
          )
        );
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Updatecharges:
    () =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      try {
        const {
          service_charge,
          delibuoy_commission,
          franchise_commission,
          driver_registration_fee,
          franchise_driver_registration_share,
          delibuoy_driver_registration_share,
        } = getState();
        var formData = new FormData();
        formData.append("service_charge", service_charge);
        formData.append("delibuoy_commission", delibuoy_commission);
        formData.append("franchise_commission", franchise_commission);
        formData.append("driver_registration_fee", driver_registration_fee);
        formData.append(
          "franchise_driver_registration_share",
          franchise_driver_registration_share
        );
        formData.append(
          "delibuoy_driver_registration_share",
          delibuoy_driver_registration_share
        );

        await Delibuoychargesupdate(formData);
        notification["success"]({
          message: "success",
          description: "Delibuoy Charges Update Succesfully",
        });
        dispatch(actions.GetDelibuoycharges());
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
