import styled from "styled-components";

export const HeaderNav = styled.div`
  display: flex;
  justify-content: center;
  background-color: #000814 ;
  opacity:0.9 ;

  position: fixed;
  width: 100%;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 80px;

    .main {
      width: 180px;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .gif {
      width: 100px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    ul {
      display: flex;
      list-style-type: none;
      gap: 20px;
      align-items: center;
      margin: 0;
      @media(max-width:640px){
        display:none;
      }
      li {
        cursor: pointer;
        color: white;
        font-size:18px;
        letter-spacing:1px;
        font-family: 'Quicksand', sans-serif;
        font-weight:bold;

      }
      a{
        text-decoration:none;
      }
    }
    .hamburger{
      display:none;
      @media(max-width:640px){
        display:block;
      }
    }
  }
`;
