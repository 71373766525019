import styled from "styled-components";
import backgrond from "../../../Images/delibuoyvan.jpg";
export const Contact=styled.div `

height:100vh;
.page {
  background:url(${backgrond}); 
    background-blend-mode: multiply, multiply, normal;
    background-repeat: no-repeat;
    background-position: center;
  
    height: 800px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
  }
}
.page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
       font-family: 'paytone_oneregular';
      color: white;
      font-size: 45px;
      margin: 0px;
      padding: 0px;
      margin-top:100px;
    }
    ul {
      list-style: none;
      display: flex;
      gap: 15px;
      font-size: 20px;
    }
  }
  .arrow{
    margin-bottom:100px;
  }
 .section{
   margin-top:50px;
   display: flex;
   justify-content: center;
   .top{

     width:70%;
     .contactus{
       margin-bottom:30px;
       display: flex;
       justify-content: center;
       h1{
        font-family: 'dm_sansregular';
         font-size:18px;
       }
     }
     .heading{
       margin-bottom:30px;
       h1{
          font-family: 'pt_serifbold';
          color:grey;
          font-weight:bold;
       }
     }

     .inputbox{
         font-family: 'dm_sansregular';
       display: flex;
       justify-content: center;
        gap:30px;
      
        width:100%;
       .addres{
       
         display: flex;
         flex-direction: column;
         margin-bottom:10px;
         label{
           font-size: 14px;
             font-family: 'dm_sansregular';
         }
         input{
             font-family: 'dm_sansregular';
           width:400px;
          border-radius: 3.5px;
          padding: 1vh;
          font-size: 1.7vh;
          border: 1px solid #e0e0e0;
          height: 7vh;
          background: white;
          box-shadow: inset 0 -1px 0 #ffd60a;
          @media(max-width:870px){
              width:340px;
          }
          @media(max-width:800px){
              width:274px;
          }
          @media(max-width:670px){
              width:210px;
          }
          @media (max-width:540px){
              width:100%;
          }
         }
         
       }
       @media(max-width:540px){
           flex-direction:column;
       }
     }
     .textarea{
         
       display: flex;
       justify-content: center;
      margin-bottom:40px;
       .main{
         flex-direction: column;
         display: flex;
         @media(max-width:540px){
            width:100%;
        }
       }
       input{
           font-family: 'dm_sansregular';
         width:822px;
          border-radius: 3.5px;
          padding: 1vh;
          font-size: 1.7vh;
          border: 1px solid #e0e0e0;
          height: 13vh;
          background: white;
          box-shadow: inset 0 -1px 0 #ffd60a;
          @media(max-width:870px){
              width:712px;
          }
          @media(max-width:800px){
            width:580px;
        }
        @media(max-width:670px){
            width:452px;
        }
        @media(max-width:540px){
            width:100%;
        }
       
       }
       
     }
     .btn{
       margin-bottom:30px;
       .btn1{
         display: flex;
         justify-content: center;
         button{
             font-family: 'dm_sansregular';
           width: 300px;
          height: 50px;
          background:#ffc300;
          border: none;
          border-radius: 8px;
          color:black;
          cursor: pointer;
          &:active {
            background-color: black;
            color: white;
            
         }
         @media(max-width:800px){
            width:274px;
        }
        @media(max-width:670px){
            width:246px;
        }
        @media (max-width:540px){
            width:223px;
            height:40px;
        }
        @media (max-width:420px){
            width:126px;
         
        }
       }
     }
   }

 }
`