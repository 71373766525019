import React from "react";
import { Request } from "./style";
import { Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
function SupportRequests() {
  return (
    <Request>
      <div>
        <div className="title">
          <h1>Home</h1>
          <LeftOutlined style={{ color: "grey" }} />
          <h2>Support Request</h2>
        </div>
        <div className="support">
          <div className="heading">
            <h1>Support Request</h1>
            <Divider orientation="left" />
          </div>
        </div>
        <div className="content">
          <div className="border">
            <table className="official">
              <thead>
                <tr className="head-row-off">
                  <th className="head-off">
                    <h1>SL No</h1>
                  </th>
                  <th className="head-off">
                    <h1>Id </h1>
                  </th>
                  <th className="head-off">
                    <h1>Name</h1>
                  </th>
                  <th className="head-off">
                    <h1>phone</h1>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="body-row-off">
                  <td>gh</td>
                  <td>hgf</td>
                  <td>ff</td>
                  <td>bfhg</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Request>
  );
}

export default SupportRequests;
