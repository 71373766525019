import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const Franchesis = createStore({
  name: "franchises",
  initialState,
  actions,
});

export const Franchesisloginexp = createHook(Franchesis, {
  selector: (state) => state,
});
