import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const Franchiseprofile = createStore({
  name: "Franchise_profile",
  initialState,
  actions,
});

export const MyFranchiseprofile = createHook(Franchiseprofile, {
  selector: (state) => state,
});
