import React, { useEffect,useState } from "react";
import { RequestD } from "./style";
import { LeftOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { GetDriverlist } from "./store";
import { useHistory } from "react-router-dom";
import { firebase } from "../../../Common/firebase";
function Driverreqlist() {
  let history = useHistory();
  const [{ isLoading, driverreq }, { getDriverreq }] = GetDriverlist();

  const DriverExp = (id) => {
    history.push(`/admin/driverExp/${id}`);
  };
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);

  useEffect(() => {
    getDriverreq();
  }, [token]);

  return (
    <RequestD>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Driver Req list</h2>
      </div>
      <div className="trip">
        <div className="heading">
          <h1>Driver Req List</h1>
          <Divider orientation="left" />
        </div>
      </div>

      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>Sl No</h1>
                </th>
                <th className="head-off">
                  <h1>ID</h1>
                </th>
                <th className="head-off">
                  <h1>Name</h1>
                </th>
                <th className="head-off">
                  <h1>Phone</h1>
                </th>
                <th className="head-off">
                  <h1>Email</h1>
                </th>
                <th className="head-off">
                  <h1>Vehicle Reg Num</h1>
                </th>
                <th className="head-off">
                  <h1> Blocked</h1>
                </th>
                <th className="head-off">
                  <h1>Account Status</h1>
                </th>
              </tr>
            </thead>
            <tbody>
              {driverreq?.map((driver, i) => (
                <tr className="body-row-off">
                  <td onClick={() => DriverExp(driver.id)}>{i + 1}</td>
                  <td onClick={() => DriverExp(driver.id)}>{driver.id}</td>
                  <td onClick={() => DriverExp(driver.id)}>{driver.name}</td>
                  <td onClick={() => DriverExp(driver.id)}>{driver.phone}</td>
                  <td onClick={() => DriverExp(driver.id)}>{driver.email}</td>
                  <td onClick={() => DriverExp(driver.id)}>
                    {driver.vehicle_registration_number}
                  </td>
                  <td onClick={() => DriverExp(driver.id)}>
                    {driver.is_blocked === true ? "Yes" : "No"}
                  </td>
                  <td onClick={() => DriverExp(driver.id)}>
                    {driver.account_status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </RequestD>
  );
}

export default Driverreqlist;
