const initialState = {
  isLoading: true,
  firebase_email: "",
  firebase_password: "",
  confirmpassword: "",
  name: "",
  address: "",
  phone: "",
  emails: "",
  pincode: "",
  total_solved_requests: "",
  image: "",
  usernamec:'',
  passwordc:'',
  isModalVisible:false,
};
export default initialState;
