const initialState = {
  isLoading: false,
  farnChiseUser: "",
  name: "",
  phone: "",
  email: "",
  billing_address: "",
  pincode: "",
  Total_drivers: "",
  razorpay_id:"",
  owner_id_proof_option:"",
  isaccount:false,
  isowner:false,
  isBasic:false,
  id_proof_file:null,
  pan_file:null

};
export default initialState;
