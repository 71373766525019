import styled from "styled-components";
export const CustomerDetail= styled.div`
margin-left: 15vw;
margin-top: 80px;

width: 82vw;
.title{
  display:flex;
   justify-content:space-between;
   margin-top:40px;
   gap:10px;
   .heading{
     display:flex;
  h1{
    font-size:14px;
    color:grey;
  }
  h2{
    font-size:14px;
    color:grey;
  }
}

.backarrow {
  padding-left: 30px;
  margin-bottom: 50px;
  justify-content:flex-start;
}
}
.main {
  margin-top: 19px;
  display: flex;
  justify-content: center;
  .heading {
    width: 95%;
    display: flex;
    align-items: center;
    h1{
      font-size:22px;
      font-weight:bold; 
      font-family: "pt_serifbold";
      letter-spacing: 1px;
    }
  }
}
.content {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  .border {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 80%;
    padding: 10px;
    .tables table.official {
      width: 100%;
      border: 1px solid #dddddd;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
      border-radius: 0px 4px 0px 0px;
    }
    tr.head-row-off {
      border-bottom: 1px solid #dddddd;
    }
    th.head-off {
      font-style: normal;
      font-weight: 6 00;
      font-size: 14px;
      line-height: 20px;
      height: 40px;

      text-align: left;
      font-family: FSMedium;
      font-style: normal;
      font-weight: 500px !important;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
      width: 300px;
      h1 {
        font-size: 16px;
        font-weight:bold;
        font-family: "dm_sansregular";
        display: flex;
        align-items: center;
        gap:5px;
      
      }
    }
    tr.body-row-off {
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #dddddd;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
      height: 60px;
      text-align: left;
      font-family: FSMedium;
      font-style: normal;
      font-weight: 500px !important;
      font-size: 16px;
      line-height: 150%;
      font-family: "dm_sansregular";
      color: #000000;

      h1{
          font-size:16px;
          font-family: "dm_sansregular";
      }
      h2{
        font-size:16px;
        font-family: "dm_sansregular";
    }
    button{
        background: #00a8cb;
        color: black;
        height: 30px;
        border-radius: 4px;
        border: none;
        width:80px;
        cursor: pointer;
        &:active{
          background-color:black;
          color:white;
        }
    }
    :hover {

      background: linear-gradient(
          0deg,
          rgba(96, 36, 57, 0.08),
          rgba(96, 36, 57, 0.08)
        ),
        #ffffff;
    }
  }
}
`