import { Getprofilefranchise } from "../../../../infrastructure/Admin-franchise/profile";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setProfile:
    (profile) =>
    ({ setState }) => {
      setState({ profile });
    },

    getuserprofile:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
     
      try {
        const res = await Getprofilefranchise();
 
        dispatch(actions.setProfile(res.data.data));
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
    
};
export default actions;
