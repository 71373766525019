import { GetRequestTaken } from "../../../../infrastructure/Caller/RequestTaken";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setRequesttaken:
    (requests) =>
    ({ setState }) => {
      setState({ requests });
    },
  GetRequestTakenres:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        let res = await GetRequestTaken();
        console.log(res,"base.....................................")
        dispatch(actions.setRequesttaken(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};

export default actions;
