import {
  Dashboards,
  Getuserlogin,
} from "../../../../infrastructure/Admin/Dashboard";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setUser:
    (user) =>
    ({ setState }) => {
      setState({ user });
    },
  setDashboard:
    (dashboard) =>
    ({ setState }) => {
      setState({ dashboard });
    },
  getAdmin:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await Getuserlogin();
        dispatch(actions.setUser(res.data.data));
        dispatch(actions.setisLoading(false));
      } catch (err) {
        if (err) {
          window.location.replace(`/report`);
        }
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  getDashboard:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await Dashboards();

        dispatch(actions.setDashboard(res.data));
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
