import React, { useEffect, useState } from "react";
import Loader from "../../Admin/Widget/common/Loader";
import { FranchiseDashboard } from "./store";
import { Dashboardfranchise } from "./style";
import { firebase } from "../../../Common/firebase";
import { MyFranchiseprofile } from "../Profile/store";
function Dashboardf() {
  const [{ isLoading, dashboard }, { getdashboard }] = FranchiseDashboard();

  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);
  useEffect(() => {
    getdashboard();
  }, [token]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Dashboardfranchise>
      <div className="section">
        <div className="item1">
          <h1>Drivers</h1>
          <h2>{dashboard.total_drivers}</h2>
        </div>
        <div className="item2">
          <h1>Unsettled Amounts</h1>
          <h2>{dashboard.unsettled_amount}</h2>
        </div>
        <div className="item3">
          <h1>Total Trips</h1>

          <h2>{dashboard.total_trips}</h2>
        </div>
        <div className="item4">
          <h1>Total income </h1>
          <h2>{dashboard.total_income}</h2>
        </div>
        <div className="item5" >
          <h1>Total Uncollected Amount From Driver </h1>
          <h2>{dashboard.total_uncollected_amount_from_driver}</h2>
        </div>
        <div className="item6">
          <h1>Total Driver Register Income </h1>
          <h2>{dashboard.total_driver_register_income}</h2>
        </div>
        <div className="item7">
          <h1>Total Commission Income </h1>
          <h2>{dashboard.total_commission_income}</h2>
        </div>
      </div>
    </Dashboardfranchise>
  );
}

export default Dashboardf;
