import styled from "styled-components";
export const DashboardContainer = styled.div`
  height: 91.5vh;
  width: 100%;
  margin-top: 80px;
  margin-left: 15vw;
  .section {
    padding-top: 50px;
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    padding-left: 50px;
    gap: 20px;

    .item1 {
      border-radius: 18px;
      height: 200px;
      width: 350px;
      background-color:#3385ff;
      @media (max-width: 1500px) {
        width: 280px;
        height: 170px;
      }

      h1 {
        padding-top: 20px;
        text-align: center;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "dm_sansregular";
        @media (max-width: 1500px) {
          font-size: 24px;
        }
        @media (max-width: 1400px) {
          font-size: 22px;
        }
      }
      h2 {
        padding-top: 40px;
        color: black;
        display: flex;
        letter-spacing: 1px;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 26px;
        font-family: "dm_sansregular";
        font-weight: bold;
        opacity: 0.7;
        @media (max-width: 1500px) {
          font-size: 24px;
        }
      }
    }
    .item2 {
      border-radius: 18px;
      height: 200px;
      width: 350px;
      background-color: yellow;
      @media (max-width: 1500px) {
        width: 280px;
        height: 170px;
      }

      h1 {
        padding-top: 20px;
        text-align: center;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "dm_sansregular";
        @media (max-width: 1500px) {
          font-size: 24px;
        }
        @media (max-width: 1400px) {
          font-size: 22px;
        }
      }
      h2 {
        padding-top: 40px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: "dm_sansregular";
        font-weight: bold;
        opacity: 0.7;
        font-size: 24px;
        @media (max-width: 1500px) {
          font-size: 24px;
        }
      }
    }
    .item3 {
      border-radius: 18px;
      height: 200px;
      width: 350px;
      background-color: #b0f542;
    
      @media (max-width: 1500px) {
        width: 280px;
        height: 170px;
      }

      h1 {
        padding-top: 20px;

        text-align: center;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "dm_sansregular";
        @media (max-width: 1500px) {
          font-size: 24px;
        }
        @media (max-width: 1400px) {
          font-size: 22px;
        }
      }
      h2 {
        padding-top: 40px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 26px;
        font-family: "dm_sansregular";
        font-weight: bold;
        opacity: 0.7;
        @media (max-width: 1500px) {
          font-size: 24px;
        }
      }
    }
    .item4 {
      border-radius: 18px;
      height: 200px;
      width: 350px;
   background-color: #42f56c;
      @media (max-width: 1500px) {
        width: 280px;
        height: 170px;
      }

      h1 {
        padding-top: 20px;
        text-align: center;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: 1px;
        font-family: "dm_sansregular";
        @media (max-width: 1500px) {
          font-size: 25px;
        }
        @media (max-width: 1400px) {
          font-size: 22px;
        }
      }
      h2 {
        padding-top: 60px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 26px;
        font-family: "dm_sansregular";
        font-weight: bold;
        opacity: 0.7;
        @media (max-width: 1500px) {
          font-size: 24px;
        }
      }
    }
  }
`;
