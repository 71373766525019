import { notification } from "antd";
import registerWithEmailsAndPassword, {
  Callerinfo,
} from "../../../../infrastructure/Admin/CallerRegistration";
import signInWithEmailAndPasswordCommon from "../../../../infrastructure/Admin/commonlogin";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setfirebase_email:
    (firebase_email) =>
    ({ setState }) => {
      setState({ firebase_email });
    },
  setPassword:
    (firebase_password) =>
    ({ setState }) => {
      setState({ firebase_password });
    },
  setconfirmpassword:
    (confirmpassword) =>
    ({ setState }) => {
      setState({ confirmpassword });
    },
  setname:
    (name) =>
    ({ setState }) => {
      setState({ name });
    },
  setaddress:
    (address) =>
    ({ setState }) => {
      setState({ address });
    },
  setphone:
    (phone) =>
    ({ setState }) => {
      setState({ phone });
    },
  setemails:
    (emails) =>
    ({ setState }) => {
      setState({ emails });
    },
  setpincode:
    (pincode) =>
    ({ setState }) => {
      setState({ pincode });
    },
  setimage:
    (image) =>
    ({ setState }) => {
      setState({ image });
    },
  setpincode:
    (pincode) =>
    ({ setState }) => {
      setState({ pincode });
    },
  settotal_solved_requests:
    (total_solved_requests) =>
    ({ setState }) => {
      setState({ total_solved_requests });
    },
  setIsModalVisible:
    (isModalVisible) =>
    ({ setState }) => {
      setState({ isModalVisible });
    },
  setusernamec:
    (usernamec) =>
    ({ setState }) => {
      setState({ usernamec });
    },
  setpasswordc:
    (passwordc) =>
    ({ setState }) => {
      setState({ passwordc });
    },

  SubmitloginC:
    (goNextpage) =>
    async ({ dispatch, getState }) => {
      const { firebase_email, firebase_password } = getState();
      if (firebase_email === "") {
        notification["warning"]({
          message: "warning",
          description: "Email feild is empty",
        });
        return;
      } else if (firebase_password === "") {
        notification["warning"]({
          message: "warning",
          description: "password feild is empty",
        });
        return;
      }
      try {
        dispatch(actions.setisLoading(true));
        await registerWithEmailsAndPassword(firebase_email, firebase_password);
        notification["success"]({
          message: "success",
          description: "User Created  successfully",
        });
        goNextpage();
      } catch (err) {
        let msg = err.message;
        if (err.message === msg) {
          notification["warning"]({
            message: "warning",
            description: "Email address already exists",
          });
        } else {
          notification["warning"]({
            message: "warning",
            description: "Something went Wrong!!!",
          });
        }
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  PostCallerlogin:
    (showModal) =>
    async ({ dispatch, getState }) => {
      const {
        firebase_email,
        firebase_password,
        name,
        address,
        pincode,
        phone,
        emails,
        image,
        total_solved_requests,
      } = getState();
      if (name === "") {
        notification["warning"]({
          message: "warning",
          description: "name feild is empty",
        });
        return;
      } else if (address === "") {
        notification["warning"]({
          message: "warning",
          description: "address feild is empty",
        });
        return;
      } else if (phone === "") {
        notification["warning"]({
          message: "warning",
          description: "phone feild is empty",
        });
        return;
      } else if (emails === "") {
        notification["warning"]({
          message: "warning",
          description: "email feild is empty",
        });
        return;
      } else if (pincode === "") {
        notification["warning"]({
          message: "warning",
          description: "pincode feild is empty",
        });
        return;
      } else if (image === "") {
        notification["warning"]({
          message: "warning",
          description: "image file is empty",
        });
        return;
      } else if (total_solved_requests === "") {
        notification["warning"]({
          message: "warning",
          description: "total solved request field is empty",
        });
        return;
      }
      try {
        dispatch(actions.setisLoading(true));
        var bodyformdat = new FormData();
        bodyformdat.append("name", name);
        bodyformdat.append("address", address);
        bodyformdat.append("phone", phone);
        bodyformdat.append("firebase_email", firebase_email);
        bodyformdat.append("firebase_password", firebase_password);
        bodyformdat.append("image", image);
        bodyformdat.append("emails", emails);
        bodyformdat.append("pincode", pincode);
        bodyformdat.append("total_solved_requests", total_solved_requests);
        await Callerinfo(bodyformdat);
        showModal();
        // notification["success"]({
        //   message: "success",
        //   description: " information Created  successfully",
        // });
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: " information Created unSuccessfully",
        });
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Relogin:
    (history) =>
    async ({ dispatch, getState }) => {
      const { usernamec, passwordc } = getState();
      try {
        dispatch(actions.setisLoading(true));
        await signInWithEmailAndPasswordCommon(usernamec, passwordc);

        history.push(`/admin/caller`);
        notification["success"]({
          message: "success",
          description: " information Created  successfully",
        });
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
