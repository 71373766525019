const initialState = {
  isLoading: false,
  firebase_email: "",
  firebase_password: "",
  confirmpass: "",
  name: "",
  phone: "",
  billing_address: "",
  pincode: "",
  account_number:"",
  ifsc_code: "",
  bank_name: "",
  upi: "",
  register_number: "",
  year_of_registration: "",
  body_color: "",
  max_load: "",
  checkbox: false,
  vtype: [],
  model: [],
  vehicle_model: "",
  image: null,
  passwordD: "",
  usernameD: "",
  isModalVisibleD: false,
  licence_number: "",
  licence_file: "",
  age: "",
  date_of_birth: "",
  referece_name: "",
  reference_relation: "",
  referece_number: "",
  image_left: null,
  image_right: null,
  image_back: null,
  noc_file: null,
  pcc_file: null,
  sex:"",
};
export default initialState;
