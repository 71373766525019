import React from "react";
import Styled from "styled-components";
import Header from "../../Header";
import Sidenav from "../../Sidebar";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../../Dashboard";
import Driver from "../../Drivers";
import DriverExp from "../../DriverExp";
import Customer from "../../Customer";
import CustomerTrip from "../../CustomerTrip";
import Trips from "../../Trips";
import Commision from "../../CommisionReceived";
import Franchises from "../../Franchises";
import FranchiseCreate from "../../FrachiseExp";
import FranchiseDetail from "../../FranchiseDetails";
import PaymentList from "../../Payment";
import Settlementslist from "../../SettlementDues";
import Caller from "../../Caller";
import SupportRequests from "../../SupportRequest";
import SettlementExp from "../../SettlementExp";
import { Redirect } from "react-router-dom";

import Fransettlement from "../../FranchiseSettlement";
import Driverpayments from "../../Driverpayments";
import Delibuoycharges from "../../Delibuoycharges";
import Adminregistration from "../../Admin Registration";
import Franchisereqlist from "../../FranchiseReqList";
import Createadmin from "../../Admin create";
import Driverreqlist from "../../DriverReqList";
import CallerRegistration from "../../CallerRegistration";
import Callerdetails from "../../CallerExp";


export default function AdminDashboard() {
  return (
    <div>
      <Header />
      <Container>
        <div>
          <Sidenav />
        </div>
        <Right>
          <Switch>
            <Route exact path="/admin/dashboard" component={Dashboard} />
            <Route exact path="/admin/driver" component={Driver} />
            <Route exact path="/admin/driverExp/:id" component={DriverExp} />
            <Route exact path="/admin/customer" component={Customer} />
            <Route
              exact
              path="/admin/customertrip/:id"
              component={CustomerTrip}
            />
            <Route exact path="/admin/trips" component={Trips} />
            <Route
              exact
              path="/admin/commisionreceived"
              component={Commision}
            />
            <Route exact path="/admin/franchises" component={Franchises} />
            <Route
              exact
              path="/admin/franchiseExp/"
              component={FranchiseCreate}
            />
            <Route
              exact
              path="/admin/franchiseDetail/:id"
              component={FranchiseDetail}
            />
            <Route exact path="/admin/payments" component={PaymentList} />

            <Route
              exact
              path="/admin/settlementDues"
              component={Settlementslist}
            />
            <Route exact path="/admin/caller" component={Caller} />
            <Route
              exact
              path="/admin/supportrequest"
              component={SupportRequests}
            />
            <Route
              exact
              path="/admin/settlementExp/:id"
              component={SettlementExp}
            />
            <Route
              exact
              path="/admin/franchissettlement/"
              component={Fransettlement}
            />
            <Route
              exact
              path="/admin/driverpayments/"
              component={Driverpayments}
            />
            <Route
              exact
              path="/admin/delibuoycharges/"
              component={Delibuoycharges}
            />
            <Route
              exact
              path="/admin/registration/"
              component={Adminregistration}
            />
            <Route
              exact
              path="/admin/franchisereqlist/"
              component={Franchisereqlist}
            />
            <Route exact path="/admin/createadmin/" component={Createadmin} />
            <Route
              exact
              path="/admin/driverreqlist/"
              component={Driverreqlist}
            />
             <Route exact path="/admin/callercreate/" component={CallerRegistration} />
             <Route exact path="/admin/callerdetails/:id" component={Callerdetails} />
          </Switch>
        </Right>
      </Container>
    </div>
  );
}

const Container = Styled.div`
display:flex;
`;
const Right = Styled.div`

height:calc(100vh - 80px);
display : flex;
justify-content : space-between;


`;
