const initialState = {
    isLoading: false,
    firebase_email: "",
    firebase_password: "",
    confirmpass:"",
    phone:"",
    email:"",
    image:"",
    name:"",
    designation:"",
    usernameA:"",
    passwordA:"",
    isModalVisibleAR:"",
}
export default initialState;