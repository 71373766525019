import styled from "styled-components";
export const Createdriver =styled.div`
width: 82vw;
margin-left: 15vw;
margin-top:80px;
.arrow {
    margin-top: 60px;
    padding-left: 50px;
  }
  .franchesis {
    display: flex;
    padding-left: 80px;
    margin-top: 50px;
    margin-bottom: 100px;
    align-items: center;
    gap: 15px;
    h1 {
      font-family: "Quicksand", sans-serif;
      font-size: 27px;
      font-weight: bold;
      width: 250px;
    }
    small {
      border: 1px solid #f0f0f0;
      height: 1px;
      width: 100%;
    }
  }
`

export const Created =styled.div`
margin-top: 50px;
  .form {
    .formcreate {
      h1 {
        font-size: 28px;
        font-family: "montserratregular";
        font-weight: bold;
      }
    }
    .input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      gap: 17px;
      input {
        border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid grey;
        height: 6vh;
        background: white;
        box-shadow: inset 0 -1px 0 #00a8cb;
        width: 500px;
      }
    }
    .buttonfran {
      margin-top: 20px;
      margin-bottom: 30px;
      button {
        width: 100px;
        height: 50px;
        border: none;
        background-color: #00a8cb;
        border-radius: 4px;
        &:active {
          background-color: black;
          color: white;
        }
      }
    }
  }
`
export const Created1 = styled.div`
  margin-top: 50px;
  .form {
    .formcreate {
      height: 2px;
      h1 {
        font-size: 28px;
        font-family: "montserratregular";
        font-weight: bold;
      }
    }
    .input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      gap: 17px;
      input {
        border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid grey;
        height: 6vh;
        background: white;
        box-shadow: inset 0 -1px 0 #00a8cb;
        width: 500px;
      }
    }
    .upload-btn-wrapper {
      position: relative;
     justify-content:center;
     align-items:center;
      overflow: hidden;
      display: inline-block;
      margin-top:15px;
      display:flex;
      width:100%;
    
     .imgsec{
       display:flex;
       justify-content:space-between;
       width:30%;
     }
    }
    
    .btn {
      border: 2px solid gray;
      color: gray;
      background-color: white;
   
      margin-left:13px;
      border-radius: 8px;
      font-size: 10px;
      font-weight: bold;
      justify-content:start;
      width: 200px;
      height: 40px;
      border-radius: 25px;
      background: #00a8cb;
      color:white;
      border:none;
    }
    
    .upload-btn-wrapper input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
  }
}
    .buttonfran {
      margin-top: 20px;
      margin-bottom: 30px;
      button {
        width: 100px;
        height: 50px;
        border: none;
        background-color: #00a8cb;
        border-radius: 4px;
        &:active {
          background-color: black;
          color: white;
        }
      }
    }
  }
`;
export const Created2 = styled.div`
  margin-top: 50px;
  .form {
    .formcreate {
      height: 2px;
      h1 {
        font-size: 28px;
        font-family: "montserratregular";
        font-weight: bold;
      }
    }
    .input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      gap: 17px;
      input {
        border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid grey;
        height: 6vh;
        background: white;
        box-shadow: inset 0 -1px 0 #00a8cb;
        width: 500px;
      }
      .terms{
        display:flex;
        align-items: center;
        gap:30px;
        .check{
          width:20px;
          height:20px;
        }
      }
      .file {
        opacity: 0;
        width: 0.1px;
        height: 0.1px;
        position: absolute;
      }
      .file-input label {
        display: block;
        position: relative;
        width: 150px;
        height: 40px;
        border-radius: 25px;
        background: #00a8cb;
        box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        transition: transform .2s ease-out;
      }
      
      
    }
     
    .btn {
      border: 2px solid gray;
      color: gray;
      background-color: white;
      width:130px;
      height:30px;
      margin-left:13px;
      border-radius: 8px;
      font-size: 10px;
      font-weight: bold;
      justify-content:start;
    }
    
    .upload-btn-wrapper input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
  }
}
    .buttonfran {
      margin-top: 20px;
      margin-bottom: 50px;
      button {
        width: 100px;
        height: 50px;
        border: none;
        background-color: #00a8cb;
        border-radius: 4px;
        &:active {
          background-color: black;
          color: white;
        }
      }
    }
  }

`;