import { get } from "../common/remote/base_api";

export const GetCustomerdetails = (data) => {
  return get(`admin/get-customer-list/`, data);
};
export const GetCustomerdetailsbyName = (data) => {
  return get(`admin/get-customer-list/`, { name: data });
};
export const GetCustomerdetailsbyPhone = (data) => {
  return get(`admin/get-customer-list/`, { phone: data });
};
export const GetCustomerdetailsbyId = (data) => {
  return get(`admin/get-customer-list/`, { id: data });
};

export const getBlockFranchise = (id) =>{
  return get (`admin/block-customer/?customer_id=${id}/`)
}

export const getUnBlockFranchise = (id) =>{
  return get (`admin/unblock-customer/?customer_id=${id}/`)
}