import {
  GetBlockDriversres,
  GetDriversres,
  GetUnblockDriversres,
} from "../../../../infrastructure/Admin/Driver";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setDriver:
    (driver) =>
    ({ setState }) => {
      setState({ driver });
    },
  setdrivername:
    (drivername) =>
    ({ setState }) => {
      setState({ drivername });
    },
  setdriverphone:
    (driverphone) =>
    ({ setState }) => {
      setState({ driverphone });
    },
  setdriveremail:
    (driveremail) =>
    ({ setState }) => {
      setState({ driveremail });
    },
  setdriverid:
    (driverid) =>
    ({ setState }) => {
      setState({ driverid });
    },
  getDrivers:
    () =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      const { drivername, driverphone, driveremail, driverid } = getState();
      try {
        const res = await GetDriversres(
          drivername,
          driverphone,
          driveremail,
          driverid
        );
        console.log(res.status,"status...............................");
  
        dispatch(actions.setDriver(res.data.data));
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  getblockDrivers:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));

      try {
        await GetBlockDriversres(id);
        dispatch(actions.getDrivers());
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
    getunblockDrivers:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));

      try {
        await GetUnblockDriversres(id);
        dispatch(actions.getDrivers());
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};

export default actions;
