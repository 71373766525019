import {
  GetSupportrequset,
  Postsolverequest,
  Posttakerequest,
} from "../../../../infrastructure/Caller/SupportRequest";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setsupportrequest:
    (supportrequest) =>
    ({ setState }) => {
      setState({ supportrequest });
    },
  getsupportrequest:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetSupportrequset();
        console.log(res.data.data, "resdata");
        dispatch(actions.setsupportrequest(res.data.data));
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Postrequest:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));

      var bodyFormdata = new FormData();
      bodyFormdata.append("request_id", id);
      try {
        await Posttakerequest(bodyFormdata);

        dispatch(actions.getsupportrequest());
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
    Postrequestforsolved:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));

      var bodyFormdata = new FormData();
      bodyFormdata.append("request_id", id);
      try {
        await Postsolverequest(bodyFormdata);

        dispatch(actions.getsupportrequest());
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
