import React from "react";
import { About } from "./style";
function Aboutus() {
  return (
    <About>

        <div className="pagesection">
          <h1>About Us</h1>
          <div className="paragraph">
          <p>
            Delibuoy is a one-stop delivery solution to individuals and
            businesses that takes care of point-to-point pickup and delivery
            locally. We present a wide range of goods vehicles right from
            2-wheelers to large trucks and everything in-between to pick up and
            deliver everything from roses and cakes to inventory cartons and
            more. Brought to you by Quadauq Technosys Pvt. Ltd, Delibuoy is a
            first-of-its-kind in Kerala to provide a scalable and affordable
            solution to bring fleet owners and their customers closer. Delibuoy
            is a trusted point-to-point pick-up and delivery partner for retail
            stores, restaurants, small businesses and individuals. Delibuoy
            opens up the possibilities to grow your small business as extended
            means to reaching out to your customers where they are. For
            individuals, we’re the most beloved pick-up and delivery fairy
            always at service.
          </p>
          </div>
         
        </div>
     
    </About>
  );
}

export default Aboutus;
