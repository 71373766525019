import { GetCallers } from "../../../../infrastructure/Admin/Caller";

const actions = {
    setisLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setCaller:
      (Caller) =>
      ({ setState }) => {
        setState({ Caller });
      },
      GetCaller:
      () =>
        async ({ dispatch}) => {
         
          dispatch(actions.setisLoading(true));
          try {
            let res = await GetCallers();
            dispatch(actions.setCaller(res.data.data));
          } catch (err) {
          } finally {
            dispatch(actions.setisLoading(false));
          }
        },
    };
    export default actions;