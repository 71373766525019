import React, { useEffect, useState } from "react";
import { Main } from "./style";
import { useHistory } from "react-router-dom";
import { MyFranchiselogin } from "./store";
function Franchiselogin() {
  let history = useHistory();
  const [
    { UsernameF, PasswordF },
    { setUsernameF, setPasswordF, SubmitloginF },
  ] = MyFranchiselogin();

  // useEffect(() => {
  //   window.addEventListener("keypress", (e) => {
  //     if (e.key === "Enter") {
  //       Submitlogin(history);
  //     }
  //   });
  // }, []);

  localStorage.setItem("UsernameFranchise", UsernameF);
  localStorage.setItem("PasswordFranchise", PasswordF);
  function handleKeyPress(e) {
    if (e.key === "Enter") {
      if (UsernameF !== "" && PasswordF !== "") {
        SubmitloginF(history);
      }
    }
  }
  const handleChange = (e) => {
    e.preventDefault();
  };

  return (
    <Main>
      <div className="box">
        <div className="heading">
          <h1>Delibuoy</h1>
        </div>
        <div className="inpt">
          <p>Login to Franchise</p>
          <input
            className="small"
            type="username"
            value={UsernameF}
            placeholder="enter your username"
            onKeyPress={(e) => handleKeyPress(e)}
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            onChange={(event) => {
              setUsernameF(event.target.value);
            }}
          />
          <input
            classNmae="small"
            value={PasswordF}
            type="password"
            placeholder="enter your password"
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={(event) => {
              setPasswordF(event.target.value);
            }}
          />
        </div>

        <div className="button">
          <button onClick={() => SubmitloginF(history)}> Login</button>
        </div>
      </div>
    </Main>
  );
}

export default Franchiselogin;
