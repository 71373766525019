import { GetFranchises } from "../../../../infrastructure/Admin/Franchises";

const actions = {
  setisLoading:
    (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
  setfranUser:
    (franUser) =>
      ({ setState }) => {
        setState({ franUser });
      },
  setfranname:
    (franUsername) =>
      ({ setState }) => {
        setState({ franUsername });
      },
  setfranphone:
    (franUserphone) =>
      ({ setState }) => {
        setState({ franUserphone });
      },
  setfranId:
    (franUserid) =>
      ({ setState }) => {
        setState({ franUserid });
      },
  GetFranchise:
    () =>
      async ({ setState, dispatch, getState }) => {
        const { franUsername, franUserphone, franUserid } = getState();
        dispatch(actions.setisLoading(true));
        try {
          let res = await GetFranchises(franUsername, franUserphone, franUserid);
          dispatch(actions.setfranUser(res.data.data));
        } catch (err) {
        } finally {
          dispatch(actions.setisLoading(false));
        }
      },
};

export default actions;
