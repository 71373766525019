import { GetTripsapi } from "../../../../infrastructure/Admin/Trips";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  settrips:
    (trips) =>
    ({ setState }) => {
      setState({ trips });
    },
  gettrips:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetTripsapi();
        console.log(res, "resdata");
        dispatch(actions.settrips(res.data.data));
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
