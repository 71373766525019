import {
  FranchiseDetailbyid,
  FranchiseUpdateaccountid,
  FranchiseUpdatebyid,
  FranchiseUpdateRazerpay,
  FranchiseUpdateregisterid,
} from "../../../../infrastructure/Admin/FranchiseDetail";
import { notification } from "antd";
const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setFranchise:
    (farnChiseUser) =>
    ({ setState }) => {
      setState({ farnChiseUser });
    },
  setFranname:
    (name) =>
    ({ setState }) => {
      setState({ name });
    },
  setFranphone:
    (phone) =>
    ({ setState }) => {
      setState({ phone });
    },
  setFranEmail:
    (email) =>
    ({ setState }) => {
      setState({ email });
    },
  setFranBilling:
    (billingads) =>
    ({ setState }) => {
      setState({ billingads });
    },
  setFranBillings:
    (billing_address) =>
    ({ setState }) => {
      setState({ billing_address });
    },
  setFranpicode:
    (pincode) =>
    ({ setState }) => {
      setState({ pincode });
    },
  setFranlongitude:
    (longitude) =>
    ({ setState }) => {
      setState({ longitude });
    },
  setFranlatitude:
    (latitude) =>
    ({ setState }) => {
      setState({ latitude });
    },

  setFranaccount_number:
    (account_number) =>
    ({ setState }) => {
      setState({ account_number });
    },
  setFranifsc_code:
    (ifsc_code) =>
    ({ setState }) => {
      setState({ ifsc_code });
    },
  setFranbank_name:
    (bank_name) =>
    ({ setState }) => {
      setState({ bank_name });
    },
  setFranbank_branch:
    (bank_branch) =>
    ({ setState }) => {
      setState({ bank_branch });
    },
  setFranowner_name:
    (owner_name) =>
    ({ setState }) => {
      setState({ owner_name });
    },
  setFranowner_id_proof:
    (owner_id_proof) =>
    ({ setState }) => {
      setState({ owner_id_proof });
    },
  setFranid_proof_number:
    (id_proof_number) =>
    ({ setState }) => {
      setState({ id_proof_number });
    },
  setFranpan_number:
    (pan_number) =>
    ({ setState }) => {
      setState({ pan_number });
    },
  setFranid_proof_file:
    (id_proof_file) =>
    ({ setState }) => {
      setState({ id_proof_file });
    },
  setFranpan_file:
    (pan_file) =>
    ({ setState }) => {
      setState({ pan_file });
    },
  setrazorpayid:
    (razorpay_id) =>
    ({ setState }) => {
      setState({ razorpay_id });
    },
  setowner_id_proof:
    (owner_id_proof_option) =>
    ({ setState }) => {
      setState({ owner_id_proof_option });
    },
  setIsaccount:
    (isaccount) =>
    ({ setState }) => {
      setState({ isaccount });
    },
  setIsBasic:
    (isBasic) =>
    ({ setState }) => {
      setState({ isBasic });
    },
  setIsowner:
    (isowner) =>
    ({ setState }) => {
      setState({ isowner });
    },
  GetFranchise:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await FranchiseDetailbyid(id);
        dispatch(actions.setFranchise(res.data.data));
        dispatch(actions.setFranname(res.data.data.name));
        dispatch(actions.setFranphone(res.data.data.phone));
        dispatch(actions.setFranpicode(res.data.data.pincode));
        dispatch(actions.setFranBilling(res.data.data.billing_address));
        dispatch(actions.setFranlatitude(res.data.data.latitude));
        dispatch(actions.setFranlongitude(res.data.data.longitude));
        dispatch(
          actions.setFranaccount_number(
            res.data.data.account_information?.account_number
          )
        );
        dispatch(
          actions.setFranifsc_code(res.data.data.account_information?.ifsc_code)
        );
        dispatch(
          actions.setFranbank_name(res.data.data.account_information?.bank_name)
        );
        dispatch(
          actions.setFranbank_branch(
            res.data.data.account_information?.bank_branch
          )
        );
        dispatch(
          actions.setFranowner_name(res.data.data.owner_information?.owner_name)
        );
        dispatch(
          actions.setFranowner_id_proof(
            res.data.data.owner_information?.owner_id_proof
          )
        );
        dispatch(
          actions.setFranid_proof_number(
            res.data.data.owner_information?.id_proof_number
          )
        );
        dispatch(
          actions.setFranpan_number(res.data.data.owner_information?.pan_number)
        );
        // dispatch(
        //   actions.setFranid_proof_file(
        //     res.data.data.owner_information?.id_proof_file
        //   )
        // );

        // dispatch(
        //   actions.setFranpan_file(res.data.data.owner_information?.pan_file)
        // );
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },

  Updatebasic:
    (id) =>
    async ({ dispatch, getState }) => {
      const {
        name,
        phone,
        pincode,
        billingads,
        billing_address,
        longitude,
        latitude,
      } = getState();

      if (name === "") {
        notification["warning"]({
          message: "warning",
          description: "Name Field is empty",
        });
        return;
      } else if (phone === "") {
        notification["warning"]({
          message: "warning",
          description: "Phone Number Field is empty",
        });
        return;
      } else if (pincode === "") {
        notification["warning"]({
          message: "warning",
          description: "Pincode Field is empty",
        });
        return;
      } else if (billingads === "") {
        notification["warning"]({
          message: "warning",
          description: "Billing Address Field is empty",
        });
        return;
      }
      dispatch(actions.setisLoading(true));
      var formData = new FormData();

      formData.append("name", name);
      formData.append("phone", phone);
      formData.append("pincode", pincode);
      formData.append(
        "billing_address",
        billingads === "" ? billing_address : billingads
      );
      formData.append("longitude", longitude);
      formData.append("latitude", latitude);
      try {
        await FranchiseUpdatebyid(id, formData);
        notification["success"]({
          message: "success",
          description: "Basic information updated successfully",
        });
        dispatch(actions.setIsBasic(false));
        dispatch(actions.GetFranchise());
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  putAccountsinfo:
    (id) =>
    async ({ dispatch, getState }) => {
      const { account_number, ifsc_code, bank_name, bank_branch, isaccount } =
        getState();
      dispatch(actions.setisLoading(true));
      if (account_number === "") {
        notification["warning"]({
          message: "warning",
          description: "Account Number Field is empty",
        });
        return;
      } else if (ifsc_code === "") {
        notification["warning"]({
          message: "warning",
          description: "IFSC Field is empty",
        });
        return;
      } else if (bank_name === "") {
        notification["warning"]({
          message: "warning",
          description: "Bank Name field is empty",
        });
        return;
      } else if (bank_branch === "") {
        notification["warning"]({
          message: "warning",
          description: "Bank Branch field is empty",
        });
        return;
      }
      var formData = new FormData();
      formData.append("account_number", account_number);
      formData.append("ifsc_code", ifsc_code);
      formData.append("bank_name", bank_name);
      formData.append("bank_branch", bank_branch);

      try {
        await FranchiseUpdateaccountid(id, formData);
        notification["success"]({
          message: "success",
          description: "Account information updated successfully",
        });
        dispatch(actions.setIsaccount(false));
        dispatch(actions.GetFranchise());
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  putOwnerinfo:
    (id) =>
    async ({ dispatch, getState }) => {
      const {
        owner_name,
        owner_id_proof,
        id_proof_number,
        pan_number,
        id_proof_file,
        pan_file,
        owner_id_proof_option,
      } = getState();

      if (owner_name === "") {
        notification["warning"]({
          message: "warning",
          description: "Owner Name field is empty",
        });
        return;
      }
      if (owner_id_proof === "") {
        notification["warning"]({
          message: "warning",
          description: "Owner Id Proof field is empty",
        });
        return;
      } else if (id_proof_number === "") {
        notification["warning"]({
          message: "warning",
          description: "Id Proof Number field is empty",
        });
        return;
      } else if (pan_number === "") {
        notification["warning"]({
          message: "warning",
          description: "Pan Number field is empty",
        });
        return;
      }
      dispatch(actions.setisLoading(true));
      var formData = new FormData();
      formData.append("owner_name", owner_name);
      formData.append(
        "owner_id_proof",
        owner_id_proof_option === "" ? owner_id_proof : owner_id_proof_option
      );
      formData.append("id_proof_number", id_proof_number);
      formData.append("pan_number", pan_number);
      if (id_proof_file !== null) {
        formData.append("id_proof_file", id_proof_file);
      }
      if (pan_file !== null) {
        formData.append("pan_file", pan_file);
      }

      try {
        await FranchiseUpdateregisterid(id, formData);
        notification["success"]({
          message: "success",
          description: "Owner information updated successfully",
        });
        dispatch(actions.setIsowner(false));
        dispatch(actions.GetFranchise());
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  putRazorpayupdate:
    (idd, history) =>
    async ({ dispatch, getState }) => {
      const { razorpay_id } = getState();
      dispatch(actions.setisLoading(true));
      var formData = new FormData();
      formData.append("razorpay_id", razorpay_id);
      try {
        await FranchiseUpdateRazerpay(idd, formData);
        notification["success"]({
          message: "success",
          description: "Franchise Approved successfully",
        });
        history.push(`/admin/franchises`);
        dispatch(actions.GetFranchise());
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: "Franchise Approved Unsuccessfully",
        });
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};

export default actions;
