import React from "react";
import appimage from "../../../../../Images/delibuoytruck.gif";
import { HomeBackground } from "./style";
function AppStore() {
  return (
    <HomeBackground>
      <div className="top">
        <div className="left">
          <img src={appimage}></img>
        </div>
        <div className="right">
          <h1>Delibuoy Service</h1>
          <p>
            Delibuoy is for everyone who is in need of pick-up and delivery of
            parcels locally. We strive to serve individuals and business owners,
            doctors and chemists, home-makers and home-bakers looking for
            reliable and timely pick-up and delivery of essentials. Our online
            tracking system helps you stay updated. To use our service, install
            our app, register and get going. We’ll make sure your things reach
            their destination safe and sound, on time.
          </p>
        </div>
      </div>
    </HomeBackground>
  );
}

export default AppStore;
