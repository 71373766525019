import { get,put } from "../common/remote/base_api";

export const Delibuoycharges = (data) => {
  return get(`admin/get-delibuoy-charges/`, data);
};

export const Delibuoychargesupdate = (data) => {
    return put(`admin/update-delibuoy-charges/`, data);
  };
  
