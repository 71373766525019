import React, { useEffect, useState } from "react";
import { Profilecaller } from "./style";
import { firebase } from "../../../Common/firebase";
import { MyCallerprofile } from "./store";
import Moment from "react-moment";
import Loader from "../../Caller/Widget/Common/Loader";
function Callerprofile() {
  const [token, setToken] = useState("");
  const [{ isLoading, profiles }, { getprofiles }] = MyCallerprofile();
 
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  localStorage.setItem("token", token);
  
  useEffect(() => {
    getprofiles();
  }, [token]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Profilecaller>
      <div className="profile">
        <img src={profiles.image} alt="img"></img>
      </div>

      <div className="main">
        <div className="section">
          <div className="small">
            <div className="item">
              <div className="name">
                <h1>Name </h1>
                <span>:</span>
                <h2> {profiles.name} </h2>
              </div>
              <div className="name">
                <h1>Address</h1>
                <span>:</span>

                <h2>{profiles.address}</h2>
              </div>
              <div className="name">
                <h1>Phone </h1>
                <span>:</span>
                <h2>{profiles.phone}</h2>
              </div>
              <div className="name">
                <h1>Pincode</h1>
                <span>:</span>
                <h2>{profiles.pincode}</h2>
              </div>
              <div className="name">
                <h1>Email</h1>
                <span>:</span>
                <h2>{profiles.email}</h2>
              </div>
              <div className="name">
                <h1>Date created</h1>
                <span>:</span>
                <h2>
                  <Moment format="YYYY/MM/DD hh:mm">
                    {profiles.date_created}
                  </Moment>
                </h2>
              </div>
              <div className="name">
                <h1>Blocked</h1>
                <span>:</span>
                <h2>{profiles.is_blocked === true ? "yes" : "No"}</h2>
              </div>
              <div className="name">
                <h1>Fcm token</h1>
                <span>:</span>
                <h2>{profiles.fcm_token}</h2>
              </div>
              <div className="name">
                <h1>User</h1>
                <span>:</span>
                <h2>{profiles.user}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Profilecaller>
  );
}

export default Callerprofile;
