import React, { useState } from "react";
import BackArrow from "../Widget/common/BackArrow";
import { Adminlogin } from "./store";
import { Create, Login, Information } from "./style";
import { useHistory } from "react-router-dom";
import { notification } from "antd";

import { Modal } from "antd";
function Createadmin() {
  let history = useHistory();
  const [
    {
      firebase_email,
      firebase_password,
      confirmpass,
      phone,
      email,
      image,
      name,
      designation,
      usernameA,
      passwordA,
      isModalVisibleAR,
    },
    {
      setUsername,
      setPassword,
      setConfirmpass,
      Submitlogin,
      setphone,
      setemail,
      setimage,
      setname,
      setpasswordA,
      setusernameA,
      setdesignation,
      PostAdminlogin,
      setIsModalVisibleAR,
      Relogin,
      
    },
  ] = Adminlogin();
  let myuser = localStorage.getItem('usernameLogin');
  let myuserpass = localStorage.getItem('passwordLogin');
  let username = myuser;
  let password = myuserpass;
  localStorage.setItem("usernameadminr", username);
  localStorage.setItem("passwordadminr", password);
  let usernameAdmin = localStorage.getItem("usernameadminr");
  let passwordAdmin = localStorage.getItem("passwordadminr");
  setpasswordA(passwordAdmin)
  setusernameA(usernameAdmin)
  console.log(usernameA,"usernameAusernameA")
  const [page, setpage] = useState(1);
  function goNextpage() {
    setpage((page) => page + 1);
  }
  return (
    <Create>
      <div className="arrow">
        <BackArrow />
      </div>
      <div className="franchesis">
        <h1>Register Admin</h1>
        <small></small>
      </div>
      <div className="createf">
        <div className="maindiv">
          {page === 1 && (
            <Adminform1
              setpage={setpage}
              page={page}
              goNextpage={goNextpage}
              setUsername={setUsername}
              setPassword={setPassword}
              firebase_email={firebase_email}
              firebase_password={firebase_password}
              setConfirmpass={setConfirmpass}
              confirmpass={confirmpass}
              Submitlogin={Submitlogin}
            />
          )}
          {page === 2 && (
            <Adminform2
              setpage={setpage}
              page={page}
              goNextpage={goNextpage}
              firebase_email={firebase_email}
              firebase_password={firebase_password}
              phone={phone}
              email={email}
              image={image}
              setphone={setphone}
              setemail={setemail}
              setimage={setimage}
              PostAdminlogin={PostAdminlogin}
              setname={setname}
              name={name}
              designation={designation}
              setdesignation={setdesignation}
              history={history}
              usernameA={usernameA}
              passwordA={passwordA}
              setIsModalVisibleAR={setIsModalVisibleAR}
              isModalVisibleAR={isModalVisibleAR}
              Relogin={ Relogin}
            />
          )}
        </div>
      </div>
    </Create>
  );
}

export default Createadmin;

//form 1 ..............................

function Adminform1({
  setpage,
  page,
  goNextpage,
  setPassword,
  setUsername,
  firebase_email,
  firebase_password,
  Submitlogin,
  setConfirmpass,
  confirmpass,
}) {
  const submitbuttonA = () => {
    let pswd = firebase_password;
    let cpswd = confirmpass;
    let message = document.getElementById("message");
    if (pswd.length !== 0) {
      if (pswd == cpswd) {
        message.textContent = "Password Match";
        message.style.color = "green";
        Submitlogin(goNextpage);
      } else {
        message.textContent = "Password doesnt Match!!!";
        message.style.color = "red";
      }
    }
  };

  return (
    <Login>
      <div className="form">
        <div className="formcreate">
          <h1>Create an Account</h1>
        </div>
        <div className="input">
          <input
            placeholder="Enter your email"
            onChange={(e) => setUsername(e.target.value)}
            value={firebase_email}
          ></input>
          <input
            type="password"
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
            value={firebase_password}
          ></input>
          <input
            type="password"
            placeholder="Confirm your password"
            onChange={(e) => setConfirmpass(e.target.value)}
            value={confirmpass}
          ></input>
        </div>
        <small id="message" style={{ color: "black" }}></small>
        <div className="buttonfran">
          <button onClick={() => submitbuttonA()}>Next</button>
        </div>
      </div>
    </Login>
  );
}
//form 2..............................................................

function Adminform2({
  firebase_email,
  firebase_password,
  phone,
  setphone,
  setimage,
  setemail,
  email,
  name,
  setname,
  designation,
  setdesignation,
  image,
  history,
  PostAdminlogin,
  usernameA,
  passwordA,
  setIsModalVisibleAR,
  isModalVisibleAR,
  Relogin,
}) {
 
  const [preview, setpreview] = useState(null);

  const uploadFile = (e) => {
    setimage(e.target.files[0]);
    setpreview(URL.createObjectURL(e.target.files[0]));
  };
  let imgPreview;
  if (preview) {
    imgPreview = (
      <img src={preview} alt="" style={{ width: "100%", height: "100%" }} />
    );
  }
  const showModalAR = () => {
    setIsModalVisibleAR(true);
  };

  const handleOkAR = () => {
    Relogin(history);
    setIsModalVisibleAR(false);
  };

  const handleCancelAR = () => {
    setIsModalVisibleAR(false);
  };

  const submitformA = () => {
    PostAdminlogin(showModalAR);

  };
  return (
    <Information>
      <div className="formcreate">
        <h1>Enter Informations</h1>
      </div>
      <div className="input">
        <input
          placeholder="name"
          onChange={(e) => setname(e.target.value)}
          value={name}
        ></input>
        {/* <input
          placeholder="designation"
          onChange={(e) => setdesignation(e.target.value)}
          value={designation}
        ></input> */}
        <select
          onChange={(e) => setdesignation(e.target.value)}
          style={{
            width: "40%",
            height: 40,
            background: "#F8F9FC",
            border: "1px solid gray",
            paddingLeft: 10,
            cursor: "pointer",
          }}
        >
          <option disabled selected>
            Select designatiion
          </option>
          <option value="Founder">Founder</option>
          <option value="Co-Founder">Co-Founder</option>
          <option value="Managing-Director">Managing-Director</option>
          <option value="HR">HR</option>
        </select>
        <input
          placeholder=" email"
          onChange={(e) => setemail(e.target.value)}
          value={email}
        ></input>
        <input
          maxlength="13"
          value={phone}
          placeholder="Phone"
          onChange={(e) => setphone(e.target.value)}
        ></input>
        <div className='pic' style={{display:"flex",justifyContent:"space-between",width:"40%"}}>
        <input
          type="file"
          id="actual-btn"
          name="filename"
          onChange={uploadFile }
          hidden
        />
        <label for="actual-btn">Upload Image</label>
        <div className='img' style={{width:"60px",height:"40px"}}>{imgPreview}</div>
        </div>
        <div className="buttonfran">
          <button onClick={() => submitformA()}>Finish</button>
        </div>
        <Modal
          title="Confirm your login"
          visible={isModalVisibleAR}
          onOk={handleOkAR}
          onCancel={handleCancelAR}
        >
          <div style={{ display: "flex", gap: 20 }}>
            <input value={usernameA}></input>
            <input value={passwordA} type="password"></input>
          </div>
        </Modal>
      </div>
    </Information>
  );
}
