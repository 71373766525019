import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Divider } from "antd";
import Loader from "../Widget/common/Loader";
import { CustomerDiv } from "./style";
import { GetCustomerlist } from "./store";
import{LeftOutlined}  from "@ant-design/icons";
import { firebase } from "../../../Common/firebase";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { Empty, Input } from "antd";
function Customer() {
  let history = useHistory();
  const [
    { isLoading, customer, Custrname, Custphone, Custid },
    {
      setcustrname,
      setcustphone,
      getCustomers,
      setcustid,
      getCustomersName,
      getCustomersPhone,
      getCustomersid,
      getblockFranchise,
      getunblockfranchise,
    },
  ] = GetCustomerlist();
 

  const Customertrip = (id) => {
    history.push(`/admin/customertrip/${id}`);
  };

  ///search................................................................................................
const main =()=>{

}
  const [hideP, sethideP] = useState(true);
  const hiddenP = (p) => {
    sethideP(!hideP);

  };
  const [hideN, sethideN] = useState(true);
  const hiddenN = (i) => {
    sethideN(!hideN);
  };

  const [hideI, sethideI] = useState(true);
  const hiddenI = () => {
    sethideI(!hideI);
  };

  //search funtion................................................................................................
  const Searchrequestname = (e) => {
    setcustrname(e.target.value);
    getCustomersName();
  };

  const Searchrequestphone = (e) => {
    setcustphone(e.target.value);
    getCustomersPhone();
  };

  const Searchrequestid = (e) => {
    setcustid(e.target.value);
    getCustomersid();
  };
  const [token, setToken] = useState("");

  const blockfranchise = (id) => {
    getblockFranchise(id);
  };
  const unblockfranchise = (id) =>{
    getunblockfranchise(id);
  }
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);
  //useEffect............................................
  useEffect(() => {
    getCustomers();
  }, [hideN,hideP,hideI,token]);
  //........................................................
  if (isLoading) {
    return <Loader />;
  }
  return (
    <CustomerDiv>
        <div className="title">
          <h1>Home</h1>
          <LeftOutlined style={{color:'grey'}}/>
          <h2>Customer</h2>
        </div>
      <div className="driver">
        <div className="heading">
          <h1>Customers</h1>
          <Divider orientation="left" />
        </div>
      </div>

      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No:</h1>
                </th>
                <th className="head-off">
                  <h1>
                    ID
                    {hideI ? (
                      <div>
                        <SearchOutlined style={{color:'grey'}} onClick={() => hiddenI()} />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestid(e)}
                          suffix={<CloseOutlined style={{color:'grey'}} onClick={() => hiddenI()} />}
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Name
                    {hideN ? (
                      <div>
                        <SearchOutlined style={{color:'grey'}} onClick={() => hiddenN()} />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestname(e)}
                          suffix={<CloseOutlined style={{color:'grey'}} onClick={() => hiddenN()} />}
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Phone{" "}
                    {hideP ? (
                      <div>
                        <SearchOutlined style={{color:'grey'}} onClick={() => hiddenP()} />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestphone(e)}
                          suffix={<CloseOutlined style={{color:'grey'}} onClick={() => hiddenP()} />}
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Email</h1>
                </th>
                <th className="head-off">
                  <h1>Block</h1>
                </th>
                <th className="head-off"></th>
              </tr>
            </thead>

            <tbody>
              {customer?.map((res, i) => (
                <tr className="body-row-off">
                  <td>{i + 1} </td>
                  <td>{res.id}</td>
                  <td>
                    <h1>{res.name}</h1>
                  </td>
                  <td>
                    <h1>{res.phone}</h1>
                  </td>
                  <td>
                    <h2>{res.email}</h2>
                  </td>
                  <td>
                    {res.is_blocked===false?
                  <button
                      style={{
                        width: 80,
                        height: 30,
                        borderRadius: 30,
                        border: "none",
                        fontFamily: "dm_sansregular",
                        fontSize: 14,
                        backgroundColor: "#f5f5f5",
                        fontWeight:"bold",
                        letterSpacing:".5px",
                        color: "red",
                      }}
                      onClick={() =>blockfranchise(res.id)}
                    > 
                    Block</button>:
                     <button
                      style={{
                        width: 80,
                        height: 30,
                        borderRadius: 30,
                        border: "none",
                        fontFamily: "dm_sansregular",
                        fontSize: 14,
                        backgroundColor: "#f5f5f5",
                        fontWeight:"bold",
                        letterSpacing:".5px",
                        color: "blue",
                      }}
                      onClick={() => unblockfranchise(res.id)}
                    > Unblock</button>}

                  </td>
                  <td>
                    <button onClick={() => Customertrip(res.id)}>Trip</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </CustomerDiv>
  );
}

export default Customer;
