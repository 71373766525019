import React, { useEffect, useState } from "react";
import { Commisionfranchise } from "./style";
import { Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { commisionsRecived } from "./store";
import { firebase } from "../../../Common/firebase";
function Commisionf() {
  const [{ isLoading, commision }, { GetCommisionres }] = commisionsRecived();

  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  localStorage.setItem("token", token);
  
  useEffect(() => {
    GetCommisionres();
  }, [token]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  return (
    <Commisionfranchise>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Commision</h2>
      </div>
      <div className="commision">
        <div className="heading">
          <h1>Commision</h1>
          <Divider orientation="left" />
        </div>
      </div>

      <div className="content">
        <div className="border" style={{ backgroundColor: "white"}}>
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No</h1>
                </th>
                <th className="head-off">
                  <h1>Id</h1>
                </th>

                <th className="head-off">
                  <h1>amount</h1>
                </th>
                {/* <th className="head-off">
                  <h1>Date created</h1>
                </th> */}
                <th className="head-off">
                  <h1>Franchise</h1>
                </th>
                <th className="head-off">
                  <h1>Trip</h1>
                </th>
              </tr>
            </thead>

            <tbody>
              {commision?.map((res, i) => (
                <tr className="body-row-off">
                  <td>{i + 1}</td>
                  <td>{res.id}</td>
                  <td>{res.amount}</td>
                  {/* <td>
                 {res.date_created}
                </td> */}
                  <td>{res.franchise}</td>
                  <td>{res.trip}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Commisionfranchise>
  );
}

export default Commisionf;
