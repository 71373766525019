import { get } from "../common/remote/base_api";
export const GetDriverpayments = (
  razorpay_payment_id,
  date_created,
  driver_name,
  driver_id,
  refunded
) => {
  return get(`admin/get-driver-payments/`, {
    razorpay_payment_id: razorpay_payment_id,
    date_created: date_created,
    driver_name: driver_name,
    driver_id: driver_id,
    refunded: refunded,
  });
};
