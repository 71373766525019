import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const settlements = createStore({
  name: "Settlement",
  initialState,
  actions,
});

export const Getsettlementdetails = createHook(settlements, {
  selector: (state) => state,
});