import styled from "styled-components";
export const CallerExp =styled.div`
width: 82vw;
margin-left:15vw;
margin-top:80px;
.title{
    display:flex;
     justify-content:flex-end;
     margin-right:200px;
     margin-top:40px;
     gap:10px;
    h1{
      font-size:14px;
      color:grey;
    }
    h2{
      font-size:14px;
      color:grey;
    }
  }
  .arrow{
 
      margin-left:20px;
      margin-bottom: 30px;
  }
 .main{
     width:80%;
     margin-top:10px;
   
     .heading{
        margin-left: 40px;
         display:flex;
         font-family: "montserratregular";
         align-items:center;
         h1{
             font-size:18px;
             text-align:center;
             font-weight:bold;
            
         }
         small{
             width:800px;
             height:1px;
             background-color:#f0f0f0;
             margin-top:10px;
         }
         
     }
     .section{
         display:flex;
         width:100%;
         justify-content:space-between;
         margin-top:30px;
         margin-left:80px;
         margin-bottom:30px;
         .small{
             display:flex;
            justify-content:space-between;
             .item{
                  display:flex;
                  flex-direction:column;
                  justify-content:space-between;
                  width:577px;
                .name{
                     display:flex;
                     text-align:start;
                     justify-content:space-between;
                     h1{
                         font-size:16px;
                         width:150px;
                         font-weight:bold;
                         font-family: "dm_sansregular";
                     }
                     h2{
                         font-size:16px;
                         width:250px;
                         font-family: "dm_sansregular";
                         color:grey;
                       
                     }
                     span{
                         width:20px;
                     }
                 }
             }
             
          .heading{
              display:flex;
              width:200px;
          }
         
        }
        
     }
    
`
export const Updatemodal = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
justify-content:center;
align-items: center;
input{
  border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid grey;;
        height: 6vh;
        background: white;
        box-shadow: inset 0 -1px 0  #00a8cb;
        width: 400px;

}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width:90%;
    margin-top:15px;
   
  }
  
  .btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    width:130px;
    height:30px;
    margin-left:13px;
    border-radius: 8px;
    font-size: 10px;
    font-weight: bold;
    justify-content:start;

  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
`

