import { GetDriverpayments } from "../../../../infrastructure/Admin/Driverpayments";

const actions = {
    setisLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setpayment:
      (payment) =>
      ({ setState }) => {
        setState({payment });
      },
      setrazorpay_payment_id:
      (razorpay_payment_id) =>
      ({setState})=>{
        setState({razorpay_payment_id});
      },
      setdate_created:
      (date_created) =>
      ({ setState }) => {
        setState({date_created });
      },
      setdriver_name:
      (driver_name) =>
      ({ setState }) => {
        setState({driver_name });
      },
      setdriver_id:
      (driver_id) =>
      ({ setState }) => {
        setState({driver_id });
      },
      setrefunded:
      (refunded) =>
      ({ setState }) => {
        setState({refunded });
      },
     getPayment:
    () =>
    async ({ dispatch ,getState}) => {
      dispatch(actions.setisLoading(true));
     const{razorpay_payment_id,date_created,driver_name,driver_id,refunded}=getState();
      try {
        const res = await GetDriverpayments(razorpay_payment_id,date_created,driver_name,driver_id,refunded);
        console.log(res, "resdata");
        dispatch(actions. setpayment(res.data.data));
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
    
     export default actions;