import React from "react";
import { Route, Switch } from "react-router-dom";
import CallerHeader from "../Header";
import Callerprofile from "../profile";
import SidenavCaller from "../sidenav/sidenavc";
import styled from "styled-components";
import SupportRequest from "../SupportRequest";
import SupportreqExp from "../SupportReqexp";
import RequestTaken from "../Taken_Request";
function RouterCaller() {
  return (
    <div>
      <CallerHeader />
      <Container>
        <div>
          <SidenavCaller />
        </div>
        <Right>
          <Switch>
            <Route exact path="/caller/profile" component={Callerprofile} />
            <Route
              exact
              path="/caller/supportrequest"
              component={SupportRequest}
            />
            <Route
              exact
              path="/caller/supportrequestExp/:id"
              component={SupportreqExp}
            />
              <Route
              exact
              path="/caller/takenRequests"
              component={RequestTaken}
            />
          </Switch>
        </Right>
      </Container>
    </div>
  );
}

export default RouterCaller;

const Container = styled.div`
  display: flex;
`;
const Right = styled.div`
  height: 100vh;

  display: flex;
  justify-content: space-between;
`;
