import React from "react";
import { HeaderNav2 } from "./style";
import Logo from "../../../Images/LogoDelibuoy.png";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { AlignCenterOutlined } from "@ant-design/icons";
function Navbar() {
  let history = useHistory();
  const contactlink = () => {
    history.push(`/contact`);
  };
  const Homelink = () => {
    history.push(`/home`);
  };

  return (
    <HeaderNav2>
      <div className="top">
        <div className="main">
          <img src={Logo} alt="logo"></img>
        </div>

        <ul>
          <li onClick={() => Homelink()}>Home</li>
          <a href="#about">
            {" "}
            <li>About Us</li>
          </a>

          <li onClick={() => contactlink()}>Contact Us</li>
        </ul>
        <ul className="hamburger">
          <li>
            <AlignCenterOutlined />
          </li>
        </ul>
      </div>
    </HeaderNav2>
  );
}

export default Navbar;
