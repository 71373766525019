import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import goodmorning from '../../../Images/goodmorning.mpga'
import goodafternoon from '../../../Images/goodafternoon.mpga';
import goodevening from '../../../Images/good evening.mp3'
import delibuoyloading from '../../../Images/delibuoy.gif'
function Landingpage() {
  let time = new Date().getHours();
  let greet = "";
  let msg='';
  if ((time >= 0) & (time < 12)) {
    greet = "Hey,Good Morning !";
    msg= <iframe width='0' height="0" src={goodmorning} frameborder='0' allowfullscreen/>;
  } else if ((time >= 12) & (time <= 17)) {
    greet = "Hey,Good Afternoon !";
    msg= <iframe width='0' height="0" src={ goodafternoon } frameborder='0' allowfullscreen/>;
  } else {
    greet = "Hey,Good Evening !";
    msg= <iframe width='0' height="0" src={goodevening } frameborder='0' allowfullscreen/>;
  }
  const [loader, setLoader] = useState(false);
  let History = useHistory();
  const loading = () => {
    const timer = setTimeout(() => {
      History.push("/home");
    }, 3000);
  };

  useEffect(() => {
    loading();
  }, []);

  return (
    <Landing   style={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
     
        <div className="body">
        {/* <img src={delibuoyloading}></img> */}
        <h2>
          <span>{greet}{msg} </span>
        </h2>
        </div>
    </Landing>
  );
}

export default Landingpage;

export const Landing = styled.div`
// display:flex;
// justify-content:center;
// align-items:center;
// img {
//   width: 420px;
//   @media (max-width: 540px) {
//     width: 300px;
//   }
//   @media (max-width: 48px) {
//     width: 280px;
//   }
// }
  // figure {
  //   position: absolute;
  //   margin: auto;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   width: 6.25em;
  //   height: 6.25em;
  //   animation: rotate 2.4s linear infinite;
  // }
  // .white {
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background: white;
  //   animation: flash 2.4s linear infinite;
  //   opacity: 0;
  // }
  // .dot {
  //   position: absolute;
  //   margin: auto;
  //   width: 2.4em;
  //   height: 2.4em;
  //   border-radius: 100%;
  //   transition: all 1s ease;
  // }
  // .dot:nth-child(2) {
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   background: #ff4444;
  //   animation: dotsY 2.4s linear infinite;
  // }
  // .dot:nth-child(3) {
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   background: #ffbb33;
  //   animation: dotsX 2.4s linear infinite;
  // }
  // .dot:nth-child(4) {
  //   top: 0;
  //   bottom: 0;
  //   right: 0;
  //   background: #99cc00;
  //   animation: dotsY 2.4s linear infinite;
  // }
  // .dot:nth-child(5) {
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background: #33b5e5;
  //   animation: dotsX 2.4s linear infinite;
  // }

  // @keyframes rotate {
  //   0% {
  //     transform: rotate(0);
  //   }
  //   10% {
  //     width: 6.25em;
  //     height: 6.25em;
  //   }
  //   66% {
  //     width: 2.4em;
  //     height: 2.4em;
  //   }
  //   100% {
  //     transform: rotate(360deg);
  //     width: 6.25em;
  //     height: 6.25em;
  //   }
  // }

  // @keyframes dotsY {
  //   66% {
  //     opacity: 0.1;
  //     width: 2.4em;
  //   }
  //   77% {
  //     opacity: 1;
  //     width: 0;
  //   }
  // }
  // @keyframes dotsX {
  //   66% {
  //     opacity: 0.1;
  //     height: 2.4em;
  //   }
  //   77% {
  //     opacity: 1;
  //     height: 0;
  //   }
  // }

  // @keyframes flash {
  //   33% {
  //     opacity: 0;
  //     border-radius: 0%;
  //   }
  //   55% {
  //     opacity: 0.6;
  //     border-radius: 100%;
  //   }
  //   66% {
  //     opacity: 0;
  //   }
  // }
  .body{
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    min-height:100vh;
    background:black;
  }
  h2{
    color:#000;
    font-size:3em;
    display:flex;
    @media(max-width:450px){
      font-size:2em;
    }
  }
  h2 span {
    animation:animate 4s linear infinite;
  }
  h2 span :nth-child(1){
    animation-delay:0s;
  }
  h2 span :nth-child(2){
    animation-delay:0.1s;
  }
  h2 span :nth-child(3){
    animation-delay:0.2s;
  }
  h2 span :nth-child(4){
    animation-delay:0.3s;
  }
  h2 span :nth-child(5){
    animation-delay:0.4s;
  }
  h2 span :nth-child(6){
    animation-delay:0.5s;
  }
  h2 span :nth-child(7){
    animation-delay:0.6s;
  }
  h2 span :nth-child(8){
    animation-delay:0.8s;
  }
  @keyframes animate{
    0%,100%
    {
      color:#fff;
      filter:blur(2px);
      text-shadow:0 0 10px #00b3ff;
                  0 0 20px #00b3ff;
                  0 0 30px #00b3ff;
                  0 0 40px #00b3ff;
                  0 0 80px #00b3ff;
                  0 0 120px #00b3ff;
                  0 0 200px #00b3ff;
                  0 0 300px #00b3ff;
                  0 0 400px #00b3ff;
    }
    25%,50%
    {
      color:#000;
      filter:blur(0px);
      text-shadow:none;
    }
  }
`;
