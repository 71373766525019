import { GetFranchisereq } from "../../../../infrastructure/Admin/FranchiseReqList";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setFranchisereq:
    (franchisereq) =>
    ({ setState }) => {
      setState({ franchisereq });
    },
    setName:
    (name) =>
    ({ setState }) => {
      setState({ name });
    },
    setPhone:
    (phone) =>
    ({ setState }) => {
      setState({ phone});
    },
    setEmail:
    (email) =>
    ({ setState }) => {
      setState({ email });
    },
  getFreq:
    () =>
    async ({ dispatch,getState }) => {
        const{name,phone,email}=getState();
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetFranchisereq(name,phone,email);
        dispatch(actions.setFranchisereq(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
