import React, { useEffect,useState} from "react";
import { Payment } from "./style";
import { Divider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Getdriverpaymentdetails } from "./store";
import { SearchOutlined, CloseOutlined} from "@ant-design/icons";
import { firebase } from "../../../Common/firebase";  
import {  Input } from "antd";

function Driverpayments() {
  const [
    {
      isLoading,
      payment,
      razorpay_payment_id,
      date_created,
      driver_name,
      driver_id,
      refunded,
    },
    {
      getPayment,
      setrazorpay_payment_id,
      setdate_created,
      setdriver_name,
      setdriver_id,
      setrefunded,
    },
  ] = Getdriverpaymentdetails();

  const [hide, sethide] = useState(true);
  const hidden = () => {
    sethide(!hide);
    setrazorpay_payment_id("");
  };
  const [hideD, sethideD] = useState(true);
  const hiddenD = () => {
    sethideD(!hideD);
    setdate_created("");
  };
  const [hideDn, sethideDn] = useState(true);
  const hiddenDn = () => {
    sethideDn(!hideDn);
    setdriver_name("");
  };
  const [hideDI, sethideDI] = useState(true);
  const hiddenDI = () => {
    sethideDI(!hideDI);
    setdriver_id("");
  };
  const [hideRE, sethideRE] = useState(true);
  const hiddenRE = () => {
    sethideRE(!hideRE);
    setrefunded("");
  };

//token................................................................\
const [token, setToken] = useState("");
useEffect(() => {
  //import { firebase } from "../../../Common/firebase";
  return firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      user
        .getIdToken(true)
        .then((latestToken) => setToken(latestToken))
        .catch((err) => console.log(err));
    }
  });
}, [token]);
localStorage.setItem("token", token);
//......................................................
  useEffect(() => {
    getPayment();
  }, [ razorpay_payment_id,
    date_created,
    driver_name,
    driver_id,
    refunded,token]);

    const SearchrequestRazorpaymentid = (e) => {
      setrazorpay_payment_id(e.target.value);
      getPayment();
    };
    const SearchrequestDatecreated = (e) => {
      setdate_created(e.target.value);
      getPayment();
    };
    const SearchrequestDrivername = (e) => {
      setdriver_name(e.target.value);
      getPayment();
    };
    const SearchrequestDriverid = (e) => {
      setdriver_id(e.target.value);
      getPayment();
    };
    const SearchrequestRefund = (e) => {
      setrefunded(e.target.value);
      getPayment();
    };
    
   
    
  return (
    <Payment>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Driver Payments</h2>
      </div>
      <div className="dpayment">
        <div className="heading">
          <h1>Driver Payments</h1>
          <Divider orientation="left" />
        </div>
      </div>
      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No</h1>
                </th>
                <th className="head-off">
                  <h1>Id 
                  {""}
                    {hideDI ? (
                      <div>
                        <SearchOutlined style={{ color: 'grey' }} onClick={() => hiddenDI()} />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) =>SearchrequestDriverid(e)}
                          suffix={<CloseOutlined style={{ color: 'grey' }} onClick={() => hiddenDI()} />}
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Razorpay payment ID
                  {""}
                    {hide ? (
                      <div>
                        <SearchOutlined style={{ color: 'grey' }} onClick={() => hidden()} />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) =>SearchrequestRazorpaymentid (e)}
                          suffix={<CloseOutlined style={{ color: 'grey' }} onClick={() => hidden()} />}
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Payment Received</h1>
                </th>
                <th className="head-off">
                  <h1>Driver
                  {""}
                    {hideDn ? (
                      <div>
                        <SearchOutlined style={{ color: 'grey' }} onClick={() => hiddenDn()} />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) =>SearchrequestDrivername(e)}
                          suffix={<CloseOutlined style={{ color: 'grey' }} onClick={() => hiddenDn()} />}
                        ></Input>
                      </div>
                    )} 
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Amount</h1>
                </th>
                <th className="head-off">
                  <h1>Delibuoy Share</h1>
                </th>
                <th className="head-off">
                  <h1>Franchise Share</h1>
                </th>
                <th className="head-off">
                  <h1>Refunded
                  {""}
                    {hideRE ? (
                      <div>
                        <SearchOutlined style={{ color: 'grey' }} onClick={() => hiddenRE()} />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) =>SearchrequestRefund(e)}
                          suffix={<CloseOutlined style={{ color: 'grey' }} onClick={() => hiddenRE()} />}
                        ></Input>
                      </div>
                    )} 
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Date created
                  {""}
                    {hideD ? (
                      <div>
                        <SearchOutlined style={{ color: 'grey' }} onClick={() => hiddenD()} />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) =>SearchrequestDatecreated(e)}
                          suffix={<CloseOutlined style={{ color: 'grey' }} onClick={() => hiddenD()} />}
                        ></Input>
                      </div>
                    )} 
                  </h1>
                </th>
              </tr>
            </thead>
            <tbody>
              {payment?.map((res, i) => (
                <tr className="body-row-off">
                  <td>{i + 1}</td>
                  <td>{res.id}</td>
                  <td>{res.razorpay_payment_id}</td>
                  <td>{res.payment_recieved == true ? "Yes" : "No"}</td>
                  <td>{res.driver}</td>
                  <td>{res.amount}</td>
                  <td>{res.delibuoy_share}</td>
                  <td>{res.franchise_share}</td>
                  <td>{res.refunded == true ? "Yes" : "No"}</td>
                  <td>{res.date_created}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Payment>
  );
}

export default Driverpayments;
