import React, { useEffect, useState } from "react";
import { RequestDiv } from "./style";
import { Divider } from "antd";
import { SearchOutlined, CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { RequestTakenby } from "./store";
import { firebase } from "../../../Common/firebase";
import Loader from '../../Caller/Widget/Common/Loader'
function RequestTaken() {
  const [{ isLoading,requests }, { GetRequestTakenres }] = RequestTakenby();
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  localStorage.setItem("token", token);
  
  useEffect(() => {
    GetRequestTakenres();
  }, [token]);
  if(isLoading) {
    return <Loader/>
}
  return (
    <RequestDiv>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Request</h2>
      </div>
      <div className="driver">
        <div className="heading">
          <h1>Request Taken</h1>
          <Divider orientation="left" />
        </div>
      </div>

      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No:</h1>
                </th>
                <th className="head-off">
                  <h1>Id{""}</h1>
                </th>
                <th className="head-off">
                  <h1>User Id</h1>
                </th>
                <th className="head-off">
                  <h1>Name</h1>
                </th>
                <th className="head-off">
                  <h1>User Type </h1>
                </th>
                <th className="head-off">
                  <h1>Phone{""}</h1>
                </th>
                <th className="head-off">
                  <h1>Email</h1>
                </th>
                <th className="head-off">
                  <h1>File</h1>
                </th>
              </tr>
            </thead>

            <tbody>
              {requests.map((res,i) => (
                <tr className="body-row-off">
                  <td>{i + 1}</td>
                  <td>{res.id}</td>
                  <td>{res.user_id}</td>

                  <td>
                    <h1>{res.name}</h1>
                  </td>
                  <td>
                    <h2>{res.user_type}</h2>
                  </td>

                  <td>{res.phone} </td>
                  <td>{res.email} </td>
                  <td> <a href={res.file} target="_blank">file</a>  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </RequestDiv>
  );
}

export default RequestTaken;
