import {
  Getdriverexpfranchise,
  DriverUpdatebyid,
  VehicleUpdatebyid,
} from "../../../../infrastructure/Admin-franchise/DiverExpnd";
import { notification } from "antd";
const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setDetaillist:
    (Details) =>
    ({ setState }) => {
      setState({ Details });
    },
  setiddt:
    (idd) =>
    ({ setState }) => {
      setState({ idd });
    },
  setAccountDetails:
    (accountDetails) =>
    ({ setState }) => {
      setState({ accountDetails });
    },
  setVehicleDetails:
    (vehicleDetails) =>
    ({ setState }) => {
      setState({ vehicleDetails });
    },
  setUserNames:
    (name) =>
    ({ setState }) => {
      setState({ name });
    },
  setPhone:
    (phone) =>
    ({ setState }) => {
      setState({ phone });
    },
  setBillingadrs:
    (billing_address) =>
    ({ setState }) => {
      setState({ billing_address });
    },
  setPincode:
    (pincode) =>
    ({ setState }) => {
      setState({ pincode });
    },
  setAge:
    (age) =>
    ({ setState }) => {
      setState({ age });
    },
  setSex:
    (sex) =>
    ({ setState }) => {
      setState({ sex });
    },
  setLicencefile:
    (Licencefile) =>
    ({ setState }) => {
      setState({ Licencefile });
    },
  setReferencename:
    (referencename) =>
    ({ setState }) => {
      setState({ referencename });
    },
  setReferencerelation:
    (referencerelation) =>
    ({ setState }) => {
      setState({ referencerelation });
    },
  setReferencenumber:
    (referencenumber) =>
    ({ setState }) => {
      setState({ referencenumber });
    },
  setLicencenumber:
    (Licencenumber) =>
    ({ setState }) => {
      setState({ Licencenumber });
    },
  setAccountnum:
    (account_number) =>
    ({ setState }) => {
      setState({ account_number });
    },
  setIfsccode:
    (ifsc_code) =>
    ({ setState }) => {
      setState({ ifsc_code });
    },
  setBankname:
    (bank_name) =>
    ({ setState }) => {
      setState({ bank_name });
    },

  setUpi:
    (upi) =>
    ({ setState }) => {
      setState({ upi });
    },
  setImage:
    (image) =>
    ({ setState }) => {
      setState({ image });
    },
  setRegisternmbr:
    (register_number) =>
    ({ setState }) => {
      setState({ register_number });
    },
  setYearofregistration:
    (year_of_registration) =>
    ({ setState }) => {
      setState({ year_of_registration });
    },
  setBodycolor:
    (body_color) =>
    ({ setState }) => {
      setState({ body_color });
    },
  setMaxload:
    (max_load) =>
    ({ setState }) => {
      setState({ max_load });
    },
  setVehicleimage:
    (vehicleimage) =>
    ({ setState }) => {
      setState({ vehicleimage });
    },
  setImageleft:
    (imageleft) =>
    ({ setState }) => {
      setState({ imageleft });
    },
  setImageright:
    (imageright) =>
    ({ setState }) => {
      setState({ imageright });
    },
  setImageback:
    (imageback) =>
    ({ setState }) => {
      setState({ imageback });
    },
  setDateOfBirth:
    (DateOfBirth) =>
    ({ setState }) => {
      setState({ DateOfBirth });
    },
  setlicensecovert:
    (licensecovert) =>
    ({ setState }) => {
      setState({ licensecovert });
    },
  setProfilepicupload:
    (Profilepicupload) =>
    ({ setState }) => {
      setState({ Profilepicupload });
    },
  setIsDriver:
    (isDriver) =>
    ({ setState }) => {
      setState({ isDriver });
    },
  setIsVehicle:
    (isVehicle) =>
    ({ setState }) => {
      setState({ isVehicle });
    },
    setrerender:
    (rerender) =>
    ({ setState }) => {
      setState({ rerender });
    },
  GetDetailsoff:
    (id) =>
    async ({ dispatch }) => {
      try {
        dispatch(actions.setisLoading(true));
        let res = await Getdriverexpfranchise(id);
        dispatch(actions.setDetaillist(res.data.data));
        dispatch(actions.setVehicleDetails(res.data.data));
        dispatch(actions.setUserNames(res?.data.data?.name));
        dispatch(actions.setPhone(res?.data.data?.phone));
        dispatch(actions.setBillingadrs(res?.data.data?.billing_address));
        dispatch(actions.setPincode(res?.data.data?.pincode));
        dispatch(actions.setAge(res?.data.data?.age));
        dispatch(actions.setSex(res?.data.data?.sex));
        dispatch(actions.setReferencename(res?.data.data?.reference_name));
        dispatch(
          actions.setReferencerelation(res?.data.data?.reference_relation)
        );
        dispatch(actions.setReferencenumber(res?.data.data?.reference_number));
        dispatch(actions.setLicencenumber(res?.data.data?.licence_number));
        dispatch(actions.setLicencefile(res?.data.data?.licence_file));
        dispatch(
          actions.setAccountnum(res.data.data.account_info?.account_number)
        );
        dispatch(actions.setIfsccode(res.data.data.account_info?.ifsc_code));
        dispatch(actions.setBankname(res.data.data.account_info?.bank_name));
        dispatch(actions.setUpi(res.data.data.account_info?.upi));
        dispatch(actions.setImage(res.data.data.image));
        dispatch(actions.setDateOfBirth(res.data.data?.date_of_birth));
        dispatch(
          actions.setRegisternmbr(res.data.data.vehicle_info?.register_number)
        );
        dispatch(
          actions.setYearofregistration(
            res.data.data.vehicle_info?.year_of_registration
          )
        );
        dispatch(actions.setBodycolor(res.data.data.vehicle_info?.body_color));
        dispatch(actions.setMaxload(res.data.data.vehicle_info?.max_load));
        // dispatch(
        //   actions.setVehicleimage(res.data.data.vehicle_info?.vehicle_image)
        // );
        // dispatch(actions.setImageback(res.data.data.vehicle_info?.image_back));
        // dispatch(actions.setImageleft(res.data.data.vehicle_info?.image_left));
        // dispatch(
        //   actions.setImageright(res.data.data.vehicle_info?.image_right)
        // );
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  UpdateDriver:
    (id) =>
    async ({ dispatch, getState }) => {
      const {
        name,
        phone,
        pincode,
        billing_address,
        account_number,
        ifsc_code,
        bank_name,
        upi,
        image,
        age,
        sex,
        DateOfBirth,
        referencename,
        referencerelation,
        referencenumber,
        Licencenumber,
        Licencefile,
        Profilepicupload,
        licensecovert,
        rerender
      } = getState();
      dispatch(actions.setisLoading(true));
    

      try {
        if (name === "") {
          notification["warning"]({
            message: "warning",
            description: "Name feild is empty",
          });
          return;
        } else if (phone === "") {
          notification["warning"]({
            message: "warning",
            description: "Phone feild is empty",
          });
          return;
        } else if (pincode === "") {
          notification["warning"]({
            message: "warning",
            description: "Phone feild is empty",
          });
          return;
        } else if (billing_address === "") {
          notification["warning"]({
            message: "warning",
            description: "Billing address feild is empty",
          });
          return;
        } else if (account_number === "") {
          notification["warning"]({
            message: "warning",
            description: "Account Number feild is empty",
          });
          return;
        } else if (ifsc_code === "") {
          notification["warning"]({
            message: "warning",
            description: "IFSC code feild is empty",
          });
          return;
        } else if (bank_name === "") {
          notification["warning"]({
            message: "warning",
            description: "IFSC code feild is empty",
          });
          return;
        } else if (upi === "") {
          notification["warning"]({
            message: "warning",
            description: "UPI feild is empty",
          });
          return;
        } else if (age === "") {
          notification["warning"]({
            message: "warning",
            description: "Age feild is empty",
          });
          return;
        } else if (referencename === "") {
          notification["warning"]({
            message: "warning",
            description: "Reference Name feild is empty",
          });
          return;
        } else if (referencerelation === "") {
          notification["warning"]({
            message: "warning",
            description: "Reference Relation feild is empty",
          });
          return;
        } else if (referencenumber === "") {
          notification["warning"]({
            message: "warning",
            description: "Reference Number feild is empty",
          });
          return;
        }
  
        var formData = new FormData();
        formData.append("name", name);
        formData.append("phone", phone);
        formData.append("pincode", pincode);
        formData.append("billing_address", billing_address);
        formData.append("account_number", account_number);
        formData.append("ifsc_code", ifsc_code);
        formData.append("bank_name", bank_name);
        formData.append("upi", upi);
        if (Profilepicupload !== null) {
          formData.append("image", Profilepicupload);
        }
        formData.append("age", age);
        formData.append("sex", sex);
        formData.append("date_of_birth", DateOfBirth);
        formData.append("reference_name", referencename);
        formData.append(" reference_relation", referencerelation);
        formData.append("reference_number", referencenumber);
        formData.append("licence_number", Licencenumber);
        if (licensecovert !== null) {
          formData.append("licence_file", licensecovert);
        }
         await DriverUpdatebyid(id, formData);

        dispatch(actions.GetDetailsoff());
        dispatch(actions.setrerender(!rerender))
        dispatch(actions.setIsDriver(false));
        notification["success"]({
          message: "success",
          description: "Updated Succesfully",
        });
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: "Unsuccessfull updation",
        });
      } finally {
        dispatch(actions.GetDetailsoff());
        dispatch(actions.setisLoading(false));
      }
    },

  UpdateVehicle:
    (id) =>
    async ({ dispatch, getState }) => {
      const {
        register_number,
        year_of_registration,
        body_color,
        max_load,
        vehicleimage,
        imageleft,
        imageright,
        imageback,
        rerender
      } = getState();
      dispatch(actions.setisLoading(true));
      try {
        if (register_number === "") {
          notification["warning"]({
            message: "warning",
            description: "Register Number field is empty",
          });
          return;
        } else if (year_of_registration === "") {
          notification["warning"]({
            message: "warning",
            description: "Year Of Registration field is empty",
          });
          return;
        } else if (body_color === "") {
          notification["warning"]({
            message: "warning",
            description: "Body Color field is empty",
          });
          return;
        } else if (max_load === "") {
          notification["warning"]({
            message: "warning",
            description: "Max Load field is empty",
          });
          return;
        }

        var formData = new FormData();
        formData.append("register_number", register_number);
        formData.append("year_of_registration", year_of_registration);
        formData.append("body_color", body_color);
        formData.append("max_load", max_load);
        if (vehicleimage !== null) {
          formData.append("image", vehicleimage);
        }
        if (imageleft !== null) {
          formData.append("image_left", imageleft);
        }
        if (imageright !== null) {
          formData.append("image_right", imageright);
        }
        if (imageback !== null) {
          formData.append("image_back", imageback);
        }

        await VehicleUpdatebyid(id, formData);

        dispatch(actions.GetDetailsoff());
        dispatch(actions.setrerender(!rerender))
        dispatch(actions.setIsVehicle(false));
        notification["success"]({
          message: "success",
          description: "Updated Succesfully",
        });
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: "Unsuccessfull updation",
        });
      } finally {
        dispatch(actions.GetDetailsoff());
        dispatch(actions.setisLoading(false));
      }
    },
};

export default actions;
