import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const settlement = createStore({
  name: "settlement",
  initialState,
  actions,
});

export const SettlementDues = createHook(settlement, {
  selector: (state) => state,
});
