import signInWithEmailAndPassword from "../../../../infrastructure/Admin/Login";
import { doc, getDoc } from "firebase/firestore";
import { db, firebase } from "../../../../Common/firebase";
import { notification } from "antd";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setToken:
    (token) =>
    ({ setState }) => {
      setState({ token });
    },
  setUsernameL:
    (UsernameL) =>
    ({ setState }) => {
      setState({ UsernameL });
    },
  setPasswordL:
    (PasswordL) =>
    ({ setState }) => {
      setState({ PasswordL });
    },
  Submitlogins:
    (history) =>
    async ({ setState, dispatch, getState }) => {
      const { UsernameL, PasswordL } = getState();
      dispatch(actions.setisLoading(true));
      try {
        await signInWithEmailAndPassword(UsernameL, PasswordL);
        const user = firebase.auth().currentUser;
        const mytoken = user && (await user.getIdToken());
        localStorage.setItem("token", mytoken);
        notification["success"]({
          message: "success",
          description: "Admin Logged in successfully",
        });

        history.push(`/admin/dashboard`);
      } catch (err) {
        notification["warning"]({
          message: "warning",
          description: "Username or Password incorrect",
        });
      } finally {
      }
    },
};
export default actions;
