import styled from "styled-components";
export const Payment = styled.div`
width:83vw;
margin-left:15vw;
  margin-top:80px;
.title{
    display:flex;
     justify-content:flex-end;
     margin-right:200px;
     margin-top:40px;
     gap:10px;
    h1{
      font-size:14px;
      color:grey;
    }
    h2{
      font-size:14px;
      color:grey;
    }
  }
  .franchises{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .heading {
      width: 95%;
      display: flex;
      align-items: center;
      h1{
        font-size:22px;
        font-weight:bold; 
        font-family: "Quicksand", sans-serif;
        letter-spacing: 1px;
      }
    }
  }
  .button{
    display:flex;
    justify-content: flex-end;
    margin-right:150px;
    button{
      height:40px;
      width:100px;
      border:none;
      border-radius:4px;
      background:#00a8cb;
      &:active{
        background-color: black;
        color:white;
      }
    


    }
  }
  .content{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
    flex-direction: column;
    align-items: center;
.border {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 90%;
    padding: 30px;
    .tables table.official {
      width: 100%;
      border: 1px solid #dddddd;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
      border-radius: 0px 4px 0px 0px;
    }
    tr.head-row-off {
      border-bottom: 1px solid #dddddd;
    }
    th.head-off {
      font-style: normal;
      font-weight:bold;
      font-size: 14px;
      line-height: 20px;
      height: 40px;

      text-align: left;
      font-family: "dm_sansregular";
      font-style: normal;
      font-weight: 500px !important;
      font-size: 18px;
      line-height: 150%;
      color: #000000;
      width: 300px;
      h1 {
        display:flex;
        align-items: center;
        font-family: "dm_sansregular";
        font-size: 18px;
        font-weight:bold;
        display: flex;
        align-items: center;
        gap:5px;
      }
    }
    tr.body-row-off {
      margin-top: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #dddddd;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
      height: 60px;
      text-align: left;
      font-family: "dm_sansregular";
      font-style: normal;
      font-weight: 500px !important;
      font-size: 16px;
      line-height: 150%;

      color: #000000;

      .qty {
        display: flex;
        gap: 15px;
      }
      img {
        width: 120px;
        border-radius: 6px;
      }
      h1 {
        font-size: 16px;
      }
      h2 {
        font-size: 16px;
      }
    }
    tr.body-row-off:hover {
  
      background: linear-gradient(
          0deg,
          rgba(96, 36, 57, 0.08),
          rgba(96, 36, 57, 0.08)
        ),
        #ffffff;
    }
  }
}
}
`;
