import { get } from "../common/remote/base_api";

export const GetDriversres = (name, phone, email, id) => {
  return get(`admin/get-driver-list/`, {
    name: name,
    phone: phone,
    email: email,
    id: id,
  });
};

export const GetBlockDriversres = (id) => {
  return get(`admin/block-driver/?driver_id=${id}/`);
};

export const GetUnblockDriversres=(id) =>{
  return get(`admin/unblock-driver/?driver_id=${id}/`)
};