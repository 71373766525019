import React from "react";
import HeaderDelibuoy from "../Header";
import Footerweb from "../Footer";
import { Contact } from "./style";

function ContactUs() {
  return (
    <Contact>
      <div>
        <HeaderDelibuoy />
        <div className="page"></div>
        <div className="section">
          <div className="top">
            <div className="heading">
              <h1>Get in Touch </h1>
            </div>
            <div className="contactus">
              <h1>
                39/2701, 1st Floor Pallickel Building,
                <br /> (75) South janatha road, Palarivattom, Ernakulam, Kochi,
                682025, Kerala, IN,04842998662
              </h1>
            </div>
            <div className="inputbox">
              <div className="addres">
                <input type="text" placeholder="enter your name"></input>
              </div>
              <div className="addres">
                <input type="email" placeholder="enter your email"></input>
              </div>
            </div>
            <div className="inputbox">
              <div className="addres">
                <input
                  type="text"
                  placeholder="enter your phone number"
                ></input>
              </div>
              <div className="addres">
                <input type="text" placeholder="subject"></input>
              </div>
            </div>
            <div className="textarea">
              <div className="main">
                <input
                  type="text"
                  placeholder="Start write here your message"
                ></input>
              </div>
            </div>
            <div className="btn">
              <div className="btn1">
                <button>Submit</button>
              </div>
            </div>
          </div>
        </div>
        <Footerweb />
        {/* color code  for header   */}
      </div>
    </Contact>
  );
}

export default ContactUs;
