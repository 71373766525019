import React, { useEffect, useState } from "react";
import Loader from "../Widget/common/Loader";
import { GetCustmertripby } from "./store";
import BackArrow from "../Widget/common/BackArrow";
import { LeftOutlined } from "@ant-design/icons";
import { CustomerDetail } from "./style";
import { Divider } from "antd";
import { firebase } from "../../../Common/firebase";
function CustomerTrip({ match }) {
  const [idd, setidd] = useState(match.params.id);

  const [{ isLoading, customertrip }, { getCustomertrips }] =
    GetCustmertripby();
    const [token, setToken] = useState("");
    useEffect(() => {
      //import { firebase } from "../../../Common/firebase";
      return firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          user
            .getIdToken(true)
            .then((latestToken) => setToken(latestToken))
            .catch((err) => console.log(err));
        }
      });
    }, [token]);
    localStorage.setItem("token", token);
  useEffect(() => {
    getCustomertrips(idd);
  }, [token]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <CustomerDetail>
      <div className="title">
        <div className="backarrow">
          <BackArrow />
        </div>
        <div className="heading">
          <h1>Customers</h1>
          <LeftOutlined style={{ color: "grey" }} />
          <h2>Customers Trip</h2>
        </div>
      </div>
      <div className="main">
        <div className="heading">
          <h1>Customer Trip</h1>
        </div>
      </div>
      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No:</h1>
                </th>
                <th className="head-off">
                  <h1>ID</h1>
                </th>
                <th className="head-off">
                  <h1>Start Date</h1>
                </th>
                <th className="head-off">
                  <h1>Driver </h1>
                </th>
                <th className="head-off">
                  <h1>Total Distance</h1>
                </th>
                <th className="head-off">
                  <h1>Total Charge</h1>
                </th>
                <th className="head-off">
                  <h1>Commission</h1>
                </th>
              </tr>
            </thead>
            <tbody>
              {customertrip?.map((res,i) => (
                <tr className="body-row-off">
                  <td>{i+1} </td>
                  <td>{res.id}</td>
                  <td>
                    <h1>{res.start_date}</h1>
                  </td>
                  <td>
                    <h1>{res.driver}</h1>
                  </td>
                  <td>
                    <h1>{res.total_distance}</h1>
                  </td>
                  <td>
                    <h1>&#8377; {res.total_charge }</h1>
                  </td>
                  <td>&#8377; {res.commission}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </CustomerDetail>
  );
}

export default CustomerTrip;
