// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";
// import "firebase/compat/auth";
// import firebase from "firebase"
// const app = {
//   apiKey: "AIzaSyCo1P4h8FqpX8L_2rncwiK9bLVrxdgVu-g",

//   authDomain: "foodbuoy-258311.firebaseapp.com",

//   databaseURL: "https://foodbuoy-258311.firebaseio.com",

//   projectId: "foodbuoy-258311",

//   storageBucket: "foodbuoy-258311.appspot.com",

//   messagingSenderId: "401433778284",

//   appId: "1:401433778284:web:efef98e548b7213ab62049",

//   measurementId: "G-3SJBFQTZTD",
// };
// Initialize Firebase

// export const appdata = firebase.initializeApp(app);
// export const auth = appdata.auth();
// export const db = app.firestore();

// const exports =()=>{
//   firebase.initializeApp(app);
// }

// firebase.initializeApp(app);
// var db = firebase.firestore();

// export const auth = app.auth();
// export default app;

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCUbzpkubN28XeQ4-CvbIGwpabnNhdvdwY",
  authDomain: "delibuoy-644f5.firebaseapp.com",
  projectId: "delibuoy-644f5",
  storageBucket: "delibuoy-644f5.appspot.com",
  messagingSenderId: "755101925194",
  appId: "1:755101925194:web:145b637c9c3f0573fb8bb3",
  measurementId: "G-3ESK4GR2K7",
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
const db = firebase.firestore();

export { auth, firebase, db };
