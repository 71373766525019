import { Postforsupport } from "../../../../infrastructure/Admin-franchise/Support";
import { notification } from "antd";
import initialState from "./initialState";
const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setsubject:
    (subject) =>
    ({ setState }) => {
      setState({ subject });
    },
  setmessage:
    (message) =>
    ({ setState }) => {
      setState({ message });
    },
  setmyfiles:
    (myfiles) =>
    ({ setState }) => {
      setState({ myfiles });
    },
  Postsupportmessage:
    () =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      try {
        const { subject, message, myfiles } = getState();
        var bodyFormdata = new FormData();
        bodyFormdata.append("subject", subject);
        bodyFormdata.append("message", message);
        bodyFormdata.append("file", myfiles);
        await Postforsupport(bodyFormdata);
        dispatch(actions.setsubject(initialState.subject));
        dispatch(actions.setmessage(initialState.message));
        dispatch(actions.setmyfiles(initialState.myfiles));
        notification["success"]({
          message: "success",
          description: "Your Message Send successfully",
        });
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
