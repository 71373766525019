import React, { useState, useEffect } from "react";
import { Reqexp } from "./style";
import { LeftOutlined } from "@ant-design/icons";
import BackArrow from "../../Caller/Widget/Common/BackArrow";
import { Divider } from "antd";
import { SupportdetailExp } from "./store";
import { firebase } from "../../../Common/firebase";
import Loader from '../../Caller/Widget/Common/Loader'
function SupportreqExp({ match }) {
  const [idd, setidd] = useState(match.params.id);
  const [token, setToken] = useState("");
  const [{ isLoading, details }, { setDetails, getCallerdetails }] =
    SupportdetailExp();

    useEffect(() => {
      //import { firebase } from "../../../Common/firebase";
      return firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          user
            .getIdToken(true)
            .then((latestToken) => setToken(latestToken))
            .catch((err) => console.log(err));
        }
      });
    }, []);
    localStorage.setItem("token", token);

  useEffect(() => {
    getCallerdetails(idd);
  }, [token]);

  if(isLoading) {
    return <Loader/>
}

  return (
    <Reqexp>
      <div className="title">
        <h1>Support Request</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>{details.name}</h2>
      </div>
      <div className="arrow">
        <BackArrow />
      </div>
      <div className="main">
        <div className="heading">
          <h1>Support Request</h1>
          <Divider orientation="left" />
        </div>

        <div className="section">
          <div className="small">
            <div className="item">
            <div className="name">
                <h1>ID </h1>
                <span>:</span>
                <h2> {details.id} </h2>
              </div>
              <div className="name">
                <h1>User Id </h1>
                <span>:</span>
                <h2> {details.user_id} </h2>
              </div>
              <div className="name">
                <h1>User Type</h1>
                <span>:</span>
                <h2>{details.user_type}</h2>
              </div>
              <div className="name">
                <h1>Name </h1>
                <span>:</span>
                <h2>{details.name}</h2>
              </div>
              <div className="name">
                <h1>Phone </h1>
                <span>:</span>
                <h2>{details.phone}</h2>
              </div>
              <div className="name">
                <h1>Email </h1>
                <span>:</span>
                <h2>{details.email}</h2>
              </div>
              <div className="name">
                <h1>Subject </h1>
                <span>:</span>
                <h2>{details.subject}</h2>
              </div>
              <div className="name">
                <h1>Message </h1>
                <span>:</span>
                <h2>{details.message}</h2>
              </div>
              <div className="name">
                <h1>Taken </h1>
                <span>:</span>
                <h2>{details.taken === false ? "No" : "Yes"}</h2>
              </div>
              <div className="name">
                <h1>Solved </h1>
                <span>:</span>
                <h2>{details.solved === false ? "No" : "Yes"}</h2>
              </div>

              <div className="name">
                <h1>Date created </h1>
                <span>:</span>
                <h2>{details.date_created}</h2>
              </div>
              <div className="name">
                <h1>Solved by </h1>
                <span>:</span>
                <h2>{details.solved_by === false ? "No" : "Yes"}</h2>
              </div>
              <div className="name">
                <h1>File </h1>
                <span>:</span>
                <h2> <a href={details.file} target="_blank">Caller file</a> </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Reqexp>
  );
}

export default SupportreqExp;
