import styled from "styled-components";
export const Supportfranchise = styled.div`
// background-color: #d3e3fc;
width: 85vw;
margin-left: 15vw;
margin-top:80px;
 .title{
  display:flex;
   justify-content:flex-end;
   margin-right:200px;
   margin-top:40px;
   gap:10px;
  h1{
    font-size:14px;
    color:grey;
  }
  h2{
    font-size:14px;
    color:grey;
  }
}
.support{
  margin-top: 60px;
  display: flex;
  justify-content: center;
  .heading {
    width: 95%;
    display: flex;
    align-items: center;
    h1{
      font-size:22px;
      font-weight:bold; 
      font-family: "Quicksand", sans-serif;
      letter-spacing: 1px;
    }
  }
}
.section{
  
  margin-top:50px;
  display: flex;
  justify-content: center;
  .top{

    width:70%;
    .heading{
      margin-bottom:30px;
      h1{
         font-family: 'pt_serifbold';
         color:grey;
         font-weight:bold;
      }
    }
    .inputbox{
        font-family: 'dm_sansregular';
      display: flex;
      justify-content: center;
       gap:30px;
     
       width:100%;
      .addres{
      
        display: flex;
        flex-direction: column;
        margin-bottom:10px;
        label{
          font-size: 14px;
            font-family: 'dm_sansregular';
        }
        input{
            font-family: 'dm_sansregular';
          width:600px;
         border-radius: 3.5px;
         padding: 1vh;
         font-size: 1.7vh;
       
         border: 1px solid #e0e0e0;
         height: 7vh;
         background: white;
         box-shadow: inset 0 -1px 0 #00a8cb;
       
        }
        
      }
      @media(max-width:540px){
          flex-direction:column;
      }
    }
    .textarea{
        
      display: flex;
      justify-content: center;
     margin-bottom:40px;
      .main{
        flex-direction: column;
        display: flex;
        @media(max-width:540px){
           width:100%;
       }
      }
      input{
          font-family: 'dm_sansregular';
        width:600px;
         border-radius: 3.5px;
         padding: 1vh;
         font-size: 1.7vh;
         border: 1px solid #e0e0e0;
         height: 13vh;
         background: white;
         box-shadow: inset 0 -1px 0 #00a8cb;
     
      
      }
      
    }
    .btn{
      margin-bottom:30px;
      .btn1{
        display: flex;
        justify-content: center;
        button{
            font-family: 'dm_sansregular';
          width: 300px;
         height: 50px;
         background:#00a8cb;
         border: none;
         border-radius: 8px;
         color:black;
         cursor: pointer;
         &:active {
           background-color: #00a8cb;
           color: white;
           
        }
        @media(max-width:800px){
           width:274px;
       }
       @media(max-width:670px){
           width:246px;
       }
       @media (max-width:540px){
           width:223px;
           height:40px;
       }
       @media (max-width:420px){
           width:126px;
        
       }
      }
    }
  }

}
`