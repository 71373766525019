import {
  Callerupdatebyid,
  GetDetailscaller,
} from "../../../../infrastructure/Admin/CallerExp";
import { notification } from "antd";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setdetails:
    (details) =>
    ({ setState }) => {
      setState({ details });
    },
  setcaller_id:
    (caller_id) =>
    ({ setState }) => {
      setState({ caller_id });
    },
  setiddt:
    (idd) =>
    ({ setState }) => {
      setState({ idd });
    },
  setname:
    (name) =>
    ({ setState }) => {
      setState({ name });
    },
  setaddress:
    (address) =>
    ({ setState }) => {
      setState({ address });
    },
  setphone:
    (phone) =>
    ({ setState }) => {
      setState({ phone });
    },
  setpincode:
    (pincode) =>
    ({ setState }) => {
      setState({ pincode });
    },
  setimage:
    (image) =>
    ({ setState }) => {
      setState({ image });
    },
  setimages:
    (images) =>
    ({ setState }) => {
      setState({ images });
    },

  GetCallerdetails:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetDetailscaller(id);
        dispatch(actions.setdetails(res.data.data));
        dispatch(actions.setimages(res.data.data.image));
        dispatch(actions.setname(res.data?.data?.name));
        dispatch(actions.setaddress(res.data?.data?.address));
        dispatch(actions.setphone(res.data?.data?.phone));
        dispatch(actions.setpincode(res.data?.data?.pincode));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Updatecaller:
    (id) =>
    async ({ dispatch, getState }) => {
      const { name, address, phone, pincode, image } = getState();
      dispatch(actions.setisLoading(true));
      try {
        var formData = new FormData();

        formData.append("name", name);
        formData.append("phone", phone);
        formData.append("pincode", pincode);
        formData.append("address", address);
        formData.append("image", image);
        await Callerupdatebyid(id, formData);
        notification["success"]({
            message: "success",
            description: " Updated successfully",
          });
        dispatch(actions.GetCallerdetails());
      
      } catch (err) {
        notification["warning"]({
            message: "warning",
            description: " updated Unsuccessfully",
          });
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
