import React,{useState} from "react";
import { Route, Switch } from "react-router-dom";
import FranchiseHeader from "../Header";
import SideNavFranchise from "../Sidebar";
import styled from "styled-components";
import Dashboardf from "../Dashboard";
import Driverf from "../Driver";
import Commisionf from "../Commision";
import Profile from "../Profile";
import Settlementf from "../Settlement";
import Contactf from "../Contact";
import Supportf from "../Support";
import Driverexpf from "../DriverExpnd";
import Drivercreate from "../Drivercreate";
import Errorwarning from "../Errorpage";

function Routerfranchise() {



  return (
    <div>
      <FranchiseHeader />
      <Container>
        <div>
          <SideNavFranchise />
        </div>
        <Right>
          <Switch>
            <Route exact path="/franchise/profile" component={Profile} />
            <Route exact path="/franchise/dashbord" component={Dashboardf} />
            <Route exact path="/franchise/driver" component={Driverf} />
            <Route exact path="/franchise/commision" component={Commisionf} />
            <Route exact path="/franchise/settlement" component={Settlementf} />
            <Route exact path="/franchise/contact" component={Contactf} />
            <Route exact path="/franchise/support" component={Supportf} />
            <Route
              exact
              path="/franchise/driverexpnd/:id"
              component={Driverexpf}
            />
            <Route
              exact
              path="/franchise/drivercreate/"
              component={Drivercreate}
            />
            <Route exact path="/franchise/Notfound/" component={Errorwarning} />
          </Switch>
        </Right>
      </Container>
    </div>
  );
}

export default Routerfranchise;

const Container = styled.div`
  display: flex;
`;
const Right = styled.div`
  height: 100vh;
  
  display: flex;
  justify-content: space-between;
`;
