import { GetDriverreq } from "../../../../infrastructure/Admin/DriverRequestList";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setDriverreq:
    (driverreq) =>
    ({ setState }) => {
      setState({ driverreq });
    },
  getDriverreq:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetDriverreq();
        dispatch(actions.setDriverreq(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
