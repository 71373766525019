import styled from "styled-components";
export const Arrow = styled.div`
  width: 47px;
  height: 46px;
  background: #00a8cb;;
  border-radius: 30px;
  padding: 6px 15px;
  cursor: pointer;

  button {
    background:#00a8cb;
    border-radius: 30px;
    border: none;

    opacity: 0.7;
    color: black;
  }
`;
