import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const Requeststake = createStore({
  name: "Taken Request",
  initialState,
  actions,
});

export const RequestTakenby = createHook(Requeststake, {
  selector: (state) => state,
});