import Styled from "styled-components";

export const Report = Styled.div`
display:flex;
justify-content:center;
align-items:center;
h1{

    font-size:50px;
     font-weight: bold;
}`;
