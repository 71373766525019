import React, { useEffect, useState } from "react";
import { Createdriver, Created, Created1, Created2 } from "./style";
import BackArrow from "../../Admin-franchise/Widget/common/BackArrow";
import { useHistory } from "react-router";
import { Driverlogin } from "./Store";
import { notification } from "antd";
import { Modal } from "antd";
import { DatePicker, Space } from "antd";
import moment from "moment";

function Drivercreate() {
  let history = useHistory();
  const [
    {
      firebase_email,
      firebase_password,
      confirmpass,
      name,
      phone,
      billing_address,
      pincode,
      accountnmbr,
      ifsc_code,
      bankname,
      upi,
      register_number,
      year_of_registration,
      body_color,
      max_load,
      image,
      address,
      checkbox,
      vtype,
      model,
      vehicle_model,
      isModalVisibleD,
      passwordD,
      usernameD,
      licence_number,
      licence_file,
      age,
      date_of_birth,
      referece_number,
      reference_relation,
      reference_name,
      image_left,
      image_right,
      image_back,
      noc_file,
      pcc_file,
      sex,
    },
    {
      setUsername,
      setPassword,
      setConfirmpass,
      setName,
      setPhone,
      setBillingadrs,
      setPincode,
      setAccountnumbr,
      setIfsccode,
      setBankname,
      setUpi,
      setRegisternmbr,
      setRegistartion,
      setBodycolor,
      setMaxload,
      setImage,
      Submitlogin,
      Postformlogind,
      setCheckbox,
      Postform3data,
      Getvechiclemodeltype,
      Getvechiclemodel,
      setVechiclemodel,
      setmodelid,
      setlicence_number,
      setpasswordD,
      setlicence_file,
      setage,
      setdate_of_birth,
      setreferece_number,
      setreference_relation,
      setreference_name,
      setimage_left,
      setimage_right,
      setimage_back,
      setnoc_file,
      setpcc_file,
      setusernameD,
      setIsModalVisibleD,
      setsex,
      Relogin,
    },
  ] = Driverlogin();

  let myuserfranchise = localStorage.getItem("UsernameFranchise");
  let myuserfranchisepassword = localStorage.getItem("PasswordFranchise");

  console.log(myuserfranchisepassword, "pass");
  console.log(myuserfranchise, "name");
  let username = myuserfranchise;
  let password = myuserfranchisepassword;
  localStorage.setItem("usernamedriverf", username);
  localStorage.setItem("passworddriverf", password);
  let usernameDriver = localStorage.getItem("usernamedriverf");
  let passwordDriver = localStorage.getItem("passworddriverf");
  setpasswordD(passwordDriver);
  setusernameD(usernameDriver);
  

  const [idd, setidd] = useState("");
  const [page, setpage] = useState(1);
  function goNextpage() {
    setpage((page) => page + 1);
  }
  return (
    <Createdriver>
      <div className="arrow">
        <BackArrow />
      </div>
      <div className="franchesis">
        <h1>Create Driver</h1>
        <small></small>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <div style={{ width: "60%" }}>
          <marquee behavior="scroll" direction="left">
            <small style={{ color: "red", fontSize: "22" }}>
              Please Donot Change the window until driver registration is
              finished!.........
            </small>
          </marquee>
        </div>
      </div>

      <div className="createf">
        <div className="maindiv">
          {page === 1 && (
            <Driverform1
              setpage={setpage}
              page={page}
              goNextpage={goNextpage}
              setUsername={setUsername}
              setPassword={setPassword}
              firebase_email={firebase_email}
              firebase_password={firebase_password}
              setConfirmpass={setConfirmpass}
              confirmpass={confirmpass}
              Submitlogin={Submitlogin}
              setpasswordD={setpasswordD}
              setusernameD={setusernameD}
              setIsModalVisibleD={setIsModalVisibleD}
              usernameD={usernameD}
              passwordD={passwordD}
              isModalVisibleD={isModalVisibleD}
              Relogin={Relogin}
              history={history}
            />
          )}
          {page === 2 && (
            <Driverform2
              setpage={setpage}
              page={page}
              goNextpage={goNextpage}
              Postformlogind={Postformlogind}
              firebase_email={firebase_email}
              firebase_password={firebase_password}
              address={address}
              phone={phone}
              name={name}
              bankname={bankname}
              billing_address={billing_address}
              setPhone={setPhone}
              setName={setName}
              setBillingadrs={setBillingadrs}
              setPincode={setPincode}
              picode={pincode}
              setImage={setImage}
              image={image}
              bankname={bankname}
              accountnmbr={accountnmbr}
              ifsc_code={ifsc_code}
              upi={upi}
              setBankname={setBankname}
              setaccountnmbr={setAccountnumbr}
              setIfsccode={setIfsccode}
              setupi={setUpi}
              Getvechiclemodeltype={Getvechiclemodeltype}
              vtype={vtype}
              Getvechiclemodel={Getvechiclemodel}
              setVechiclemodel={setVechiclemodel}
              model={model}
              setidd={setidd}
              idd={idd}
              setmodelid={setmodelid}
              licence_number={licence_number}
              licence_file={licence_file}
              age={age}
              date_of_birth={date_of_birth}
              referece_number={referece_number}
              reference_relation={reference_relation}
              reference_name={reference_name}
              setlicence_number={setlicence_number}
              setlicence_file={setlicence_file}
              setage={setage}
              setdate_of_birth={setdate_of_birth}
              setreferece_number={setreferece_number}
              setreference_relation={setreference_relation}
              setreference_name={setreference_name}
              setsex={setsex}
              sex={sex}
            />
          )}
          {page === 3 && (
            <Driverform3
              firebase_email={firebase_email}
              firebase_password={firebase_password}
              register_number={register_number}
              year_of_registration={year_of_registration}
              body_color={body_color}
              max_load={max_load}
              setImage={setImage}
              setCheckbox={setCheckbox}
              checkbox={checkbox}
              Postform3data={Postform3data}
              setRegistartion={setRegistartion}
              setBodycolor={setBodycolor}
              setMaxload={setMaxload}
              setRegisternmbr={setRegisternmbr}
              Created2={Created2}
           
              image={image}
        
              setimage_left={setimage_left}
              setimage_right={setimage_right}
              setimage_back={setimage_back}
              setnoc_file={setnoc_file}
              setpcc_file={setpcc_file}
      
            />
          )}
        </div>
      </div>
    </Createdriver>
  );
}

export default Drivercreate;

//form1...
function Driverform1({
  setpage,
  page,
  goNextpage,
  setPassword,
  setUsername,
  firebase_email,
  firebase_password,
  Submitlogin,
  setConfirmpass,
  confirmpass,
  setpasswordD,
  setusernameD,
  setIsModalVisibleD,
  usernameD,
  passwordD,
  isModalVisibleD,
  Relogin,
  history

}) {

  const showModalD = () => {
    setIsModalVisibleD(true);
  };

  const handleOkD = () => {
    Relogin(goNextpage);
  
    setIsModalVisibleD(false);
  };

  const handleCancelD = () => {
    setIsModalVisibleD(false);
  };
  const submitbutton = () => {
    let pswd = firebase_password;
    let cpswd = confirmpass;
    let message = document.getElementById("message");
    if (pswd.length !== 0) {
      if (pswd == cpswd) {
        message.textContent = "Password Match";
        message.style.color = "green";
        Submitlogin(showModalD);
      } else {
        message.textContent = "Password doesnt Match!!!";
        message.style.color = "red";
      }
    }
  };
  
  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <Created>
      <div className="form">
        <div className="formcreate">
          <h1>Create an Account</h1>
        </div>
        <div className="input">
          <input
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            placeholder="Enter your email"
            onChange={(e) => setUsername(e.target.value)}
            value={firebase_email}
          ></input>
          <input
            type="password"
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
            value={firebase_password}
          ></input>
          <input
            type="password"
            placeholder="Confirm your password"
            onChange={(e) => setConfirmpass(e.target.value)}
            value={confirmpass}
          ></input>
        </div>
        <small id="message" style={{ color: "black" }}></small>
        <div className="buttonfran">
          <button onClick={() => submitbutton()}>Next</button>
        </div>
      </div>
      <Modal
          title="Confirm your login"
          visible={isModalVisibleD}
          onOk={handleOkD}
          onCancel={handleCancelD}
        >
          <div style={{ display: "flex", gap: 20 }}>
            <input value={usernameD}></input>
            <input value={passwordD} type="password"></input>
          </div>
        </Modal>
    </Created>
  );
}

//form2.......................................................................................................
// const [filesize, setfilesize] = useState(3e6);

// const fileChangedHandler = (event) => {
//   let file = event.target.files[0];
//   setfilesize(file);
//   setImage(event.target.files[0]);
// let reader = new FileReader();

// console.log(file, "....................................");
// reader.onload = function (e) {
//   setImage(e.target.result);
// };
// reader.readAsDataURL(event.target.files[0]);

//  if (file !== ".png" || file !== ".jpg" ||file !== ".png") {
//       window.alert("File does not support. You must use .png or .jpg ");
//       return false;
//    }
// if (file.size > 1.5e6 || filesize === 3000000) {
//   notification["warning"]({
//     message: "warning",
//     description: "Please upload a file smaller than 1.5 MB",
//   });

//   return false;
// }
// };
function Driverform2({
  setpage,
  page,
  goNextpage,
  Postformlogind,
  firebase_email,
  firebase_password,
  phone,
  setimage,
  image,
  bankname,
  name,
  billing_address,
  setPhone,
  setName,
  setBillingadrs,
  setPincode,
  pincode,
  account_number,
  setaccountnmbr,
  bank_name,
  setBankname,
  ifsc_code,
  setIfsccode,
  upi,
  setupi,
  setImage,
  Getvechiclemodeltype,
  vtype,
  Getvechiclemodel,
  model,
  setidd,
  idd,
  setmodelid,
  licence_number,
  licence_file,
  age,
  date_of_birth,
  referece_number,
  reference_relation,
  reference_name,
  setlicence_number,
  setlicence_file,
  setage,
  setdate_of_birth,
  setreferece_number,
  setreference_relation,
  setreference_name,
  setsex,
  sex,
}) {
  const vechicletypes = (e) => {
    setidd(e);
  };
  const vechiclemodeldata = (e) => {
    setmodelid(e);
  };

  useEffect(() => {
    Getvechiclemodeltype();
    Getvechiclemodel(idd);
  }, [idd]);
  const [preview, setpreview] = useState(null);

  const uploadSingleFile = (e) => {
    setImage(e.target.files[0]);
    setpreview(URL.createObjectURL(e.target.files[0]));
  };
  let imgPreview;
  if (preview) {
    imgPreview = (
      <img src={preview} alt="" style={{ width: "100%", height: "100%" }} />
    );
  }
  function onChange(date, dateString) {
    let mydates = (date, dateString);

    setdate_of_birth(mydates);
  }

  //date validation.........................
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  }
  //validation................................................................

  const AgeHandler = (inputage) => {
    setage(inputage.replace(/[^0-9]/, ""));
  };

  const phoneNumHandler = (input) => {
    setPhone(input.replace(/[^+ 0-9]/, ""));
  };

  const picodeHandler = (input) => {
    setPincode(input.replace(/[^0-9]/, ""));
  };

  const ReferenceNumberHandler = (input) => {
    setreferece_number(input.replace(/[^+ 0-9]/, ""));
  };

  const Subbmitforms = () => {
    Postformlogind(goNextpage);
  };

  const format = "YYYY-MM-DD";
  return (
    <Created1>
      <div className="form">
        <div className="formcreate">
          <h1>Enter Basic Information</h1>
        </div>
        <div className="input">
          <input
            value={name}
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
          ></input>
          <input
            value={age}
            placeholder="Age"
            onChange={(e) => AgeHandler(e.target.value)}
          ></input>
          <input
            maxlength="13"
            value={phone}
            placeholder="Phone"
            onChange={(e) => phoneNumHandler(e.target.value)}
          ></input>
          <label>Date Of Birth</label>
          <DatePicker
            onChange={onChange}
            disabledDate={disabledDate}
            format={format}
            style={{
              width: "500px",
              borderRadius: 3.5,
              height: "6vh",
              backgroundColor: "white",
              boxShadow: "inset 0 -1px 0 #e0e0e0",
              border: "1px solid #e0e0e0",
              display: "flex",
            }}
          />
          <input
            value={pincode}
            placeholder="Pincode"
            maxlength="6"
            onChange={(e) => picodeHandler(e.target.value)}
          ></input>

          <select
            value={sex}
            onChange={(e) => setsex(e.target.value)}
            style={{
              width: 500,
              height: 50,
              // background: "white",
              border: "1px solid black",
              fontSize: "14px",
              backgroundColor: "white",
              color: "grey",
            }}
          >
            <option value="" disabled selected>
              Please Select Your Gender
            </option>
            <option value="male">Male</option>
            <option value="female">female</option>
            <option vlaue="other">Other</option>
          </select>
          <input
            type="text"
            value={reference_name}
            placeholder="Refrence Name"
            maxlength="10"
            onChange={(e) => setreference_name(e.target.value)}
          ></input>
          <input
            type="tel"
            value={referece_number}
            placeholder="Refrence Number"
            maxlength="13"
            onChange={(e) => ReferenceNumberHandler(e.target.value)}
          />
          <input
            type="text"
            value={reference_relation}
            placeholder="Refrence Relation"
            maxlength="10"
            onChange={(e) => setreference_relation(e.target.value)}
          />

          <input
            value={licence_number}
            placeholder="Licence Number"
            maxlength="16"
            onChange={(e) => setlicence_number(e.target.value)}
          ></input>
          <label>Uplode Licence File</label>
          <input
            type="file"
            name="myImage"
            onChange={(event) => {
              setlicence_file(event.target.files[0]);
            }}
          />

          {/* <input
            placeholder="Profile pic"
            className="pic"
            type="file"
            id="myFile"
            name="filename"
            inputProps={{ accept: "image/*" }}
            onChange={fileChangedHandler}
          ></input>  */}

          <input
            placeholder="Billing Address"
            onChange={(e) => setBillingadrs(e.target.value)}
            value={billing_address}
          ></input>
          <input
            placeholder="Account Number"
            onChange={(e) => setaccountnmbr(e.target.value)}
            minLength="9"
            maxLength="18"
            value={account_number}
          ></input>
          <input
            placeholder="IFSC Code"
            onChange={(e) => setIfsccode(e.target.value)}
            value={ifsc_code}
            maxLength="11"
          ></input>
          <input
            placeholder="Bank name"
            onChange={(e) => setBankname(e.target.value)}
            value={bank_name}
          ></input>

          <select
            style={{
              width: 500,
              height: 50,
              // background: "white",
              border: "1px solid black",
              fontSize: "14px",
              backgroundColor: "white",
              color: "grey",
            }}
            onChange={(e) => vechicletypes(e.target.value)}
          >
            <option value="" disabled selected>
              Select your vehicle type
            </option>
            {vtype?.map((res) => (
              <option value={res.id}>{res.model}</option>
            ))}
          </select>
          <select
            style={{
              width: 500,
              height: 50,
              // background: "white",
              border: "1px solid black",
              fontSize: "14px",
              backgroundColor: "white",
              color: "grey",
            }}
            onChange={(e) => vechiclemodeldata(e.target.value)}
          >
            <option value="" disabled selected>
              Select your vehicle Models
            </option>
            {model?.map((res) => (
              <option value={res.id}>{res.model}</option>
            ))}
          </select>
          <input
            placeholder="UPI"
            onChange={(e) => setupi(e.target.value)}
            value={upi}
          ></input>
        </div>
        <div class="upload-btn-wrapper">
          <div className="imgsec">
            <button class="btn"> Upload profile</button>
            <input
              type="file"
              id="myFile"
              name="filename"
              onChange={uploadSingleFile}
            />
            <div className="img" style={{ width: "60px", height: "60px" }}>
              {imgPreview}
            </div>
          </div>
        </div>
        <div className="buttonfran">
          <button onClick={() => Subbmitforms()}>Next</button>
        </div>
      </div>
     
    </Created1>
  );
}

//Form 3 .............................................................................................................

function Driverform3({
  register_number,
  year_of_registration,
  body_color,
  max_load,
  image,
  setImage,
  setRegisternmbr,
  setRegistartion,
  setBodycolor,
  setMaxload,
  setCheckbox,
  checkbox,
  Postform3data,
  Created2,
  history,
  passwordD,
  usernameD,
  isModalVisibleD,
  setpasswordD,
  setusernameD,

  setimage_left,
  setimage_right,
  setimage_back,
  setnoc_file,
  setpcc_file,
  Relogin,
}) {
  const checkboxfun = () => {
    setCheckbox(!checkbox);
  };
  const [preview, setpreview] = useState(null);
  const [preview2, setpreview2] = useState(null);
  const [preview3, setpreview3] = useState(null);
  const [preview4, setpreview4] = useState(null);

  //image uplode...................................................................
  const SingleFileupload = (e) => {
    setImage(e.target.files[0]);
    setpreview(URL.createObjectURL(e.target.files[0]));
  };

  let imgPreview;
  if (preview) {
    imgPreview = (
      <img src={preview} alt="" style={{ width: "100%", height: "100%" }} />
    );
  }

  const SingleFileuploadleft = (e) => {
    setimage_left(e.target.files[0]);
    setpreview2(URL.createObjectURL(e.target.files[0]));
  };

  let imgPreview2;
  if (preview2) {
    imgPreview2 = (
      <img src={preview2} alt="" style={{ width: "100%", height: "100%" }} />
    );
  }

  const SingleFileuploadright = (e) => {
    setimage_right(e.target.files[0]);
    setpreview3(URL.createObjectURL(e.target.files[0]));
  };

  let imgPreview3;
  if (preview3) {
    imgPreview3 = (
      <img src={preview3} alt="" style={{ width: "100%", height: "100%" }} />
    );
  }

  const SingleFileuploadback = (e) => {
    setimage_back(e.target.files[0]);
    setpreview4(URL.createObjectURL(e.target.files[0]));
  };

  let imgPreview4;
  if (preview4) {
    imgPreview4 = (
      <img src={preview4} alt="" style={{ width: "100%", height: "100%" }} />
    );
  }


  const subbmitform = () => {
    if (checkbox !== true) {
      notification["warning"]({
        message: "warning",
        description:
          "Please accept our terms and conditions & payment recieves",
      });
    } else if (checkbox === true) {
      Postform3data(history);
    }
  };

  // VALIDATION............................

  return (
    <Created2>
      <div className="form">
        <div className="formcreate">
          <h1>Driver Vehicle Information</h1>
        </div>
        <div className="input">
          <input
            maxLength="9"
            value={register_number}
            type="text"
            placeholder="Register number"
            onChange={(e) => setRegisternmbr(e.target.value)}
          ></input>
          <input
            maxLength="4"
            type="text"
            value={year_of_registration}
            placeholder="year of registration"
            onChange={(e) => setRegistartion(e.target.value)}
          ></input>
          <input
            type="text"
            value={body_color}
            placeholder="Body color"
            onChange={(e) => setBodycolor(e.target.value)}
          ></input>
          <input
            type="text"
            value={max_load}
            placeholder="max load"
            onChange={(e) => setMaxload(e.target.value)}
          ></input>

          <div
            class="upload-btn-wrappe"
            style={{ display: "flex", alignItems: "center", gap: "40px" }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <label>Upload Vechicle Front image</label>
              <input
                style={{ width: 300 }}
                type="file"
                onChange={SingleFileupload}
              />
            </div>

            <div
              style={{ width: "100px", height: "100px", objectFit: "cover" }}
            >
              {imgPreview}
            </div>
          </div>
          <div
            class="upload-btn-wrappe"
            style={{ display: "flex", alignItems: "center", gap: "40px" }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <label>Upload Vechicle Left image</label>
              <input
                style={{ width: 300 }}
                type="file"
                onChange={SingleFileuploadleft}
              />
            </div>

            <div
              style={{ width: "100px", height: "100px", objectFit: "cover" }}
            >
              {imgPreview2}
            </div>
          </div>
          <div
            class="upload-btn-wrappe"
            style={{ display: "flex", alignItems: "center", gap: "40px" }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <label>Upload Vechicle Right image</label>
              <input
                style={{ width: 300 }}
                type="file"
                onChange={SingleFileuploadright}
              />
            </div>

            <div
              style={{ width: "100px", height: "100px", objectFit: "cover" }}
            >
              {imgPreview3}
            </div>
          </div>
          <div
            class="upload-btn-wrappe"
            style={{ display: "flex", alignItems: "center", gap: "40px" }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <label>Upload Vechicle Back image</label>
              <input
                style={{ width: 300 }}
                type="file"
                onChange={SingleFileuploadback}
              />
            </div>

            <div
              style={{ width: "100px", height: "100px", objectFit: "cover" }}
            >
              {imgPreview4}
            </div>
          </div>

          <label>Uplode NOC File</label>
          <input
            type="file"
            name="myImage"
            onChange={(event) => {
              setnoc_file(event.target.files[0]);
            }}
          />
          <label>Uplode PCC File</label>
          <input
            type="file"
            name="myImage"
            onChange={(event) => {
              setpcc_file(event.target.files[0]);
            }}
          />
          <div className="terms">
            <label>Accept terms and conditions and Payment recieved</label>
            <input
              className="check"
              value={checkbox}
              placeholder="max load"
              type="checkbox"
              onClick={() => checkboxfun()}
            ></input>
          </div>
        </div>

        <div className="buttonfran">
          <button onClick={() => subbmitform()}>Finish</button>
        </div>
      
      </div>
    </Created2>
  );
}
