import React, { useState, useEffect } from "react";
import { Reg } from "./style";
import { Divider } from "antd";
import { useHistory } from "react-router";

import { MapFranchise } from "./store";
import { SearchOutlined, CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { Empty, Input } from "antd";
import Spinner from "../Widget/common/Spinner";
import { firebase } from "../../../Common/firebase";
function Franchisis() {
  let history = useHistory();

  const create = () => {
    history.push(`/admin/franchiseExp/`);
  };
  const [
    { isLoading, franUser, franUsername, franUserphone, franUserid },
    { GetFranchise, setfranname, setfranphone, setfranId },
  ] = MapFranchise();



  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);
  useEffect(() => {
    GetFranchise();
  }, [franUsername, franUserphone, franUserid, token]);

  const Franchidedetailspage = (id) => {
    history.push(`/admin/franchiseDetail/${id}`);
  };

  //search reaquest by id ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
  const [hideI, sethideI] = useState(true);
  const hiddenI = () => {
    sethideI(!hideI);
    setfranId("");
  };
  const Searchrequestbyid = (e) => {
    setfranId(e.target.value);
  };
  //search request by name...............................................
  const [hidenn, sethiden] = useState(true);
  const hiddenName = () => {
    sethiden(!hidenn);
    setfranname("");
  };

  const Searchrequestname = (e) => {
    setfranname(e.target.value);
  };
  //search request by phone...............................................
  const [hideP, sethideP] = useState(true);
  const hiddenP = () => {
    sethideP(!hideP);
    setfranphone("");
  };
  const Searchrequestphone = (e) => {
    setfranphone(e.target.value);
  };
  // if (isLoading) {
  //   return <Loader />;
  // }
  return (
    <Reg>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Franchises</h2>
      </div>
      <div className="franchises">
        <div className="heading">
          <h1>Franchises</h1>
          <Divider orientation="left" />
        </div>
      </div>
      <div className="button">
        <button type="button" onClick={() => create()}>
          Create
        </button>
      </div>

      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No:</h1>
                </th>
                <th className="head-off">
                  <h1>
                    ID{" "}
                    {hideI ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenI()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestbyid(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenI()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>

                <th className="head-off">
                  <h1>
                    Name{" "}
                    {hidenn ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenName()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestname(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenName()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Phone
                    {hideP ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenP()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestphone(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenP()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>

                <th className="head-off">
                  <h1>Pincode</h1>
                </th>
                <th className="head-off">
                  <h1>Total drivers</h1>
                </th>
              </tr>
            </thead>

            <tbody>
              {franUser?.map((res, i) => (
                <tr className="body-row-off">
                  <td onClick={() => Franchidedetailspage(res.id)}>{i + 1}</td>
                  <td onClick={() => Franchidedetailspage(res.id)}>{res.id}</td>
                  <td onClick={() => Franchidedetailspage(res.id)}>
                    {res.name}
                  </td>
                  <td onClick={() => Franchidedetailspage(res.id)}>
                    <h1>{res.phone}</h1>
                  </td>

                  <td onClick={() => Franchidedetailspage(res.id)}>
                    {" "}
                    {res.pincode}{" "}
                  </td>
                  <td onClick={() => Franchidedetailspage(res.id)}>
                    {" "}
                    {res.total_drivers}{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Reg>
  );
}

export default Franchisis;
