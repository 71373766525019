import { GetCallerprofiles } from "../../../../infrastructure/Caller/Profile";

const actions = {
    setisLoading:
      (isLoading) =>
      ({ setState }) => {
        setState({ isLoading });
      },
      setprofiles:
      (profiles) =>
      ({ setState }) => {
        setState({ profiles });
      },
      getprofiles:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
     
      try {
        const res = await GetCallerprofiles();
 
        dispatch(actions.setprofiles(res.data.data));
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
    }
    export default actions;