import {createStore,createHook,defaults} from 'react-sweet-state';
import initialState from './initialState'
import actions from './actions'

defaults.devtools=true;
const Adminlist = createStore ({
  name:"users",
  initialState,
  actions,
});
export const Createadminget= createHook(Adminlist,{
  selector:(state) =>state,
});
