import { GetSupportdetails } from "../../../../infrastructure/Caller/SupportReqexp";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setDetails:
    (details) =>
    ({ setState }) => {
      setState({ details });
    },
  getCallerdetails:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetSupportdetails(id);
        dispatch(actions.setDetails(res.data.data));
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
