import React, { useEffect, useState } from "react";
import { HeaderNav } from "./style";
import Logo from "../../../Images/LogoDelibuoy.png";
import { useLocation } from "react-router";
import { useHistory } from "react-router";
import Navbar from "../Navbar";
import { AlignCenterOutlined } from "@ant-design/icons";
function HeaderDelibuoy() {
  let history = useHistory();
  const contactlink = () => {
    history.push(`/contact`);
  };

  const Homelink = () => {
    history.push(`/home`);
  };


  const [ontop, setontop] = useState(true);
  const scrollbar = () => {
    if (window.scrollY > 0) {
      setontop(false);
    } else {
      setontop(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollbar);
    return () => window.removeEventListener("scroll", scrollbar);
  }, []);
  return (
    <>
      <HeaderNav>
        {ontop ? (
          <div className="top">
            <div className="main">
              <img src={Logo} alt="logo"></img>
              {/* <small style={{color: 'white',fontSize: '12px',letterSpacing:".5px",opacity:".6"}}>worth waiting for Delibuoy....</small>  */}
            </div>

            <ul>
              <li onClick={() => Homelink()}>Home</li>
              <a href="#about">
                <li >About Us</li>
              </a>
              <li onClick={() => contactlink()}>Contact Us</li>
            </ul>
            <ul className="hamburger">
              <li>
                <AlignCenterOutlined />
              </li>
            </ul>
          </div>
        ) : (
          <div>
            {" "}
            <Navbar />
          </div>
        )}
      </HeaderNav>
    </>
  );
}

export default HeaderDelibuoy;
