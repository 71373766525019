import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const Admincommision = createStore({
  name: "commision",
  initialState,
  actions,
});
export const GetCommisiondetails = createHook(Admincommision, {
    selector: (state) => state,
  });