import React, { useEffect,useState } from "react";
import { SupportDiv } from "./style";
import { LeftOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { Supportrequestdetail } from "./store";
import { useHistory } from "react-router-dom";
import { firebase } from "../../../Common/firebase";
import Loader from '../../Caller/Widget/Common/Loader'
function SupportRequest() {
  const [{ isLoading, supportrequest }, { getsupportrequest, Postrequest ,Postrequestforsolved}] =
    Supportrequestdetail();
  let history = useHistory();
  const SupportreqExp = (id) => {
    history.push(`/caller/supportrequestExp/${id}`);
  };
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, []);
  localStorage.setItem("token", token);

  useEffect(() => {
    getsupportrequest();
  }, [token]);
  if(isLoading) {
    return <Loader/>
}
  return (
    <SupportDiv>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Support request</h2>
      </div>
      <div className="support">
        <div className="heading">
          <h1>Support request</h1>
          <Divider orientation="left" />
        </div>
      </div>

      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No:</h1>
                </th>
               
                <th className="head-off">
                  <h1>User type</h1>
                </th>
                <th className="head-off">
                  <h1>Name</h1>
                </th>

                <th className="head-off">
                  <h1>Phone</h1>
                </th>
                <th className="head-off">
                  <h1>Subject</h1>
                </th>

                <th className="head-off">
                  <h1>Taken</h1>
                </th>
                <th className="head-off">
                  <h1>Solved</h1>
                </th>
              </tr>
            </thead>

            <tbody>
              {supportrequest?.map((res, i) => (
                <tr className="body-row-off">
                  <td onClick={() => SupportreqExp(res.id)}>{i + 1}</td>
             
                  <td onClick={() => SupportreqExp(res.id)}>{res.user_type}</td>
                  <td onClick={() => SupportreqExp(res.id)}>{res.name}</td>
                  <td onClick={() => SupportreqExp(res.id)}>{res.phone}</td>
                  <td onClick={() => SupportreqExp(res.id)}>{res.subject} </td>
                  <td>
                    {res.taken === true ? (
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "#00a8cb",
                          padding: "5px",
                          width: "100px",
                          boxShadow:
                            " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          color: "white",
                        }}
                        onClick={() => Postrequest(res.id)}
                      >
                        Taken
                      </button>
                    ) : (
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "green",
                          padding: "5px",
                          width: "100px",
                          boxShadow:
                            " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          color: "white",
                        }}
                        onClick={() => Postrequest(res.id)}
                      >
                        Not-Taken
                      </button>
                    )}
                  </td>
                  <td>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "green",
                        padding: "5px",
                        width: "60px",
                        boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        color: "white",
                      }}
                      onClick={() =>Postrequestforsolved(res.id)}
                    >
                      Solved
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </SupportDiv>
  );
}

export default SupportRequest;
