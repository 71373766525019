import {
  GetRazorpayres,
  Getsettlementsres,
  Settelementpay,
} from "../../../../infrastructure/Admin-franchise/settlements";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setsettlements:
    (settlements) =>
    ({ setState }) => {
      setState({ settlements });
    },
  setdueamount:
    (dueamount) =>
    ({ setState }) => {
      setState({ dueamount });
    },
  setRazorpay:
    (razorpayments) =>
    ({ setState }) => {
      setState({ razorpayments });
    },
  setRazorpaykey:
    (razorpaymentskey) =>
    ({ setState }) => {
      setState({ razorpaymentskey });
    },

  setRazorpayorderid:
    (razorpay_order_id) =>
    ({ setState }) => {
      setState({ razorpay_order_id });
    },

  setRazorpaypaymentid:
    (razorpay_payment_id) =>
    ({ setState }) => {
      setState({ razorpay_payment_id });
    },
  getsettlemets:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await Getsettlementsres();

        dispatch(actions.setsettlements(res?.data.data));
        dispatch(actions.setdueamount(res?.data.data.due_amount));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  getRazorpaydata:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        let res = await GetRazorpayres();
        dispatch(actions.setRazorpay(res?.data));
        dispatch(actions.setRazorpaykey(res?.data.razorpay_key_id));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  Postsettlement:
    () =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));

      const { razorpay_order_id, razorpay_payment_id } = getState();
      var bodyFormdata = new FormData();
      bodyFormdata.append("razorpay_order_id", razorpay_order_id);
      bodyFormdata.append("razorpay_payment_id", razorpay_payment_id);
      try {
        await Settelementpay(bodyFormdata);
        dispatch(actions.getsettlemets());
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
