import React from "react";
import { Footer } from "./style";
import Appstore from "../../../Images/playstoreuser.png";
import Playstore from "../../../Images/playstoredriver.png";
import { useHistory, Link } from "react-router-dom";
function Footerweb() {
  let history = useHistory();

  return (
    <Footer>
      <div className="mainf">
        {/* <div className="itemsf">
          <ul>
            <li className="defaultf">About Us</li>
           
          </ul>
        </div> */}
        <div className="itemsf">
          <ul>
            <li className="defaultf">Contact Us </li>
            <li className="listf">
              39/2701, 1st Floor Pallickel Building,
              <br />
              (75) South janatha road, Palarivattom,
              <br /> Ernakulam, Kochi, 682025, Kerala, IN
            </li>
            <li className="listf">04842998662</li>
            <a href="mailto:info@Delibuoy.com" target='blank' style={{textDecoration:"none"}}>
            <li className="listf"> info@Delibuoy.com</li></a>
          </ul>
        </div>
        <div className="itemsf">
          <ul>
            <li className="defaultf">Legal </li>
            <Link
              to={{ pathname: "/termsandconditions" }}
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <li className="listf">Terms and conditions</li>
            </Link>
            <Link
              to={{ pathname: "/privacyPolicy" }}
              style={{ color: "unset", textDecoration: "none" }}
            >
              <li className="listf">Privacy policy</li>
            </Link>
            <Link
              to={{ pathname: "/shippingPolicy" }}
              style={{ color: "unset", textDecoration: "none" }}
            >
              <li className="listf">Shipping and Policy</li>
            </Link>
            <Link
              style={{ color: "unset", textDecoration: "none" }}
              to={{ pathname: "/cancellationPolicy" }}
            >
              <li className="listf">Return and Cancelation Policy</li>
            </Link>
          </ul>
        </div>
      </div>
      <div className="appimg">
      <a href="https://play.google.com/store/apps/details?id=apps.quadauq.delibuoy.user" target="_blank"><img src={Appstore} alt="" /></a>   
      <a href="https://play.google.com/store/apps/details?id=apps.quadauq.delibuoy.driver" target="_blank">    <img src={Playstore} alt="" /></a>
      </div>
    </Footer>
  );
}

export default Footerweb;
