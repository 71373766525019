import { get,put } from "../Common/remote/base_api";
export const Getdriverexpfranchise = (id) => {
  return get(`franchise/get-driver-details/${id}/`);
};

export const DriverUpdatebyid = (id,data) => {
  return put(`franchise/update-driver-basic/${id}/`,data);
};

export const VehicleUpdatebyid = (id,data) => {
  return put(`franchise/update-driver-vehicle-info/${id}/`,data);
};
