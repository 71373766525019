const initialState = {
  isLoading: true,
  settlements: [],
  dueamount:"",
  razorpayments:"",
  razorpaymentskey:"",
  razorpay_order_id:"",
  razorpay_payment_id:"",

};

export default initialState;
