import React, { useEffect, useState } from "react";
import Loader from "../../Admin/Widget/common/Loader";
import { MyFranchiseprofile } from "./store";
import { Profiledashboard } from "./style";
import { firebase } from "../../../Common/firebase";
import { Tag } from "antd";
function Profile() {
  const [{ isLoading, profile }, { getuserprofile, setProfile }] =
    MyFranchiseprofile();
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);

  useEffect(() => {
    getuserprofile();
  }, [token]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Profiledashboard>
      <div className="profile">
        <img src={profile.image} alt="img"></img>
      </div>

      <div className="main">
        <div className="section">
          <div className="small">
            <div className="item">
              <div className="name">
                <h1>ID </h1>
                <span>:</span>
                <h2 style={{ color: "green", fontWeight: "bold" }}>
                  {" "}
                  {profile.id}{" "}
                </h2>
              </div>
              <div className="name">
                <h1>Name </h1>
                <span>:</span>
                <h2> {profile.name} </h2>
              </div>
              <div className="name">
                <h1>Billing Address</h1>
                <span>:</span>

                <h2>{profile.billing_address}</h2>
              </div>
              <div className="name">
                <h1>Phone </h1>
                <span>:</span>
                <h2>{profile.phone}</h2>
              </div>
              <div className="name">
                <h1>Pincode</h1>
                <span>:</span>
                <h2>{profile.pincode}</h2>
              </div>
              <div className="name">
                <h1>Email</h1>
                <span>:</span>
                <h2>{profile.email}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profileinfo">
        <h1>Account info</h1>
        <small></small>
      </div>
      <div className="main">
        <div className="section">
          <div className="small">
            <div className="item">
              <div className="name">
                <h1>Account number </h1>
                <span>:</span>
                <h2> {profile?.franchise_account_info?.account_number} </h2>
              </div>
              <div className="name">
                <h1>IFSC Code</h1>
                <span>:</span>

                <h2>{profile?.franchise_account_info?.ifsc_code}</h2>
              </div>
              <div className="name">
                <h1>Bank name </h1>
                <span>:</span>
                <h2>{profile?.franchise_account_info?.bank_name}</h2>
              </div>
              <div className="name">
                <h1>Bank branch</h1>
                <span>:</span>
                <h2>{profile?.franchise_account_info?.bank_branch}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profileinfo">
        <h1>Owner information</h1>
        <small></small>
      </div>
      <div className="main2">
        <div className="section">
          <div className="small">
            <div className="item">
              <div className="name">
                <h1>Owner Name </h1>
                <span>:</span>
                <h2> {profile?.franchise_owner_info?.owner_name} </h2>
              </div>
              <div className="name">
                <h1>ID Proof</h1>
                <span>:</span>

                <h2>{profile?.franchise_owner_info?.owner_id_proof}</h2>
              </div>
              <div className="name">
                <h1>ID Proof Number </h1>
                <span>:</span>
                <h2>{profile?.franchise_owner_info?.id_proof_number}</h2>
              </div>
              <div className="name">
                <h1>Pan Number</h1>
                <span>:</span>
                <h2>{profile?.franchise_owner_info?.pan_number}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Profiledashboard>
  );
}

export default Profile;
