import { Getsettlementsres } from "../../../../infrastructure/Admin/SettlementDues";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setsettlementdata:
    (settlementdata) =>
    ({ setState }) => {
      setState({ settlementdata });
    },
  setfranchise_id:
    (franchise_id) =>
    ({ setState }) => {
      setState({ franchise_id });
    },
  setfranchise_name:
    (franchise_name) =>
    ({ setState }) => {
      setState({ franchise_name });
    },
  GetSettlementDues:
    () =>
    async ({ setState, dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      try {
        const{franchise_id,franchise_name}=getState();
        let res = await Getsettlementsres(franchise_id,franchise_name);
        dispatch(actions.setsettlementdata(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
