import React, { useEffect, useState } from "react";
import { Main } from "./style";
import { useLocation } from "react-router-dom";
import { Myadminlogin } from "./store";
import { useHistory } from "react-router-dom";

function Login() {
  let location = useLocation();
  const [
    { UsernameL, PasswordL },
    { setUsernameL, setPasswordL, Submitlogins },
  ] = Myadminlogin();

  let history = useHistory();
  // useEffect(() => {
  //   window.addEventListener("keypress", (e) => {
  //     if (e.key === "Enter" && location.pathname === `/admin`) {
  //
  //       return () => window.removeEventListener("keypress");
  //     }
  //   });
  // }, []);

  localStorage.setItem("usernameLogin", UsernameL);
  localStorage.setItem("passwordLogin", PasswordL);
  function handleKeyPress(e) {
    if (e.key === "Enter") {
      if (UsernameL !== "" && PasswordL !== "") {
        Submitlogins(history);
      }
    }
  }

  const handleChange = (e) => {
 
    e.preventDefault();
  
  };

  return (
    <Main>
      <div className="box">
        <div className="heading">
          <h1>Delibuoy</h1>
        </div>
        <div className="inpt">
          <p>Login to Admin</p>
          <input
            className="small"
            type="username"
            value={UsernameL}
            placeholder="Enter your username"
            onKeyPress={(e) => handleKeyPress(e)}
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            onChange={(event) => {
              setUsernameL(event.target.value);
            }}
          />
          <input
            classNmae="small"
            value={PasswordL}
            type="password"
            placeholder="Enter your password"
            onChange={(event) => {
              setPasswordL(event.target.value);
            }}
            onKeyPress={(e) => handleKeyPress(e)}
          />
        </div>

        <div className="button">
          <button onClick={() => Submitlogins(history)}> Login</button>
        </div>
      </div>
    </Main>
  );
}

export default Login;
