import styled from "styled-components";
export const FCreate = styled.div`
  margin-left: 15vw;
  margin-top: 50px;

  width: 82vw;

  .arrow {
    margin-top: 60px;
    padding-left: 50px;
  }
  .franchesis {
    display: flex;
    padding-left: 80px;
    margin-top: 50px;
    margin-bottom: 100px;
    align-items: center;
    gap: 15px;
    h1 {
      font-family: "Quicksand", sans-serif;     
      font-size: 27px;
      font-weight: bold;
      width: 360px;
    }
    small {
      border: 1px solid #f0f0f0;
      height: 1px;
      width: 100%;
    }
  }
  .createf {
    display: flex;
    justify-content: center;
    .maindiv {
      width: 45%;

      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      margin-bottom: 100px;
    }
  }
`;

export const Createf = styled.div`
  margin-top: 50px;
  .form {
    .formcreate {
      height: 2px;
      h1 {
        font-size: 28px;
        font-family: "montserratregular";
        font-weight: bold;
      }
    }
    .input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      gap: 17px;
      input {
        border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid grey;
        height: 6vh;
        background: white;
        box-shadow: inset 0 -1px 0 #00a8cb;
        width: 90%;
      }
    }
    .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display:flex;
      justify-content:center;
   
      margin-top:15px;
      .imgsec{
        display:flex;
        justify-content:space-between;
        width:90%;
      }
    }
    
    .btn {
      border: 2px solid gray;
      color: gray;
      background-color: white;
      width:130px;
      height:30px;
      margin-left:13px;
      border-radius: 8px;
      font-size: 10px;
      font-weight: bold;
      justify-content:start;
    }
    
    .upload-btn-wrapper input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
  }
}
    .buttonfran {
      margin-top: 20px;
      margin-bottom: 30px;
      button {
        width: 100px;
        height: 50px;
        border: none;
        background-color: #00a8cb;
        border-radius: 4px;
        &:active {
          background-color: black;
          color: white;
        }
      }
    }
  }
`;
export const Createf2 = styled.div`
  margin-top: 50px;
  .form {
    .formcreate {
      h1 {
        font-size: 28px;
        font-family: "montserratregular";
        font-weight: bold;
      }
    }
    .input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      gap: 17px;
      input {
        border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid grey;
        height: 6vh;
        background: white;
        box-shadow: inset 0 -1px 0 #00a8cb;
        width:90%;
      }
    }
    .buttonfran {
      margin-top: 20px;
      margin-bottom: 30px;
      button {
        width: 100px;
        height: 50px;
        border: none;
        background-color: #00a8cb;
        border-radius: 4px;
        &:active {
          background-color: black;
          color: white;
        }
      }
    }
  }
`;
export const Createf3 = styled.div`
  margin-top: 50px;
  .form {
    .formcreate {
      h1 {
        font-size: 28px;
        font-family: "montserratregular";
        font-weight: bold;
      }
    }
    .input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      gap: 17px;
      input {
        border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid grey;
        height: 6vh;
        background: white;
        box-shadow: inset 0 -1px 0 #00a8cb;
        width:90%;
      }
    }
    .buttonfran {
      margin-top: 20px;
      margin-bottom: 30px;
      button {
        width: 100px;
        height: 50px;
        border: none;
        background-color: #00a8cb;
        border-radius: 4px;
        &:active {
          background-color: black;
          color: white;
        }
      }
    }
  }
 
  
`;
export const Createf4 = styled.div`
  margin-top: 50px;
  .form {
    .formcreate {
      h1 {
      }
    }
    .input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      gap: 17px;
      select {
        height: 43px;
        width: 81%;
        background: #dce7ec;
        border: none;
      }
      input {
        border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid grey;
        height: 6vh;
        background: white;
        box-shadow: inset 0 -1px 0 #00a8cb;
        width:90%;
      }
    select{
        border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid grey;
        height: 6vh;
        background: white;
        box-shadow: inset 0 -1px 0 #00a8cb;
        width:90%;
      }
    }
    .buttonfran {
      margin-top: 20px;
      margin-bottom: 30px;
      button {
        width: 100px;
        height: 50px;
        border: none;
        background-color: #00a8cb;
        border-radius: 4px;
        &:active {
          background-color: black;
          color: white;
        }
      }
    }
  }
`;
