import { get,post } from "../common/remote/base_api";

export const GetSupportrequset = (data) => {
  return get(`caller/get-support-requests/`,data);
};
export const Posttakerequest = (data) => {
  return post(`caller/take-request/`,data);
};
export const Postsolverequest = (data) => {
  return post(`caller/solve-request/`,data);
};
