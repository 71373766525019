import { GetCommissionres } from "../../../../infrastructure/Admin-franchise/commission";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setcommision:
    (commision) =>
    ({ setState }) => {
      setState({ commision });
    },
  GetCommisionres:
    () =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setisLoading(true));
      try {
        let res = await GetCommissionres();
        dispatch(actions.setcommision(res.data.data));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
};
export default actions;
