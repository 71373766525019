import styled from "styled-components";
export const SettlementDiv = styled.div`
width: 85vw;
margin-left: 15vw;
margin-top:80px;
.title{
    display:flex;
     justify-content:space-between;
     margin-top:40px;
     gap:10px;
     .heading{
       display:flex;
       margin-right:30px;
    h1{
      font-size:14px;
      color:grey;
    }
    h2{
      font-size:14px;
      color:grey;
    }
  }
  
  .backarrow {
    padding-left: 30px;
    margin-bottom: 50px;
    justify-content:flex-start;
  }
  }
 .main{
     width:80%;
     margin-top:10px;
     justify-content:center;
     .heading{
        margin-left: 40px;
         display:flex;
         font-family: "montserratregular";
         align-items:center;
         h1{
             font-size:18px;
             text-align:center;
             font-weight:bold;
            
         }
         small{
             width:800px;
             height:1px;
             background-color:#f0f0f0;
             margin-top:10px;
         }
         
     }
     .section{
         display:flex;
         width:100%;
         justify-content:space-between;
         margin-top:30px;
         margin-left:80px;
         margin-bottom:30px;
         .small{
             display:flex;
             justify-content:space-between;
             .item{
                  display:flex;
                  flex-direction:column;
                  justify-content:space-between;
                  width:577px;
                .name{
                     display:flex;
                     text-align:start;
                     justify-content:space-between;
                     h1{
                         font-size:16px;
                         width:150px;
                         font-weight:bold;
                         font-family: "dm_sansregular";
                     }
                     h2{
                         font-size:16px;
                         width:250px;
                         font-family: "dm_sansregular";
                       
                     }
                     span{
                         width:20px;
                     }
                 }
             }
             
          .heading{
              display:flex;
              width:200px;
              align-items:center;
          }
         
        }
        
     }
    
`;
