import styled from "styled-components";
export const HomeBackground = styled.div`

  height: 500px;
  display: flex;
  justify-content: center;
  @media(max-width:740px){
    height: unset;
  }
  .top {
    width: 80%;
    display: flex;
    gap:400px;
    @media(max-width:1280px){
      gap:200px;
    }
    @media(max-width:980px){
      gap:100px;
    }
    @media(max-width:740px){
      flex-direction: column;
    }
align-items: center;
    .left {
      width: 500px;
      @media(max-width:740px){
        width: 400px;
        padding-top:50px;
      }
      @media(max-width:540px){
        width: 350px;
 
      }
      @media(max-width:440px){
        width: 300px;
 
      }
      img {
        width: 100%;
        
    }
      }
      .right{
        width: 500px;
        @media(max-width:640px){
          width: 400px;
        }
        @media(max-width:440px){
          width: 300px;
        }
        @media(max-width:380px){
          width: 280px;
        }
        h1{
          font-family: "pt_serifbold";
          font-size:28px;
          font-weight:bold;
        }
        p{
          
          font-family: "dm_sansregular";
          text-align: justify;
          font-size:16px;
        }
      }
    }
  }
`;
