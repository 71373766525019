import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const chargesdelibuoy = createStore({
  name: "charges",
  initialState,
  actions,
});

export const GetCharges = createHook(chargesdelibuoy, {
  selector: (state) => state,
});
