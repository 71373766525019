import styled from "styled-components";
export const Profiledashboard = styled.div`
  margin-left: 15vw;
  margin-top: 80px;
  // background-color: #d3e3fc;
  width:82vw;
  .profile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
     padding-top:20px;
    
    img {
        width:200px;
        height:200px;
        border-radius: 100px;
        border: aliceblue;
        border: 2px solid mistyrose;
    }
  }
  .main {
    .section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 40px;
      padding-left: 80px;
      margin-bottom: 30px;
      .small {
        display: flex;
        justify-content: space-between;
        .item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 577px;
          .name {
            display: flex;
            text-align: start;
            justify-content: space-between;
            h1 {
              font-size: 16px;
              width: 150px;
              font-weight: bold;
              font-family: "dm_sansregular";
            }
            h2 {
              font-size: 16px;
              width: 250px;
              font-family: "dm_sansregular";
            }
            span {
              width: 20px;
            }F
          }
        }
      }
    }
  }
  .profileinfo{
      display: flex;
      align-items: center;
      h1{
          display:flex;
           padding-left:20px;
          font-size:24px;
          width:230px;
      }
      small{
          height:1px;
          width:100%;
          border:1px solid #dedede;
      }
  }
  .main2 {
    .section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 40px;
      padding-left: 80px;
      margin-bottom: 30px;
      .small {
        display: flex;
        justify-content: space-between;
        .item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 577px;
          .name {
            display: flex;
            text-align: start;
            justify-content: space-between;
            h1 {
              font-size: 16px;
              width: 150px;
              font-weight: bold;
              font-family: "dm_sansregular";
            }
            h2 {
              font-size: 16px;
              width: 250px;
              font-family: "dm_sansregular";
            }
            span {
              width: 20px;
            }
          }
        }
      }
    }
  }
  .profileinfo{
      display: flex;
      align-items: center;
      h1{
          display:flex;
           padding-left:20px;
          font-size:24px;
          width:280px;
      }
      small{
          height:1px;
          width:100%;
          border:1px solid #dedede;
      }
  }

`;
