import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import Loader from "../Widget/common/Loader";
import { SearchOutlined, CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { ChargesDiv, ModalchargesDiv } from "./style";
import { GetCharges } from "./store";
import { Modal, Button } from "antd";
import { firebase } from "../../../Common/firebase";
function Delibuoycharges() {
  const [
    {
      isLoading,
      Charges,
      service_charge,
      delibuoy_commission,
      franchise_commission,
      driver_registration_fee,
      franchise_driver_registration_share,
      delibuoy_driver_registration_share,
    },
    {
      GetDelibuoycharges,
      setServiceCharge,
      setdelibuoy_commission,
      setfranchise_commission,
      setdriver_registration_fee,
      setfranchise_driver_registration_share,
      setdelibuoy_driver_registration_share,
      Updatecharges,
    },
  ] = GetCharges();
  console.log(Charges,'..charges');
  console.log(delibuoy_commission, "servicechargeservicechargeservicecharge");
  const [ischarger, setIsCharger] = useState(false);
  const showModal = () => {
    setIsCharger(true);
  };

  const handleOk = () => {
    Updatecharges();
    setIsCharger(false);
  };

  const handleCancel = () => {
    setIsCharger(false);
  };
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);

  useEffect(() => {
    GetDelibuoycharges();
  }, [token]);
  if(isLoading){

      return <Loader/>
  }
  return (
    <ChargesDiv>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Driver</h2>
      </div>
      <div className="driver">
        <div className="heading">
          <h1>Drivers</h1>
          <Divider orientation="left" />
        </div>
      </div>
      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>Service charge</h1>
                </th>

                <th className="head-off">
                  <h1>Delibuoy Commission</h1>
                </th>
                <th className="head-off">
                  <h1>Franchise Commission</h1>
                </th>
                <th className="head-off">
                  <h1>Driver Reg Fee</h1>
                </th>
                <th className="head-off">
                  <h1>Franchise Driver Reg Share</h1>
                </th>
                <th className="head-off">
                  <h1>Delibuoy Driver Reg Share</h1>
                </th>
                <th className="head-off">
                  <h1></h1>
                </th>
                <th className="head-off">
                  <h1></h1>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className="body-row-off">
                <td>{Charges?.service_charge}</td>
                <td>{Charges?.delibuoy_commission}</td>
                <td>{Charges?.franchise_commission}</td>
                <td>{Charges?.driver_registration_fee}</td>
                <td>
                  <h1>{Charges?.franchise_driver_registration_share}</h1>
                </td>
                <td>
                  <h2>{Charges?.delibuoy_driver_registration_share}</h2>
                </td>
                <td></td>
                <td>
                  <button
                    style={{
                      backgroundColor: "#00a8cb",
                      width: "80px",
                      height: "30px",
                      borderRadius: 30,
                      border: "none",
                    }}
                    onClick={() => showModal()}
                  >
                    update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        title="UpDate Delivery Charges"
        visible={ischarger}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ModalchargesDiv>
          <div className="sep">
            <label>Service Charge</label>
            <input
              type="text"
              value={service_charge}
              onChange={(e) => setServiceCharge(e.target.value)}
            ></input>
          </div>
          <div className="sep">
            <label>Delibuoy Commission</label>
            <input
              type="text"
              value={delibuoy_commission}
              onChange={(e) => setdelibuoy_commission(e.target.value)}
            ></input>
          </div>
          <div className="sep">
            <label>Franchise Commission</label>
            <input
              type="text"
              value={franchise_commission}
              onChange={(e) => setfranchise_commission(e)}
            ></input>
          </div>
          <div className="sep">
            <label>Driver Registration Fee</label>
            <input
              type="text"
              value={driver_registration_fee}
              onChange={(e) => setdriver_registration_fee(e.target.value)}
            ></input>
          </div>
          <div className="sep">
            <label>Franchise Driver Registration Share</label>
            <input
              type="text"
              value={franchise_driver_registration_share}
              onChange={(e) =>
                setfranchise_driver_registration_share(e.target.value)
              }
            ></input>
          </div>
          <div className="sep">
            <label>Delibuoy Driver Registration Share</label>
            <input
              value={delibuoy_driver_registration_share}
              type="text"
              onChange={(e) =>
                setdelibuoy_driver_registration_share(e.target.value)
              }
            ></input>
          </div>
        </ModalchargesDiv>
      </Modal>
    </ChargesDiv>
  );
}

export default Delibuoycharges;
