import styled from "styled-components";
export const Reqexp =styled.div`
display: flex;
flex-direction:column;
margin-top:80px;
margin-left: 15vw;
width: 82vw;
.title{
  display:flex;
   justify-content:flex-end;
   margin-right:200px;
   margin-top:40px;
   gap:10px;
  h1{
    font-size:14px;
    color:grey;
  }
  h2{
    font-size:14px;
    color:grey;
  }
}
.arrow{

    margin-left:20px;
    margin-bottom: 30px;
}
.main{
   width:80%;
   margin-top:10px;
 
   .heading{
      margin-left: 40px;
       display:flex;
       font-family: "montserratregular";
       align-items:center;
       h1{
           font-size:18px;
           text-align:center;
           font-weight:bold;
           width: 220px;
          
       }
       small{
           width:800px;
           height:1px;
           background-color:#f0f0f0;
           margin-top:10px;
       }
       
   }
   .section{
       display:flex;
       width:100%;
       justify-content:space-between;
       margin-top:30px;
       margin-left:80px;
       margin-bottom:30px;
       .small{
           display:flex;
          justify-content:space-between;
           .item{
                display:flex;
                flex-direction:column;
                justify-content:space-between;
                width:577px;
              .name{
                   display:flex;
                   text-align:start;
                   justify-content:space-between;
                   h1{
                       font-size:16px;
                       width:150px;
                       font-weight:bold;
                       font-family: "dm_sansregular";
                   }
                   h2{
                       font-size:16px;
                       width:250px;
                       font-family: "dm_sansregular";
                       color:grey;
                     
                   }
                   span{
                       width:20px;
                   }
               }
           }
           
        .heading{
            display:flex;
            width:200px;
        }
       
      }
      
   }
`