import React, { useState } from "react";

import { FCreate, Createf, Createf2, Createf3 } from "./style";
import { useHistory } from "react-router";

import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { Franchesisloginexp } from "./store";
import BackArrow from "../Widget/common/BackArrow";
import { notification } from "antd";

import { Modal } from "antd";

function FranchiseCreate() {
  let history = useHistory();
  const [
    {
      firebase_email,
      firebase_password,
      address,
      phone,
      confirmpass,
      name,
      billing_address,
      latitude,
      account_number,
      ifsc_code,
      bank_name,
      bank_branch,
      pincode,
      owner_name,
      owner_id_proof,
      id_proof_number,
      pan_number,
      id_proof_file,
      pan_file,
      image,
      usernameF,
      passwordF,
      isModalVisibleF,
    },
    {
      setUsername,
      setPassword,
      setAddress,
      setphone,
      setimage,
      setname,
      setCordinates,
      setlngadrs,
      setbillingAdrs,
      setlatdrs,
      Submitlogin,
      Postformlogin,
      Postformlogin2,
      setAccountnum,
      setifsccode,
      setbankname,
      setbankbranch,
      setpincode,
      Postformlogin3,
      setownername,
      setowneridproof,
      setidproofnum,
      setpannumber,
      setidprooffile,
      setpanfile,
      setConfirmpass,
      setpasswordF,
      setusernameF,
      Relogin,
      setIsModalVisibleF,
    },
  ] = Franchesisloginexp();

  let myuser = localStorage.getItem("usernameLogin");
  let myuserpass = localStorage.getItem("passwordLogin");
  let username = myuser;
  let password = myuserpass;
  localStorage.setItem("usernamef", username);
  localStorage.setItem("passwordf", password);
  let usernameFranchise = localStorage.getItem("usernamef");
  let passwordFranchise = localStorage.getItem("passwordf");
  setpasswordF(passwordFranchise);
  setusernameF(usernameFranchise);
  const [page, setpage] = useState(1);
  function goNextpage() {
    setpage((page) => page + 1);
  }
  return (
    <FCreate>
      <div className="arrow">
        <BackArrow />
      </div>
      <div className="franchesis">
        <h1>Register Franchise</h1>
        <small></small>
      </div>
      <div className="createf">
        <div className="maindiv">
          {page === 1 && (
            <Franchiseform1
              setpage={setpage}
              page={page}
              goNextpage={goNextpage}
              setUsername={setUsername}
              setPassword={setPassword}
              firebase_email={firebase_email}
              firebase_password={firebase_password}
              setConfirmpass={setConfirmpass}
              confirmpass={confirmpass}
              Submitlogin={Submitlogin}
            />
          )}
          {page === 2 && (
            <Franchiseform2
              setpage={setpage}
              page={page}
              goNextpage={goNextpage}
              Postformlogin={Postformlogin}
              setAddress={setAddress}
              firebase_email={firebase_email}
              firebase_password={firebase_password}
              address={address}
              phone={phone}
              name={name}
              billing_address={billing_address}
              setAddress={setAddress}
              setphone={setphone}
              setname={setname}
              setCordinates={setCordinates}
              setlngadrs={setlngadrs}
              setbillingAdrs={setbillingAdrs}
              setlatdrs={setlatdrs}
              latitude={latitude}
              setpincode={setpincode}
              picode={pincode}
              setimage={setimage}
              image={image}
            />
          )}
          {page === 3 && (
            <Franchiseform3
              setpage={setpage}
              page={page}
              goNextpage={goNextpage}
              firebase_email={firebase_email}
              firebase_password={firebase_password}
              Postformlogin2={Postformlogin2}
              account_number={account_number}
              bank_name={bank_name}
              bank_branch={bank_branch}
              setAccountnum={setAccountnum}
              setifsccode={setifsccode}
              setbankname={setbankname}
              setbankbranch={setbankbranch}
              ifsc_code={ifsc_code}
            />
          )}
          {page === 4 && (
            <Franchiseform4
              firebase_email={firebase_email}
              firebase_password={firebase_password}
              history={history}
              Postformlogin3={Postformlogin3}
              setownername={setownername}
              setowneridproof={setowneridproof}
              setidproofnum={setidproofnum}
              setpannumber={setpannumber}
              setidprooffile={setidprooffile}
              setpanfile={setpanfile}
              owner_name={owner_name}
              owner_id_proof={owner_id_proof}
              id_proof_number={id_proof_number}
              pan_number={pan_number}
              id_proof_file={id_proof_file}
              pan_file={pan_file}
              usernameF={usernameF}
              passwordF={passwordF}
              Relogin={Relogin}
              setIsModalVisibleF={setIsModalVisibleF}
              isModalVisibleF={isModalVisibleF}
            />
          )}
        </div>
      </div>
    </FCreate>
  );
}

export default FranchiseCreate;

//form 1 ...............................................................................................................
function Franchiseform1({
  setpage,
  page,
  goNextpage,
  setPassword,
  setUsername,
  firebase_email,
  firebase_password,
  Submitlogin,
  setConfirmpass,
  confirmpass,
}) {
  const submitbutton = () => {
    let pswd = firebase_password;
    let cpswd = confirmpass;
    let message = document.getElementById("message");
    if (pswd.length !== 0) {
      if (pswd == cpswd) {
        message.textContent = "Password Match";
        message.style.color = "green";
        Submitlogin(goNextpage);
      } else {
        message.textContent = "Password doesnt Match!!!";
        message.style.color = "red";
      }
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <Createf3>
      <div className="form">
        <div className="formcreate">
          <h1>Create an Account</h1>
        </div>
        <div className="input">
          <input
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            autocomplete="off"
            placeholder="Enter your email"
            onChange={(e) => setUsername(e.target.value)}
            value={firebase_email}
          ></input>
          <input
            autocomplete="off"
            type="password"
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
            value={firebase_password}
          ></input>
          <input
            autocomplete="off"
            type="password"
            placeholder="Confirm your password"
            onChange={(e) => setConfirmpass(e.target.value)}
            value={confirmpass}
          ></input>
        </div>
        <small id="message" style={{ color: "black" }}></small>
        <div className="buttonfran">
          <button onClick={() => submitbutton()}>Next</button>
        </div>
      </div>
    </Createf3>
  );
}

//form 2 ...............................................................................................................
function Franchiseform2({
  setpage,
  page,
  goNextpage,
  Postformlogin,
  firebase_email,
  firebase_password,
  address,
  phone,
  setimage,
  image,
  name,
  billing_address,
  setAddress,
  setphone,
  setname,
  setCordinates,
  setlngadrs,
  setbillingAdrs,
  setlatdrs,
  latitude,
  setpincode,
  pincode,
}) {
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const latlng = await getLatLng(result[0]);
    setbillingAdrs(value);

    setAddress(value);
    setCordinates(latlng);
    setlatdrs(latlng.lat);
    setlngadrs(latlng.lng);
  };
  const [preview, setpreview] = useState(null);

  //const [filesize, setfilesize] = useState(3e6);

  // const fileChangedHandlerr = (event) => {
  //   let file = event.target.files[0];
  //   setfilesize(file);
  //   setimage(event.target.files[0]);
  //   if (file.size > 1.5e6 || filesize === 3000000) {
  //     notification["warning"]({
  //       message: "warning",
  //       description: "Please upload a file smaller than 1.5 MB",
  //     });

  //     return false;
  //   }
  // };

  const uploadFile = (e) => {
    setimage(e.target.files[0]);
    setpreview(URL.createObjectURL(e.target.files[0]));
  };
  let imgPreview;
  if (preview) {
    imgPreview = (
      <img src={preview} alt="" style={{ width: "100%", height: "100%" }} />
    );
  }
  const subbmitform = () => {
    Postformlogin(goNextpage);
  };

  return (
    <Createf>
      <div className="form">
        <div className="formcreate">
          <h1>Enter Basic Information</h1>
        </div>
        <div className="input">
          {/* <input value={firebase_email} placeholder="Enter your email"></input>
          <input
            value={firebase_password}
            placeholder="Enter your password"
            type="password"
          ></input> */}
          <input
            autocomplete="off"
            value={name}
            placeholder="Name"
            onChange={(e) => setname(e.target.value)}
          ></input>
          <input
            autocomplete="off"
            value={phone}
            placeholder="Phone"
            maxlength="13"
            onChange={(e) => setphone(e.target.value)}
          ></input>
          <input
            autocomplete="off"
            value={pincode}
            placeholder="Pincode"
            maxlength="6"
            onChange={(e) => setpincode(e.target.value)}
          ></input>
          {/* <input
            placeholder="Profile pic"
            className="pic"
            type="file"
            id="myFile"
            name="filename"
            onChange={(e) => setimage(e.target.files[0])}
          ></input> */}

          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={{ width: "100%" }}>
                {/* <div style={{ display: "flex", gap: 10 }}>
              {" "}
              <small>Latitude:{coordinates.lat}</small>
              <small>Latitude:{coordinates.lng}</small>
            </div> */}

                <input
                  autocomplete="off"
                  style={{ width: "90%" }}
                  {...getInputProps({ placeholder: "Enter billing address" })}
                />
                <div>
                  {loading ? <div>...loading </div> : null}
                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#00a8cb" : "#fff",
                    };
                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })}>
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <div class="upload-btn-wrapper">
          <div className="imgsec">
            <button class="btn">Upload profile</button>
            <input
              type="file"
              id="myFile"
              name="filename"
              onChange={uploadFile}
            />
            <div
              className="img"
              style={{ width: "60px", height: 60, objectFit: "cover" }}
            >
              {imgPreview}
            </div>
          </div>
        </div>
        <div className="buttonfran">
          <button onClick={() => subbmitform()}>Next</button>
        </div>
      </div>
    </Createf>
  );
}

//Form 3 .....................................................................................................................................

function Franchiseform3({
  setpage,
  page,
  goNextpage,
  firebase_email,
  firebase_password,
  account_number,
  Postformlogin2,
  bank_name,
  bank_branch,
  setAccountnum,
  setifsccode,
  setbankname,
  setbankbranch,
  ifsc_code,
}) {
  const Submitform = () => {
    Postformlogin2(goNextpage);
  };

  return (
    <Createf2>
      <div className="form">
        <div className="formcreate">
          <h1>Enter Bank Information</h1>
        </div>
        <div className="input">
          {/* <input placeholder="Enter your email" value={firebase_email}></input>
          <input
            type="password"
            placeholder="Enter your password"
            value={firebase_password}
          ></input> */}
          <input
            // autocomplete="off"
            placeholder="Account Number"
            onChange={(e) => setAccountnum(e.target.value)}
            maxlength="18"
            value={account_number}
          ></input>
          <input
            autocomplete="off"
            placeholder="IFSC Code"
            onChange={(e) => setifsccode(e.target.value)}
            maxlength="11"
            value={ifsc_code}
          ></input>
          <input
            autocomplete="off"
            placeholder="Bank Name"
            value={bank_name}
            onChange={(e) => setbankname(e.target.value)}
          ></input>
          <input
            placeholder="Bank Branch"
            value={bank_branch}
            onChange={(e) => setbankbranch(e.target.value)}
          ></input>
        </div>
        <div className="buttonfran">
          <button onClick={() => Submitform()}>Next</button>
        </div>
      </div>
    </Createf2>
  );
}

//Form 4 ..........................................................................................................................................

function Franchiseform4({
  firebase_email,
  firebase_password,
  history,
  Postformlogin3,
  setownername,
  setowneridproof,
  setidproofnum,
  setpannumber,
  setidprooffile,
  setpanfile,
  owner_name,
  owner_id_proof,
  id_proof_number,
  pan_number,
  id_proof_file,
  pan_file,
  passwordF,
  usernameF,
  setIsModalVisibleF,
  isModalVisibleF,
  Relogin,
}) {
  const showModalF = () => {
    setIsModalVisibleF(true);
  };

  const handleOkF = () => {
    Relogin(history);
    setIsModalVisibleF(false);
  };

  const handleCancelF = () => {
    setIsModalVisibleF(false);
  };
  const Submitform3 = () => {
    Postformlogin3(showModalF);
  };
  return (
    <Createf3>
      <div className="form">
        <div className="formcreate">
          <h1> Enter Owner Details</h1>
        </div>
        <div className="input">
          {/* <input placeholder="Enter your email" value={firebase_email}></input>
          <input
            placeholder="Enter your password"
            value={firebase_password}
          ></input> */}
          <input
            autocomplete="off"
            placeholder="Owner Name"
            value={owner_name}
            onChange={(e) => setownername(e.target.value)}
          ></input>
          {/* <input
            placeholder="Owner Id Proof"
            value={owner_id_proof}
            onChange={(e) => setowneridproof(e.target.value)}
          ></input> */}
          <select
            onChange={(e) => setowneridproof(e.target.value)}
            style={{
              width: "90%",
              height: 40,
              background: "#F8F9FC",
              border: "1px solid gray",
              paddingLeft: 10,
              cursor: "pointer",
            }}
          >
            <option value={owner_id_proof}>adhaar</option>
            <option value={owner_id_proof}>voter-id</option>
            <option value={owner_id_proof}>passport </option>
            <option value={owner_id_proof}>driving-licence</option>
          </select>
          <input
            autocomplete="off"
            placeholder="ID Proof Number"
            value={id_proof_number}
            onChange={(e) => setidproofnum(e.target.value)}
          ></input>
          <input
            autocomplete="off"
            placeholder="Pan Number"
            value={pan_number}
            onChange={(e) => setpannumber(e.target.value)}
          ></input>
          <label>Upload Aadhar card</label>
          <input
            autocomplete="off"
            placeholder="ID Proof File"
            className="pic"
            type="file"
            id="myFile"
            name="filename"
            onChange={(e) => setidprooffile(e.target.files[0])}
          ></input>
          {/* <div class="file-input">
            <input type="file" id="file" class="file"
             onChange={(e) => setidprooffile(e.target.files[0])} />
            <label for="file">
            Upload Aadhar Card
              <p class="file-name"></p>
            </label>
          </div>
          <div class="file-input">
            <input type="file" id="file" class="file"
             onChange={(e) => {
              setpanfile(e.target.files[0]);
            }}/>
            <label for="file">
            Upload pan card
              <p class="file-name"></p>
            </label>
          </div> */}
          <label>Upload Pan card</label>
          <input
            placeholder="Pan File"
            type="file"
            className="pic"
            id="myFile"
            name="filename"
            onChange={(e) => {
              setpanfile(e.target.files[0]);
            }}
          ></input>
        </div>
        <div className="buttonfran">
          <button onClick={() => Submitform3()}>Finish</button>
        </div>
        <Modal
          title="Confirm your login"
          visible={isModalVisibleF}
          onOk={handleOkF}
          onCancel={handleCancelF}
        >
          <div style={{ display: "flex", gap: 20 }}>
            <input value={usernameF}></input>
            <input value={passwordF} type="password"></input>
          </div>
        </Modal>
      </div>
    </Createf3>
  );
}
