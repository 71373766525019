import React from 'react'
import {Contactfranchise} from './style'
import { Divider } from "antd";
import {LeftOutlined} from "@ant-design/icons";
function Contactf() {
    return (
        <Contactfranchise>
            <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: 'grey' }} />
        <h2>Contact</h2>
      </div>
      <div className="contact">

        <div className="heading">
          <h1>Contact</h1>
          <Divider orientation="left" />
        </div>
      </div>

      <div className="content">
        <div className="border" style={{ backgroundColor: 'white'}}>
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No:</h1>
                </th>
                <th className="head-off">
                  <h1>
                    Id
                  </h1>
                </th>

                <th className="head-off">
                  <h1>
                    Name
                  </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Phone
                  </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Email
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Vehicle Reg Num</h1>
                </th>
              </tr>
            </thead>

            <tbody>
          
                <tr className="body-row-off">
                  <td >ffb</td>
                  <td >ras</td>
                  <td >vfbg</td>
                  <td >
                    <h1>fbhf</h1>
                  </td>
                  <td >
                    <h2> fgfg</h2>
                  </td>
                  <td>fgfgbf</td>
                </tr>
          
            </tbody>
          </table>
        </div>
      </div>
        </Contactfranchise>
    )
}

export default Contactf
