import styled from "styled-components";
export const FranDetail = styled.div`
  margin-left: 15vw;
  margin-top: 80px;

  width: 82vw;
  .title{
    display:flex;
     justify-content:space-between;
     margin-top:40px;
     gap:10px;
     .heading{
       display:flex;
    h1{
      font-size:14px;
      color:grey;
    }
    h2{
      font-size:14px;
      color:grey;
    }
  }
  
  .backarrow {
    padding-left: 30px;
    margin-bottom: 50px;
    justify-content:flex-start;
  }
  }
  .Main {
    margin-bottom:100px;
    .heading {
      display: flex;
     align-items: center;
     gap:5px;
      h1 {
        font-size: 22px;
        font-weight: bold;
        width: 350px;
      }
      small{
          color:#f0f0f0;
          width:100%;
          height:1px;
          border:1px solid #f0f0f0;
      }
    }
    .basic {
        padding-top: 10px;
      padding-left: 50px;
      flex-direction: column;
      display: flex;
      
      .info {
        display: flex;
        width: 700px;
        justify-content: space-between;
        h1 {
          font-family: "dm_sansregular";
          font-size: 18px;
          width: 150px;
          display: flex;
        }
        .basicinfo {
          h2 {
            font-family: "montserratregular";

            width: 250px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            font-size: 14px;
            color: grey;
            text-align: initial;
          }
        }
      }
      .infoimage {
        margin-bottom: 20px;
        display: flex;
        width: 700px;
        justify-content: space-between;
        align-items: center;
        h1 {
          font-family: "dm_sansregular";
          font-size: 18px;
          width: 150px;
          display: flex;
        }
        .basicinfo {
         cursor: pointer;
        }
      }
    }
  }
`;
export const Basicmodal = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
justify-content:center;
align-items: center;
input{
  border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid grey;;
        height: 6vh;
        background: white;
        box-shadow: inset 0 -1px 0  #00a8cb;
        width: 400px;

}`
export const Ownermodal = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
justify-content:center;
align-items: center;

input{
  border-radius: 3.5px;
        padding: 1vh;
        font-size: 1.7vh;
        border: 1px solid grey;;
        height: 5vh;
        background: white;
        box-shadow: inset 0 -1px 0  #00a8cb;
        width: 400px;

}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width:90%;
 
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  width:130px;
  height:30px;
  margin-left:13px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  justify-content:start;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}`