import { createStore, createHook, defaults } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

defaults.devtools = true;
const Supportrequest = createStore({
  name: "supportrequest detail",
  initialState,
  actions,
});

export const SupportdetailExp = createHook(Supportrequest, {
  selector: (state) => state,
});