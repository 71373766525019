import React from "react";
import { Return } from "./style";
function ReturnCancellation() {
  return (
    <Return>
      <div className="pagesectionp" id="gteam">
        <div className="pagewidthp">
          <h1>Return & Refund Policy</h1>
          <p>Updated at 2021-12-31</p>

          <h1>Definitions and key terms</h1>
          <p>
            To help explain things as clearly as possible in this Return &
            Refund Policy, every time any of these terms are referenced, are
            strictly defined as:
          </p>

          <p>
            ● Cookie: small amount of data generated by a website and saved by
            your web browser. It is used to identify your browser, provide
            analytics, remember information about you such as your language
            preference or login information.
          </p>
          <p>
            ● Company: when this policy mentions “Company,” “we,” “us,” or
            “our,” it refers to DELIBUOY, that is responsible for your
            information under this Return & Refund Policy.
          </p>
          <p>
            ● Customer: refers to the company, organization or person that signs
            up to use the DELIBUOY Service to manage the relationships with your
            consumers or service users.
          </p>

          <p>
            ● Device: any internet connected device such as a phone, tablet,
            computer or any other device that can be used to visit DELIBUOY and
            use the services.
          </p>
          <p>
            ● Service: refers to the service provided by DELIBUOY as described
            in the relative terms (if available) and on this platform
          </p>

          <p>
            ● Website: DELIBUOY."’s" site, which can be accessed via this URL:
            https://www.delibuoy.com/
          </p>
          <p>
            ● You: a person or entity that is registered with DELIBUOY to use
            the Services.
          </p>

          <h1>Return & Refund Policy</h1>

          <p>
            Thanks for shopping at DELIBUOY. We appreciate the fact that you
            like to buy the stuff we build. We also want to make sure you have a
            rewarding experience while you’re exploring, evaluating, and
            purchasing our products.
            <br />
            As with any shopping experience, there are terms and conditions that
            apply to transactions at DELIBUOY. We’ll be as brief as our
            attorneys will allow. The main thing to remember is that by placing
            an order or making a purchase at DELIBUOY, you agree to the terms
            set forth below along with Policy.
            <br />
            If there’s something wrong with the product/service you bought, or
            if you are not happy with it, you will not be able to issue a refund
            for your item.
          </p>
          <br></br>
          <h1>Refunds</h1>
          <br></br>
          <p>
            We at ourselves to serving our customers with the best products.
            Every single product that you choose is thoroughly inspected,
            checked for defects and packaged with utmost care. We do this to
            ensure that you fall in love with our products.
            <br />
            Sadly, there are times when we may not have the product(s) that you
            choose in stock, or may face some issues with our inventory and
            quality check. In such cases, we may have to cancel your order. You
            will be intimated about it in advance so that you don't have to
            worry unnecessarily about your order. If you have purchased via
            Online payment (not Cash on Delivery), then you will be refunded
            once our team confirms your request.
            <br />
            We carry out thorough quality check before processing the ordered
            item. We take utmost care while packing the product. At the same
            time we ensure that the packing is good such that the items won’t
            get damaged during transit. Please note that DELIBUOY is not liable
            for damages that are caused to the items during transit or
            transportation.
          </p>
          <h1>
            We follow certain policies to ensure transparency, efficiency and
            quality customer care:
          </h1>
          <br></br>
          <p>
            ● We DO NOT allow returns on sold products - online or in retail
            outlets.{" "}
          </p>
          <p>
            ● We DO NOT accept returned goods, as we believe that customers
            should get the best quality products.
          </p>
          <p>
            ● Refunds are NOT given for any purchases made - be they online or
            in retail store.
          </p>
          <p>● We DO NOT encourage exchanges of our products.</p>
          <p>
            ● We DO NOT engage in reselling used products and discourage the
            same, because we cannot ensure the best quality products for our
            customers.
          </p>

          <h1>For International Orders:</h1>
          <br></br>
          <p>● We DO NOT support Exchanges or Returns.</p>
          <p>
            {" "}
            ● If you cancel the order before we process it and dispatch for
            shipping, a refund can be processed. Orders generally take 1-2 days
            to process before dispatch.
          </p>
          <p>
            ● Orders already in shipping cannot be returned, canceled or
            refunded.
          </p>
          <p>
            ● If you face any issues, please contact our Support Team
            immediately.
          </p>

          <br></br>
          <h1>Your Consent</h1>
          <p>
            By using our website/app, registering an account, or making a
            purchase, you hereby consent to our Return & Refund Policy and agree
            to its terms.
          </p>
          <h1>Changes To Our Return & Refund Policy</h1>
          <p>
            Should we update, amend or make any changes to this document so that
            they accurately reflect our Service and policies. Unless otherwise
            required by law, those changes will be prominently posted here.
            Then, if you continue to use the Service, you will be bound by the
            updated Return & Refund Policy. If you do not want to agree to this
            or any updated Return & Refund Policy, you can delete your account.
          </p>

          <h1>Contact Us</h1>
          <p>
            If, for any reason, You are not completely satisfied with any good
            or service that we provide, don't hesitate to contact us and we will
            discuss any of the issues you are going through with our product.
          </p>

          <p>● Via Email: thedelibuoy@gmail.com</p>
          <p>● Via this Link: https://www.delibuoy.com/</p>
        </div>
      </div>
    </Return>
  );
}

export default ReturnCancellation;
