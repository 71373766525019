import { auth, db } from "../../../Common/firebase";
import { post, get } from "../Common/remote/base_api";

const registerWithEmailAndPasswords = async (email, password) => {
  await auth.createUserWithEmailAndPassword(email, password);
  // const user = res.user;
  // await db.collection("users").add({
  //   uid: user.uid,
  //   authProvider: "local",
  //   email,
  // });
  return registerWithEmailAndPasswords;
};
export default registerWithEmailAndPasswords;

export const Postinfo1 = (data) => {
  return post("franchise/register-driver-basic/", data);
};

export const Postinfo2 = (data) => {
  return post(`franchise/register-driver-vehicle-info/`, data);
};

export const Postinfo3 = (data) => {
  return post(`franchise/register-driver-vehicle-info/`, data);
};

export const GetVechicletype = (data) => {
  return get(`franchise/get-vehicle-types/`, data);
};
export const GetVechiclemodel = (id) => {
  return get(`/franchise/get-vehicle-models/${id}/`);
};
