import {
  getBlockFranchise,
  GetCustomerdetails,
  GetCustomerdetailsbyId,
  GetCustomerdetailsbyName,
  GetCustomerdetailsbyPhone,
  getUnBlockFranchise,
} from "../../../../infrastructure/Admin/customer";

const actions = {
  setisLoading:
    (isLoading) =>
    ({ setState }) => {
      setState({ isLoading });
    },
  setcustomer:
    (customer) =>
    ({ setState }) => {
      setState({ customer });
    },
  setcustrname:
    (custrname) =>
    ({ setState }) => {
      setState({ custrname });
    },
  setcustphone:
    (custphone) =>
    ({ setState }) => {
      setState({ custphone });
    },
  setcustid:
    (custid) =>
    ({ setState }) => {
      setState({ custid });
    },
  getCustomers:
    () =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));
      try {
        const res = await GetCustomerdetails();
        dispatch(actions.setcustomer(res.data.data));
        dispatch(actions.setisLoading(false));
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
  getCustomersName:
    () =>
    async ({ dispatch, getState }) => {
      const { custrname } = getState();

      try {
        const res = await GetCustomerdetailsbyName(custrname);
        dispatch(actions.setcustrname(res.data.data));
      } catch (err) {
      } finally {
      }
    },
  getCustomersPhone:
    () =>
    async ({ dispatch, getState }) => {
      const { custphone } = getState();
      try {
        const res = await GetCustomerdetailsbyPhone(custphone);
        dispatch(actions.setcustphone(res.data.data));
      } catch (err) {
      } finally {
      }
    },
  getCustomersid:
    () =>
    async ({ dispatch, getState }) => {
      const { custid } = getState();
      try {
        const res = await GetCustomerdetailsbyId(custid);
        dispatch(actions.setcustid(res.data.data));
      } catch (err) {
      } finally {
      }
    },
    getblockFranchise:
    (id) =>
    async ({ dispatch }) => {
      dispatch(actions.setisLoading(true));

      try {
        await getBlockFranchise(id);
        dispatch(actions.getCustomers());
      } catch (err) {
      } finally {
        dispatch(actions.setisLoading(false));
      }
    },
    getunblockfranchise:
    (id) =>
    async({dispatch}) =>{
      dispatch(actions.setisLoading(true));
      try{
        await getUnBlockFranchise(id);
        dispatch(actions.getCustomers());
      }catch(err) {

      }finally{
        dispatch(actions.setisLoading(false));
      }
    },
  
};
export default actions;
