import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import { useHistory } from "react-router";
import { SearchOutlined, CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { Payment } from "./style";
import { GetPaymentdetails } from "./store";
import { Input } from "antd";
import { firebase } from "../../../Common/firebase";

function PaymentList() {
  const [
    {
      isLoading,
      payment,
      paymentrazorpay,
      paymentdatecreated,
      paymentcustomer,
      paymentrefunded,
    },
    {
      getPayment,
      setpaymentrazorpay,
      setpaymentdatecreated,
      setpaymentcustomer,
      setpaymentrefunded,
    },
  ] = GetPaymentdetails();
  const [token, setToken] = useState("");
  useEffect(() => {
    //import { firebase } from "../../../Common/firebase";
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => setToken(latestToken))
          .catch((err) => console.log(err));
      }
    });
  }, [token]);
  localStorage.setItem("token", token);
  useEffect(() => {
    getPayment();
  }, [paymentrazorpay, paymentdatecreated, paymentcustomer, paymentrefunded,token]);
  
  const Searchrequestrazorpay = (e) => {
    setpaymentrazorpay(e.target.value);
    getPayment();
  };
  const Searchrequestdatecreated = (e) => {
    setpaymentdatecreated(e.target.value);
    getPayment();
  };
  const Searchrequestcustomer = (e) => {
    setpaymentcustomer(e.target.value);
    getPayment();
  };
  const Searchrequestrefund = (e) => {
    setpaymentrefunded(e.target.value);
    getPayment();
  };
  const [hideR, sethideR] = useState(true);
  const hiddenR = () => {
    sethideR(!hideR);
    setpaymentrazorpay("");
  };
  const [hideC, sethideC] = useState(true);
  const hiddenC = () => {
    sethideC(!hideC);
    setpaymentcustomer("");
  };
  const [hideRE, sethideRE] = useState(true);
  const hiddenRE = () => {
    sethideRE(!hideRE);
    setpaymentrefunded("");
  };
  const [hideD, sethideD] = useState(true);
  const hiddenD = () => {
    sethideD(!hideD);
    setpaymentdatecreated("");
  };


  return (
    <Payment>
      <div className="title">
        <h1>Home</h1>
        <LeftOutlined style={{ color: "grey" }} />
        <h2>Payment</h2>
      </div>
      <div className="franchises">
        <div className="heading">
          <h1>Payment</h1>
          <Divider orientation="left" />
        </div>
      </div>

      <div className="content">
        <div className="border">
          <table className="official">
            <thead>
              <tr className="head-row-off">
                <th className="head-off">
                  <h1>SL No:</h1>
                </th>
                <th className="head-off">
                  <h1>ID </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Razorpay Id
                    {hideR ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenR()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestrazorpay(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenR()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Recieved</h1>
                </th>

                <th className="head-off">
                  <h1>
                    Customer
                    {hideC ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenC()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestcustomer(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenC()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>Trip</h1>
                </th>
                <th className="head-off">
                  <h1>Amount</h1>
                </th>
                <th className="head-off">
                  <h1>
                    Refund
                    {hideRE ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenRE()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestrefund(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenRE()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
                <th className="head-off">
                  <h1>
                    Date Created
                    {hideD ? (
                      <div>
                        <SearchOutlined
                          style={{ color: "grey" }}
                          onClick={() => hiddenD()}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "50%" }}>
                        <Input
                          className="search-table"
                          style={{ paddingLeft: "1vw" }}
                          type="text"
                          placeholder="Search an item"
                          onChange={(e) => Searchrequestdatecreated(e)}
                          suffix={
                            <CloseOutlined
                              style={{ color: "grey" }}
                              onClick={() => hiddenD()}
                            />
                          }
                        ></Input>
                      </div>
                    )}
                  </h1>
                </th>
              </tr>
            </thead>

            <tbody>
              {payment?.map((res, i) => (
                <tr className="body-row-off">
                  <td>{i + 1}</td>
                  <td>{res.id}</td>
                  <td>{res.razorpay_payment_id}</td>
                  <td>{res.payment_recieved === true ? "Yes" : "No"}</td>
                  <td>{res.customer}</td>
                  <td>{res.trip}</td>
                  <td>{res.amount}</td>
                  <td>{res.refunded === true ? "Yes" : "No"}</td>
                  <td>{res.date_created}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Payment>
  );
}

export default PaymentList;
