import styled from "styled-components";
export const Footer = styled.div`
  height: 350px;
  width: 100%;
  background-color: #001d3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .mainf {
    display: flex;
    width: 80%;
    justify-content: space-around;
   
    .itemsf {
   
      display: flex;
      flex-direction: column;
      

      ul {
        color:rgb(112,132,153);
        list-style: none;
        .defaultf {
          font-size: 18px;
        
          font-family: "pt_serifbold";
          margin:0px;
          padding:0px;
          @media (max-width:450px){
            font-size:14px;
          }
        }
        .listf {
          font-size: 14px;
          padding-top: 20px;
          text-align: center;
          font-family: "dm_sansregular";
          color: rgb(50,70,91);
          cursor:pointer;
          &:hover{
           
         color:white;
            
          }
        }
        @media (max-width:450px){
          font-size:12px;
        }
      }
    }
    @media (max-width:680px){
      flex-direction:column;
      justify-content:center;
      align-items:center;
    }
    @media(max-width:450px){
        justify-content:center;
        align-items:center;
    }
   
  }
  .appimg {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items:center;
    gap: 20px;
    padding-top: 40px;
    img {
      cursor: pointer;
      width: 150px;
      @media(max-width:450px){
        width:120px;
      }
    }
    @media(max-width:450px){
      flex-direction:column;
      margin-bottom:20px;
    }
  }
 @media(max-width:680px){
   height:unset;
   padding-top:30px;
 }
`;
